<template>
  <div class="data-main-wrapper">
    <i-table v-if="table.showTable" :config="table.config" :page="table.page" :page-sizes="defaultPageSizes" @change-page="handleChangePage">
      <template slot="search-condition">
        <el-form :inline="true" size="small">
          <el-form-item :label="field.chargingOrderCode.name + ':'">
            <el-input clearable v-model="searchCondition.chargingOrderCode"
                      :placeholder="field.chargingOrderCode.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.orgName.name + ':'">
            <el-input clearable v-model="searchCondition.orgName"
                      :placeholder="field.orgName.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.stationName.name + ':'">
            <el-input clearable v-model="searchCondition.stationName"
                      :placeholder="field.stationName.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.statusName.name + ':'">
            <el-select v-model="searchCondition.orderStatus" clearable :placeholder="field.statusName.placeholder">
              <el-option
                  v-for="(item, index) in chargingOrderStatusPullDownData"
                  :key="index"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>

      <!--搜索按钮-->
      <template slot="search-button">
        <el-button type="primary" size="small" @click="handleChangePage(true)">{{ btn.search }}</el-button>
      </template>

      <!--表头-->
      <template slot="header" slot-scope="scope">
        {{ field[scope.column.property].name }}
      </template>

      <!--数据行内-->
      <!--      <template slot="row_operate" slot-scope="scope">
              <el-button
                  type="text"
                  :icon="rowButton.icon"
                  size="mini"
                  v-for="(rowButton,index) in btnPosition.obtainPositionButtons(buttons,btnPosition.positions.row) "
                  :key="index"
                  @click="operateHandler(rowButton.code,scope.row)">
                {{ rowButton.name }}
              </el-button>
            </template>-->
    </i-table>
  </div>
</template>

<script>
import {defaultPage,defaultPageSizes} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable.vue';
import {clientUserChargingOrderTableSettings} from '@/config/table-settings';
import {obtainPositionButtons, showData} from '@/utils/power';
import {positions} from '@/config/button-position-settings';
import {hasSuccess} from '@/utils/tool';
import {loadCurrentPageTheButtons} from '@/api/system/menu';
import {clientUserChargingOrderPaging} from '@/api/order/client-user-charging-order';
import {loadDataDictByCode} from '@/api/system/data-dict';

export default {
  components: {
    'i-table': ITable,
  },
  data() {
    return {
      defaultPageSizes,
      table: {
        showTable: false,
        page: {
          ...defaultPage
        },
        buttons: [],
        config: {
          columns: [...clientUserChargingOrderTableSettings],
          rowKey: 'id'
        },
      },
      searchCondition: {
        orderStatus: null,
        chargingOrderCode: '',
        orgName: '',
        stationName: '',
      },
      chargingOrderStatusPullDownData: []
    }
  },
  computed: {
    btnPosition: {
      get: function () {
        return {obtainPositionButtons, positions}
      }
    },
    public() {
      return {
        unauthorizedView: this.$t('public.resultMessages.unauthorizedView'),
      }
    },
    btn() {
      return {
        search: this.$t('public.button.search'),
        // submit: this.$t('public.button.submit'),
        // cancel: this.$t('public.button.cancel'),
      }
    },
    field() {
      const prefix = 'clientUserChargingOrderManagePage.field';
      const form = 'clientUserChargingOrderManagePage.form';
      return {
        id: {name: this.$t(`${prefix}.id`)},
        chargingOrderCode: {
          name: this.$t(`${prefix}.chargingOrderCode`),
          placeholder: this.$t(`${form}.chargingOrderCode.placeholder`),
        },
        transactionSerialNumber: {name: this.$t(`${prefix}.transactionSerialNumber`)},
        mobile: {name: this.$t(`${prefix}.mobile`)},
        orgName: {
          name: this.$t(`${prefix}.orgName`),
          placeholder: this.$t(`${form}.orgName.placeholder`),
        },
        stationName: {
          name: this.$t(`${prefix}.stationName`),
          placeholder: this.$t(`${form}.stationName.placeholder`),
        },
        chargePileCode: {name: this.$t(`${prefix}.chargePileCode`)},
        chargePileName: {name: this.$t(`${prefix}.chargePileName`)},
        chargeGunCode: {name: this.$t(`${prefix}.chargeGunCode`)},
        fullChargeGunCode: {name: this.$t(`${prefix}.fullChargeGunCode`)},
        chargingStartTime: {name: this.$t(`${prefix}.chargingStartTime`)},
        chargingEndTime: {name: this.$t(`${prefix}.chargingEndTime`)},
        sharpUnitPriceByYuan: {name: this.$t(`${prefix}.sharpUnitPriceByYuan`)},
        sharpElectricQuantity: {name: this.$t(`${prefix}.sharpElectricQuantity`)},
        sharpLossElectricQuantity: {name: this.$t(`${prefix}.sharpLossElectricQuantity`)},
        sharpAmountByYuan: {name: this.$t(`${prefix}.sharpAmountByYuan`)},
        peakUnitPriceByYuan: {name: this.$t(`${prefix}.peakUnitPriceByYuan`)},
        peakElectricQuantity: {name: this.$t(`${prefix}.peakElectricQuantity`)},
        peakLossElectricQuantity: {name: this.$t(`${prefix}.peakLossElectricQuantity`)},
        peakAmountByYuan: {name: this.$t(`${prefix}.peakAmountByYuan`)},
        flatUnitPriceByYuan: {name: this.$t(`${prefix}.flatUnitPriceByYuan`)},
        flatElectricQuantity: {name: this.$t(`${prefix}.flatElectricQuantity`)},
        flatLossElectricQuantity: {name: this.$t(`${prefix}.flatLossElectricQuantity`)},
        flatAmountByYuan: {name: this.$t(`${prefix}.flatAmountByYuan`)},
        valleyUnitPriceByYuan: {name: this.$t(`${prefix}.valleyUnitPriceByYuan`)},
        valleyElectricQuantity: {name: this.$t(`${prefix}.valleyElectricQuantity`)},
        valleyLossElectricQuantity: {name: this.$t(`${prefix}.valleyLossElectricQuantity`)},
        valleyAmountByYuan: {name: this.$t(`${prefix}.valleyAmountByYuan`)},
        meterTotalStartValue: {name: this.$t(`${prefix}.meterTotalStartValue`)},
        meterTotalEndValue: {name: this.$t(`${prefix}.meterTotalEndValue`)},
        totalElectricity: {name: this.$t(`${prefix}.totalElectricity`)},
        lossTotalElectric: {name: this.$t(`${prefix}.lossTotalElectric`)},
        consumeAmountByYuan: {name: this.$t(`${prefix}.consumeAmountByYuan`)},
        statusName: {
          name: this.$t(`${prefix}.statusName`),
          placeholder: this.$t(`${form}.statusName.placeholder`),
        },
        stopReason: {name: this.$t(`${prefix}.stopReason`)},
        transactionDate: {name: this.$t(`${prefix}.transactionDate`)},
        actualPaidAmountByYuan: {name: this.$t(`${prefix}.actualPaidAmountByYuan`)},
        createTime: {name: this.$t(`${prefix}.createTime`)},
      }
    }
  },
  mounted() {
    this.initCurrentPageButton();
  },
  methods: {
    /**
     * 处理页面改变
     */
    handleChangePage(hasSearch) {
      if (hasSearch) {
        this.table.page.current = 1;
      }
      this.initChargingOrdersData();
    },
    /**
     * 初始化充电订单数据
     */
    async initChargingOrdersData() {
      const show = showData(this.buttons, positions.view);
      if (!show) {
        this.$message.error(this.public.unauthorizedView);
        return;
      }
      let {code, data} = await clientUserChargingOrderPaging({
        current: this.table.page.current,
        size: this.table.page.size,
        ...this.searchCondition
      });
      if (hasSuccess(code)) {
        this.table.page = {
          current: data.current,
          size: data.size,
          total: data.total,
          records: data.records,
        };
      }
    },
    /**
     * 初始化页面按钮数据
     */
    async initCurrentPageButton() {
      let {code, data} = await loadCurrentPageTheButtons({route: this.$route.path});
      if (hasSuccess(code)) {
        this.buttons = data;
        this.table.showTable = true;
        await this.initChargingOrdersData();
        let dictData = await loadDataDictByCode({code: 'charge_order_status'});
        this.chargingOrderStatusPullDownData = dictData.data;
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
