
import httpClient from "./http";

export const get = async (operate) => {
    operate.method = 'GET';
    const data = await httpClient(operate);
    // console.log('res-data',data);
    return new Promise((resolve,reject)=>{
        data?resolve(data):resolve({})
        // resolve(data);
    });
}

export const post =  (operate, isBody = true) => {
    operate.method = 'POST';
    if (!isBody) {
        operate.headers = {...operate.headers, 'Content-Type': 'application/x-www-form-urlencoded'}
    }
    const data =  httpClient(operate);
    return new Promise((resolve,reject)=>{
        data?resolve(data):resolve({})
    });
}

export const upload = async (operate) => {
    operate.method = 'POST';
    operate.headers = {...operate.headers, 'Content-Type': 'multipart/form-data'}
    const data = await httpClient(operate);
    return new Promise((resolve,reject)=>{
        data?resolve(data):resolve({})
    });
}
