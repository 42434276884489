<template>
  <div class="data-main-wrapper">
    <i-table v-if="table.showTable" :config="table.config" :page="table.page" :page-sizes="defaultPageSizes"
             @change-page="handleChangePage">
      <template slot="search-condition">
        <el-form :inline="true" size="small">
          <el-form-item :label="field.refundOrderCode.name + ':'">
            <el-input clearable v-model="searchCondition.refundOrderCode"
                      :placeholder="field.refundOrderCode.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.rechargeOrderCode.name + ':'">
            <el-input clearable v-model="searchCondition.rechargeOrderCode"
                      :placeholder="field.rechargeOrderCode.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.paymentOrderCode.name + ':'">
            <el-input clearable v-model="searchCondition.paymentOrderCode"
                      :placeholder="field.paymentOrderCode.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.refundStatusName.name + ':'">
            <el-select v-model="searchCondition.refundStatus" clearable
                       :placeholder="field.refundStatusName.placeholder">
              <el-option
                  v-for="(item, index) in refundOrderStatusPullDownData"
                  :key="index"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>

      <!--搜索按钮-->
      <template slot="search-button">
        <el-button type="primary" size="small" @click="handleChangePage(true)">{{ btn.search }}</el-button>
      </template>

      <!--表头-->
      <template slot="header" slot-scope="scope">
        {{ field[scope.column.property].name }}
      </template>

      <!--数据行内-->
      <!--      <template slot="row_operate" slot-scope="scope">
              <el-button
                  type="text"
                  :icon="rowButton.icon"
                  size="mini"
                  v-for="(rowButton,index) in btnPosition.obtainPositionButtons(buttons,btnPosition.positions.row) "
                  :key="index"
                  @click="operateHandler(rowButton.code,scope.row)">
                {{ rowButton.name }}
              </el-button>
            </template>-->
    </i-table>
  </div>
</template>

<script>
import {defaultPage, defaultPageSizes} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable.vue';
import {clientUserRefundOrderTableSettings} from '@/config/table-settings';
import {obtainPositionButtons, showData} from '@/utils/power';
import {positions} from '@/config/button-position-settings';
import {hasSuccess} from '@/utils/tool';
import {loadCurrentPageTheButtons} from '@/api/system/menu';
import {loadDataDictByCode} from '@/api/system/data-dict';
import {clientUserRefundOrderPaging} from "@/api/order/client-user-refund-order";

export default {
  components: {
    'i-table': ITable,
  },
  data() {
    return {
      defaultPageSizes,
      table: {
        showTable: false,
        page: {
          ...defaultPage
        },
        buttons: [],
        config: {
          columns: [...clientUserRefundOrderTableSettings],
          rowKey: 'id'
        },
      },
      searchCondition: {
        rechargeOrderCode: '',
        refundOrderCode: '',
        refundStatus: null,
      },
      refundOrderStatusPullDownData: [
        {name: '已完成', value: 3},
        {name: '已回滚', value: 5},
        {name: '待退款', value: 1},
        {name: '退款中', value: 4},
        {name: '退款失败', value: 2}
      ]
    }
  },
  computed: {
    btnPosition: {
      get: function () {
        return {obtainPositionButtons, positions}
      }
    },
    public() {
      return {
        unauthorizedView: this.$t('public.resultMessages.unauthorizedView'),
      }
    },
    btn() {
      return {
        search: this.$t('public.button.search'),
        // submit: this.$t('public.button.submit'),
        // cancel: this.$t('public.button.cancel'),
      }
    },
    field() {
      const prefix = 'clientUserRefundOrderManagePage.field';
      const form = 'clientUserRefundOrderManagePage.form';
      return {
        id: {name: this.$t(`${prefix}.id`)},
        refundOrderCode: {
          name: this.$t(`${prefix}.refundOrderCode`),
          placeholder: this.$t(`${form}.refundOrderCode.placeholder`),
        },
        rechargeOrderCode: {
          name: this.$t(`${prefix}.rechargeOrderCode`),
          placeholder: this.$t(`${form}.rechargeOrderCode.placeholder`),
        },
        paymentOrderCode: {
          name: this.$t(`${prefix}.paymentOrderCode`),
          placeholder: this.$t(`${form}.paymentOrderCode.placeholder`),
        },
        refundStatusName: {
          name: this.$t(`${prefix}.refundStatusName`),
          placeholder: this.$t(`${form}.refundStatusName.placeholder`),
        },
        userId: {name: this.$t(`${prefix}.userId`)},
        rechargeOrderId: {name: this.$t(`${prefix}.rechargeOrderId`)},
        paidAmountYuan: {name: this.$t(`${prefix}.paidAmountYuan`)},
        refundAmountYuan: {name: this.$t(`${prefix}.refundAmountYuan`)},
        refundFeeYuan: {name: this.$t(`${prefix}.refundFeeYuan`)},
        currency: {name: this.$t(`${prefix}.currency`)},
        successTime: {name: this.$t(`${prefix}.successTime`)},
        receptionTime: {name: this.$t(`${prefix}.receptionTime`)},
        refundReason: {name: this.$t(`${prefix}.refundReason`)},
        paymentTypeName: {name: this.$t(`${prefix}.paymentTypeName`)},
        thirdRefundId: {name: this.$t(`${prefix}.thirdRefundId`)},
        hasCallbackName: {name: this.$t(`${prefix}.hasCallbackName`)},
        callbackTime: {name: this.$t(`${prefix}.callbackTime`)},
        refundFailedReason: {name: this.$t(`${prefix}.refundFailedReason`)},
        userReceivedAccount: {name: this.$t(`${prefix}.userReceivedAccount`)},
        modifyTime: {name: this.$t(`${prefix}.modifyTime`)},
        createTime: {name: this.$t(`${prefix}.createTime`)},
      }
    }
  },
  mounted() {
    this.initCurrentPageButton();
  },
  methods: {
    /**
     * 处理页面改变
     */
    handleChangePage(hasSearch) {
      if (hasSearch) {
        this.table.page.current = 1;
      }
      this.initRefundOrdersData();
    },
    /**
     * 初始化充电订单数据
     */
    async initRefundOrdersData() {
      const show = showData(this.buttons, positions.view);
      if (!show) {
        this.$message.error(this.public.unauthorizedView);
        return;
      }
      let {code, data} = await clientUserRefundOrderPaging({
        current: this.table.page.current,
        size: this.table.page.size,
        ...this.searchCondition
      });
      if (hasSuccess(code)) {
        this.table.page = {
          current: data.current,
          size: data.size,
          total: data.total,
          records: data.records,
        };
      }
    },
    /**
     * 初始化页面按钮数据
     */
    async initCurrentPageButton() {
      let {code, data} = await loadCurrentPageTheButtons({route: this.$route.path});
      if (hasSuccess(code)) {
        this.buttons = data;
        this.table.showTable = true;
        await this.initRefundOrdersData();
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
