<template>
    <div class="data-main-wrapper">
        <i-table
        v-if="showPage"
        :config="config"
        :page="page"
        :page-sizes="defaultPageSizes"
        @change-page="changPagingHandler"
        >
              <!-- 搜索栏 -->
              <template  v-slot:search-condition>
                <el-form :model="search.values" :inline="true" :rules="search.rules" ref="searchRef">
                    <el-form-item :label="idField+':'" prop="id" ref="roleIdRef">
                        <el-input size="small" v-model="search.values.id" :placeholder="idPlaceholder" clearable />
                    </el-form-item>
                    <el-form-item :label="nameField+':'">
                        <el-input size="small" v-model="search.values.name" :placeholder="namePlaceholder" clearable />
                    </el-form-item>
                </el-form>

            </template>
            <!-- 搜索按钮 -->
            <template v-slot:search-button>
                <el-button type="primary"  size="small" @click="searchHandler" icon="el-icon-search">{{ $t('public.button.search') }}</el-button>
            </template>
            <!-- 左侧功能栏 -->
            <template v-slot:overall-left>
                <el-button v-for="(overallLeftButton,index) in obtainPositionButtons(buttons,positions.left)" :key="index" type="primary"  size="small" :icon="overallLeftButton.icon" @click="operateHandler(overallLeftButton.code)">{{overallLeftButton.name}}</el-button>
            </template>

            <!-- 右侧工作栏 -->
            <template v-slot:overall-right>
                <el-button v-for="(overallRightButton,index) in obtainPositionButtons(buttons,positions.right)" :key="index"   size="small" :icon="overallRightButton.icon" @click="operateHandler(overallRightButton.code)">{{overallRightButton.name}}</el-button>
            </template>

            <!-- 表格行内插槽 -->
            <!-- <template slot="row_name" slot-scope="scope">
                {{ scope.column }}
            </template> -->

            <template  v-slot:header="scope">
                {{  $t(`roleManagePage.field.${scope.column.property}`)}}
            </template>
            <template  v-slot:row_operate="scope">
                <el-button
                type="text"
                :icon="rowButton.icon"
                size="mini"
                v-for="(rowButton,index) in obtainPositionButtons(buttons,positions.row) "
                :key="index"
                @click="operateHandler(rowButton.code,scope.row)"
                >
                    {{ rowButton.name  }}
                </el-button>
            </template>



        </i-table>

        <!-- 新增或修改角色信息弹出层 -->
        <i-drawer
        :title="roleFormDrawer.title"
        :visible.sync="roleFormDrawer.hasOpen"
        @close="closeRoleFormDrawerHandler"
        >

            <el-form :model="roleFormDrawer.data" ref="roleFromRef" label-width="100px" :rules="roleFormRules">
                <!-- 角色名称 -->
                <el-form-item :label="nameField+':'" prop="name">
                    <el-input v-model="roleFormDrawer.data.name" :placeholder="namePlaceholder" size="small" />
                </el-form-item>
                <!-- 角色别名 -->
                <el-form-item :label="aliasField+':'" prop="alias">
                    <el-input v-model="roleFormDrawer.data.alias" :placeholder="aliasPlaceholder" size="small" />
                </el-form-item>
                <!-- 角色备注 -->
                <el-form-item :label="remarksField+':'" prop="remarks">
                    <el-input type="textarea" v-model="roleFormDrawer.data.remarks" :placeholder="remarksPlaceholder" size="small" />
                </el-form-item>
                <!-- 角色排序 -->
                <el-form-item :label="sortField+':'" prop="sort">
                    <el-input type="number" v-model="roleFormDrawer.data.sort" :placeholder="sortPlaceholder" size="small" />
                </el-form-item>
            </el-form>

            <template v-slot:footer>
                <el-button type="primary" size="small" @click="saveOrUpdateRoleInfo">{{ submitButtonText }}</el-button>
                <el-button  size="small" @click="roleFormDrawer.hasOpen = false">{{ cancelButtonText }}</el-button>
            </template>

        </i-drawer>


        <!-- 菜单权限配置弹窗 -->
        <i-dialog
        :title="menuPowerDialog.title"
        :visible.sync="menuPowerDialog.hasOpen"
        width="35%"
        @open="getMenuTreeList"
        style="position: relative;"

        >

            <div class="menu-power-dialog-main i-frame loading-parent">

                <el-tree
                :data="menuTreeList"
                :props="{label:'name',children:'childNode'}"
                show-checkbox
                ref="tree"
                node-key="id"
                >
                </el-tree>

            </div>
            <template v-slot:footer>
                <el-button type="primary" size="small" @click="saveOrUpdateMenuPower" :loading="menuPowerDialog.loading">{{ submitButtonText }}</el-button>
                <el-button  size="small" @click="menuPowerDialog.hasOpen = false" :loading="menuPowerDialog.loading">{{ cancelButtonText }}</el-button>
            </template>

        </i-dialog>

        <!-- 数据权限配置弹窗 -->
        <i-dialog
            :title="dataPowerDialog.title"
            :visible.sync="dataPowerDialog.hasOpen"
            @close="dataPowerDialog.data = {};"
        >
            <el-form
            :model="dataPowerDialog.data"
            :inline="true"
            label-width="100px"
            :rules="dataPowerFormRules"
            >
                <el-form-item :label="nameField">
                    <el-input size="small" v-model="dataPowerDialog.data.name" disabled></el-input>
                </el-form-item>
                <el-form-item :label="aliasField+':'">
                    <el-input size="small" v-model="dataPowerDialog.data.alias" disabled></el-input>
                </el-form-item>
                <el-form-item :label="dataScopeField+':'" prop="dataScope">
                    <el-select size="small" v-model="dataPowerDialog.data.dataScope" :placeholder="dataScopePlaceholder">
                      <el-option :label="dataScope.name" :value="dataScope.id" v-for="(dataScope , index) in  dataPowerList" :key="index"></el-option>
                    </el-select>
                </el-form-item>

            </el-form>
            <template v-slot:footer>
                <el-button type="primary" size="small" @click="changeDataPower">{{ submitButtonText }}</el-button>
                <el-button  size="small" @click="dataPowerDialog.hasOpen = false">{{ cancelButtonText }}</el-button>
            </template>

        </i-dialog>


    </div>
</template>

<script>
import {defaultPageSizes,defaultPage} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable'
import {obtainPositionButtons,showData} from '@/utils/power'
import IDrawer from '@/components/i-drawer/IDrawer'
import IDialog from '@/components/i-dialog/IDialog'
import {positions} from '@/config/button-position-settings'
import { roleTableSettings } from '@/config/table-settings';
import { rules } from '@/utils/rules'
import { loadCurrentPageTheButtons } from '@/api/system/menu'
import {loadRolePaging} from '@/api/system/role'
import {saveOrUpdateRole,deleteRoleById,lodaSelectMneusByRoleId,saveRoleMenuPower,changeDataScopeByRoleId} from '@/api/system/role'
import {loadMenuTreeByDataScope} from '@/api/system/menu'
import {getStore} from '@/utils/store'
import {loadDataScopeList} from '@/api/system/data-scope';
import { Loading } from 'element-ui'

export default{
    data(){

        return{
            defaultPageSizes,
            buttons:[],
            config:{
                columns:[
                   ...roleTableSettings
                ]
            },
            page:{
                ...defaultPage
            },
            search:{
                values:{},
                rules:{}
            },
            obtainPositionButtons,
            positions,
            roleFormDrawer:{
                data:{},
                hasOpen:false,
                title:'',

            },
            menuPowerDialog:{
                hasOpen:false,
                data:{},
                title:'',
                loading:false
            },
            menuTreeList:[

            ],
            dataPowerDialog:{
                hasOpen:false,
                data:{},
                title:''
            },
            dataPowerList:[],
            showPage:false,

        }
    },
    methods:{
        async searchHandler(){

            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.$t('public.resultMessages.unauthorizedView'));

                return;
            }


            const {data} = await loadRolePaging({
                current: 1,
                size: this.page.size,
                id: this.search.values.id,
                name: this.search.values.name
            });

            this.page = data;

        },
        async changPagingHandler(){
            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.$t('public.resultMessages.unauthorizedView'));

                return;
            }

            const {data} = await loadRolePaging({
                current: this.page.current,
                size: this.page.size,
                id: this.search.values.id,
                name: this.search.values.name
            });

            this.page = data;

        },
        operateHandler(code,row){

            switch(code){
                case 'add':
                    this.roleFormDrawer.hasOpen = true;
                    this.roleFormDrawer.title = this.$t('roleManagePage.roleFormDrawer.addTitle');
                break;
                case 'update':
                    this.roleFormDrawer.hasOpen = true;
                    this.roleFormDrawer.title =  this.$t('roleManagePage.roleFormDrawer.updateTitle');
                    this.roleFormDrawer.data = JSON.parse(JSON.stringify(row));
                break;
                case 'menu-power':
                    this.menuPowerDialog.hasOpen = true;
                    this.menuPowerDialog.title = this.$t('roleManagePage.menuPowerDialog.title');
                    this.menuPowerDialog.data = JSON.parse(JSON.stringify(row));
                    break;
                case 'data-power':
                    this.dataPowerDialog.hasOpen = true;
                    this.dataPowerDialog.title = this.$t('roleManagePage.dataPowerDialog.title');
                    this.dataPowerDialog.data = JSON.parse(JSON.stringify(row));
                    break;
                case 'delete':
                    this.deleteRole(row);
                    break;

            }

        },
        closeRoleFormDrawerHandler(){
            this.roleFormDrawer.data = {};
            this.$refs['roleFromRef'].resetFields();

        },
        saveOrUpdateRoleInfo(){
            this.$refs['roleFromRef'].validate( async (valid)=>{

                if(!valid){

                    return;
                }

                const data = await saveOrUpdateRole({
                    ...this.roleFormDrawer.data
                });

                if(data.code === 200){
                    this.changPagingHandler();
                    this.roleFormDrawer.hasOpen = false;
                }


            })

        },
        async saveOrUpdateMenuPower(){

            if(this.menuPowerDialog.loading){

                return;
            }

            const loadingInstance = Loading.service({
                target:'.loading-parent',
                background:'#fff',
                customClass:'loading-main',
                text:this.$t('public.loadingMessage.resourceLoading'),
                body:false
            });

            this.menuPowerDialog.loading = true;

            const childIds = this.$refs.tree.getCheckedKeys();
            const parentIds =  this.$refs.tree.getHalfCheckedKeys();
            const roleId = this.menuPowerDialog.data.id;

            const treeIds = [...childIds,...parentIds]

            // console.log('treeIds',treeIds)


            const data = await saveRoleMenuPower({
                id:roleId,
                selectMenus:treeIds
            });

            if(data&&data.code === 200){
                //如果是当前用户
                const userInfo = getStore('energy-user');
                if(userInfo&&roleId == userInfo.roleId){
                    location.reload();
                }
                this.menuPowerDialog.hasOpen = false;
            }

            loadingInstance.close();

            this.menuPowerDialog.loading = false;




        },
        async changeDataPower(){
            const data = await changeDataScopeByRoleId({
                id:this.dataPowerDialog.data.id,
                dataScope:this.dataPowerDialog.data.dataScope
            });
            if(data&&data.code === 200 ){
                this.changPagingHandler();
                this.dataPowerDialog.hasOpen = false;
            }
        },
        async selectMenuTreeNode(){ //选中的菜单节点
            const {data} = await lodaSelectMneusByRoleId({
                id:this.menuPowerDialog.data.id
            });
            if(data){
                let selectNodeArr = [];

                data.forEach(e =>{
                    if(!this.$refs['tree'].getNode(e).childNodes||!this.$refs.tree.getNode(e).childNodes.length){
                        selectNodeArr.push(e);
                    }
                });

                this.$refs.tree.setCheckedKeys([
                    ...selectNodeArr
                ]);
            }

        },
        async getCurrentPageButtons(){
            const {data} = await loadCurrentPageTheButtons({
                route:this.$route.path
            });

            this.buttons = data;
            this.showPage = true;
            this.changPagingHandler();
        },
        deleteRole(row){

            this.$confirm(this.$t('roleManagePage.confirmPopup.message'),this.$t('roleManagePage.confirmPopup.title'),{
                confirmButtonText: this.$t('public.button.confirm'),
                cancelButtonText: this.$t('public.button.cancel'),
                type: 'warning',
                center: true
            }).then( async ()=>{
                const data = await deleteRoleById({
                    id:row.id
                });

                if(data&&data.code === 200){
                    this.changPagingHandler();
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.$t('public.resultMessages.cancelDelete')
                });
            });



        },
        async getMenuTreeList(){
            const loadingInstance = Loading.service({
                target:'.loading-parent',
                background:'#fff',
                customClass:'loading-main',
                text:this.$t('public.loadingMessage.resourceLoading'),
                body:false
            });
            const {data} = await loadMenuTreeByDataScope();

            this.menuTreeList = data;

            await this.selectMenuTreeNode();

            loadingInstance.close();
        },
        async getDataScopeList(){
            const {data} = await loadDataScopeList();
            this.dataPowerList = data;
        }

    },
    mounted(){
        this.getCurrentPageButtons();
        this.getDataScopeList();
    },
    components:{
        ITable,
        IDrawer,
        IDialog
    },
    computed:{
        idField(){
            return this.$t('roleManagePage.field.id')
        },
        nameField(){
            return this.$t('roleManagePage.field.name')
        },
        aliasField(){
            return this.$t('roleManagePage.field.alias')
        },
        remarksField(){
            return this.$t('roleManagePage.field.remarks')
        },
        sortField(){
            return this.$t('roleManagePage.field.sort')
        },
        dataScopeField(){
            return this.$t('roleManagePage.field.dataScope')
        },
        submitButtonText(){
            return this.$t('public.button.submit')
        },
        cancelButtonText(){
            return this.$t('public.button.cancel')
        },
        idPlaceholder(){
            return this.$t('roleManagePage.form.id.placeholder')
        },
        namePlaceholder(){
            return this.$t('roleManagePage.form.name.placeholder')
        },
        aliasPlaceholder(){
            return this.$t('roleManagePage.form.alias.placeholder')
        },
        remarksPlaceholder(){
            return this.$t('roleManagePage.form.remarks.placeholder')
        },
        sortPlaceholder(){
            return this.$t('roleManagePage.form.sort.placeholder')
        },
        dataScopePlaceholder(){
            return this.$t('roleManagePage.form.remarks.placeholder')
        },
        roleFormRules(){ //表单校验
            return{
                name:[
                    {required:true,message:this.$t('roleManagePage.form.name.checkFail.required'),trigger: 'blur',pattern: '[^ \x22]+'}
                ],
                alias:[
                    {required:true,message:this.$t('roleManagePage.form.alias.checkFail.required'),trigger: 'blur',pattern: '[^ \x22]+'}
                ],
                sort:[
                    {required:true,message:this.$t('roleManagePage.form.sort.checkFail.required'),trigger: 'blur'}
                ]
            }
        },
        dataPowerFormRules(){
            return{
                dataScope:[
                    {required:true,message:this.$t('roleManagePage.form.dataScope.checkFail.required'),trigger: 'blur'}
                ]
            }
        }

    }
}

</script>

<style lang="scss">

.data-main-wrapper{
    height: 100%;
    .el-textarea__inner{ //element-ui 文本框 样式
        height: 200px;
        resize: none;
    }
    .menu-power-dialog-main{
        height: 500px;
        overflow-y: scroll;
        // .el-tree {
        //     overflow: auto
        // }
        .el-tree-node__content {
            align-self: baseline;
        }

    }
}

</style>
