import Vue from "vue";
import VueI18n from "vue-i18n";
import zhChLocale from 'element-ui/lib/locale/lang/zh-CN';
import ElementLocale from 'element-ui/lib/locale';
import zhCn from "./zh-cn";

Vue.use(VueI18n);

const messages = {

    zhCn: {
        language: '简体中文',
         ...zhCn,
        ...zhChLocale
    },
  }

const i18n = new VueI18n({
    locale: 'zhCn', // set locale
    messages, // set locale messages
  })
  
  ElementLocale.i18n((key, value) => i18n.t(key, value))


export {
    i18n,messages
}

