/**
 * 数组去重
 * @param {Array} arr 需要去重的数组
 *
 */
export function excludeDuplicates2Array(arr) {
    return Array.from(new Set(arr.map(JSON.stringify)), JSON.parse);
}

/**
 * 深度拷贝对象
 * @param {Object} source 源对象
 * @param {Object} target 目标对象
 */
export function deepCopyBean(source) {
    return JSON.parse(JSON.stringify(source));
}

export function toString(obj) {
    return JSON.stringify(obj);
}

/**
 *通过数组中对象的某个值去匹配对象
 * @param {Array} 目标数组
 * @param {string} 对象属性名
 * @param {String} 需要匹配的值
 *
 */

export function getArrayItemByItemAttribute(arr, key, value) {
    let entity = null;
    for (const item of arr) {

        if (item[key] === value) {
            entity = item;
            break;
        }

    }

    return entity;

}


/**
 *@param {string} key 缓存键
 *@returns vlaue 缓存值
 */
export function getValue2LocalCache(key) {
    return localStorage.getItem(key);
}

export function batchSetAttribute(arr, key, value, childNodeKey) {

    for (const item of arr) {
        item[key] = value;

        if (item[childNodeKey] != null && item[childNodeKey] != undefined && childNodeKey != undefined) {
            batchSetAttribute(item[childNodeKey], key, value, childNodeKey);
        }
    }

}

/**
 * 判断是否为空
 * @param val 参数
 * @returns {boolean}
 */
export const validateNull = (val) => {
    if (typeof val === 'boolean') {
        return false;
    }
    if (typeof val === 'number') {
        return false;
    }
    if (val instanceof Array) {
        return Object.keys(val).length === 0;
    } else if (val instanceof Object) {
        if (JSON.stringify(val) === '{}') {
            return true;
        }
    } else {
        if (val === 'null' || val === null || val === 'undefined' || val === undefined || val === '') {
            return true;
        }
    }
    return false;
}

/**
 * 判断业务是否成功
 * @param code 响应业务状态码
 * @returns {boolean}
 */
export const hasSuccess = (code) => {
    return code === 200;
}

/**
 * 格式化时间
 * @param date 当前时间 new Date()
 * @returns string 年月日时分秒
 */
export function formatDateTime(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return {
        year,
        month,
        day,
        hours,
        minutes,
        seconds
    }
}
