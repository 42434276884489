/**
 * 角色管理表格设置
 *
 * */

export const roleTableSettings = [
    {
        label:'角色编号',prop:'id',width:'200',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'角色名称',prop:'name',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'角色别名',prop:'alias',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'备注',prop:'remarks',width:'300',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'创建人',prop:'creatorName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'创建时间',prop:'createTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改人',prop:'modifierName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改时间',prop:'modifyTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'操作',prop:'operate',width:'350',fixed:'right',align:'center'
    }
]
/**
 *
 * 系统字典表格设置
 *
 * */
export const systemDataDictTableSettings = [
    {
        label:'字典编号',prop:'id',width:'200',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'字典名称',prop:'name',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'字典编码',prop:'code',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'字典值',prop:'value',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'备注',prop:'remarks',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'创建人',prop:'creatorName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'创建时间',prop:'createTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改人',prop:'modifierName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改时间',prop:'modifyTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
]

/**
 *
 * 业务字典
 *
 * */
export const businessDataDictTableSettings =[
    {
        label:'字典编号',prop:'id',width:'200',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'字典名称',prop:'name',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'字典编码',prop:'code',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'字典值',prop:'value',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'状态',prop:'statusName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'备注',prop:'remarks',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'创建人',prop:'creatorName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'创建时间',prop:'createTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改人',prop:'modifierName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改时间',prop:'modifyTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'操作',prop:'operate',width:'200',fixed:'right',align:'center'
    }
]

/**
 * 菜单管理
 * @type {[{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},null,null,null,null,null,null,null,null]}
 */
export const menuTableSettings = [
    {
        label:'菜单编号',prop:'id',width:'200',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'菜单名称',prop:'name',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'菜单图标',prop:'icon',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'菜单路由',prop:'route',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'菜单编码',prop:'code',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'新窗口打开',prop:'openNewWindowName',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'菜单类型',prop:'typeName',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'排序',prop:'sort',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'创建人',prop:'creatorName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'创建时间',prop:'createTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改人',prop:'modifierName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改时间',prop:'modifyTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'操作',prop:'operate',width:'200',fixed:'right',align:'center'
    }
]

/**
 * 充电规则管理表格设置
 * @type {[{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},null,null,null,null,null]}
 */
export const chargeRuleTableSettings = [
    {
        label:'充电规则编号',prop:'id',width:'260',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'充电规则名称',prop:'name',width:'240',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'所属机构',prop:'orgName',width:'240',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'电费计费类型',prop:'electricChargeTypeName',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'备注',prop:'remarks',width:'300',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'创建人',prop:'creatorName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'创建时间',prop:'createTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改人',prop:'modifierName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改时间',prop:'modifyTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'操作',prop:'operate',width:'300',fixed:'right',align:'center'
    }
]

/**
 * 平台用户钱包
 * @type {[{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},null,null,null,null,null]}
 */
export const clientUserWalletAccountTableSettings = [
    {
        label:'编号',prop:'id',width:'260',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'用户编号',prop:'userId',width:'260',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'用户昵称',prop:'nickname',width:'260',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'累计充值(元)',prop:'accumulateRechargeByYuan',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'剩余金额(元)',prop:'remainingSumByYuan',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'创建人',prop:'creatorName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'创建时间',prop:'createTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改人',prop:'modifierName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改时间',prop:'modifyTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'操作',prop:'operate',width:'300',fixed:'right',align:'center'
    }
]

/**
 * 用户充值订单表格设置
 * @type {[{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},{prop: string, width: string, fixed: boolean, label: string, align: string, showOverflowTooltip: boolean},null,null,null,null,null,null,null,null,null]}
 */
export const clientUserRechargeOrderTableSettings = [
    // {
    //     label:'编号',prop:'id',width:'260',fixed:false,align:'center',showOverflowTooltip:true,
    // },
    {
        label:'订单编号',prop:'orderCode',width:'260',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'下单用户编号',prop:'userId',width:'260',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'用户昵称',prop:'nickname',width:'200',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'下单手机号',prop:'mobile',width:'260',fixed:false,align:'center',showOverflowTooltip:true,
    },

    {
        label:'金额',prop:'amountByYuan',width:'200',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'下单时间',prop:'orderTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'支付平台',prop:'paymentTypeName',width:'260',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'第三方支付订单编号',prop:'paymentOrderCode',width:'260',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'支付时间',prop:'paymentTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'订单状态',prop:'statusName',width:'260',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'备注',prop:'remarks',width:'340',fixed:false,align:'center',showOverflowTooltip:true,
    },
    {
        label:'创建人',prop:'creatorName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'创建时间',prop:'createTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改人',prop:'modifierName',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'修改时间',prop:'modifyTime',width:'150',fixed:false,align:'center',showOverflowTooltip:true
    },
    {
        label:'操作',prop:'operate',width:'300',fixed:'right',align:'center',showOverflowTooltip:false
    }
]


export const systemUserTableSettings = [
    {label: '编号', prop: 'id', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '角色名称', prop: 'roleAlias', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '用户账号', prop: 'username', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '用户昵称', prop: 'nickname', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '手机号码', prop: 'mobile', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '头像', prop: 'avatar', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '出生日期', prop: 'birthday', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '邮箱', prop: 'email', width: '150', fixed: false, align: 'left', showOverflowTooltip: true},
    {label: '性别', prop: 'genderName', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '是否启用', prop: 'enableName', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '用户最后登录时间', prop: 'lastLoginTime', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '登录类型', prop: 'loginTypeName', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '机构名称', prop: 'orgName', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改人', prop: 'modifierName', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改时间', prop: 'modifyTime', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建人', prop: 'creatorName', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建时间', prop: 'createTime', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label:'操作',prop:'operate',width:'300',fixed:'right',align:'center'}
]

export const organizationTableSettings = [
    {label: '编号', prop: 'id', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '机构名称', prop: 'orgName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '父级机构', prop: 'parentOrgName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '联系人', prop: 'contactPerson', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '联系电话', prop: 'contactPhone', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '机构类型', prop: 'typeName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '地址', prop: 'address', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '级别', prop: 'level', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '备注', prop: 'remark', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改人', prop: 'modifierName', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改时间', prop: 'modifyTime', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建人', prop: 'creatorName', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建时间', prop: 'createTime', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label:'操作',prop:'operate',width:'160',fixed:'right',align:'center'}
]

export const dataScopeTableSettings = [
    {label: '编号', prop: 'id', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '权限编号', prop: 'code', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '数据范围名称', prop: 'name', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '数据范围描述', prop: 'remark', width: '230', fixed: false, align: 'center', showOverflowTooltip: true},
    // {label: '创建人', prop: 'creatorName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    // {label: '创建时间', prop: 'createTime', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    // {label: '修改人', prop: 'modifierName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    // {label: '修改时间', prop: 'modifyTime', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
]

export const chargeStationTableSettings = [
    {label: '编号', prop: 'id', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '充电站名称', prop: 'stationName', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '所属机构', prop: 'orgName', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '联系人', prop: 'contactPerson', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '联系电话', prop: 'contactPhone', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '国家', prop: 'country', width: '120', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '省份', prop: 'province', width: '120', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '城市', prop: 'city', width: '120', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '区/县', prop: 'district', width: '120', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '详细地址', prop: 'detailedAddress', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '完整的地址', prop: 'fullAddress', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '经度', prop: 'longitude', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '纬度', prop: 'latitude', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '获取地址方式', prop: 'getAddressTypeName', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '运营类型', prop: 'operateTypeName', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '站点图片', prop: 'image', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '备注', prop: 'remark', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改人', prop: 'modifierName', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改时间', prop: 'modifyTime', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建人', prop: 'creatorName', width: '100', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建时间', prop: 'createTime', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label:'操作',prop:'operate',width:'230',fixed:'right',align:'center'}
]

export const chargePileTableSettings = [
    {label: '主键', prop: 'id', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '所属机构', prop: 'orgName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '所属充电站', prop: 'stationName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '计费规则', prop: 'chargeRuleName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '充电桩编号', prop: 'chargePileCode', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '充电桩名称', prop: 'chargePileName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '品牌名称', prop: 'brandName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '品牌型号名称', prop: 'brandModelName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '品牌型号类型', prop: 'brandModelTypeName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '枪口数量', prop: 'gunNumber', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '最大功率(kw)', prop: 'maxPower', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '在线状态', prop: 'onlineStatusName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '服务状态', prop: 'serviceStatusName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '使用范围', prop: 'useScopeName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '是否启用', prop: 'enableName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '备注', prop: 'remark', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改人', prop: 'modifierName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改时间', prop: 'modifyTime', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建人', prop: 'creatorName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建时间', prop: 'createTime', width: '150', fixed: false, align: 'center', showOverflowTooltip: true},
    {label:'操作',prop:'operate',width:'200',fixed:'right',align:'center'}
]

export const clientWechatAppletUserTableSettings = [
    {label: '主键', prop: 'id', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '用户ID', prop: 'userId', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '昵称', prop: 'nickname', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '手机号码', prop: 'mobile', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '头像', prop: 'avatar', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '性别', prop: 'genderName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '邮箱', prop: 'email', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '身份类型', prop: 'identityTypeName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改人', prop: 'modifierName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改时间', prop: 'modifyTime', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建人', prop: 'creatorName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建时间', prop: 'createTime', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
]

export const chargePileBrandTableSettings = [
    {label: '主键', prop: 'id', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    // {label: '充电桩品牌编号', prop: 'brandCode', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '充电桩品牌名称', prop: 'brandName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '协议类型编号', prop: 'protocolTypeCode', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '协议类型名称', prop: 'protocolTypeName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '备注', prop: 'remark', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改人', prop: 'modifierName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改时间', prop: 'modifyTime', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建人', prop: 'creatorName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建时间', prop: 'createTime', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '操作', prop:'operate',width:'130',fixed:'right',align:'center'}
]

export const chargePileBrandModelTableSettings = [
    {label: '主键', prop: 'id', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '品牌名称', prop: 'brandName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '品牌型号名称', prop: 'brandModelName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '品牌型号类型', prop: 'brandModelTypeName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '充电枪数量', prop: 'gunNumber', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '最大功率', prop: 'maxPower', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '备注', prop: 'remark', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改人', prop: 'modifierName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改时间', prop: 'modifyTime', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建人', prop: 'creatorName', width: '130', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建时间', prop: 'createTime', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '操作', prop:'operate',width:'130',fixed:'right',align:'center'}
]

export const clientUserChargingOrderTableSettings = [
    {label: '主键', prop: 'id', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '充电订单编号', prop: 'chargingOrderCode', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '交易流水号', prop: 'transactionSerialNumber', width: '260', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '用户手机号', prop: 'mobile', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '机构名称', prop: 'orgName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '充电站名称', prop: 'stationName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '充电桩编号', prop: 'chargePileCode', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '充电桩名称', prop: 'chargePileName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '充电枪编号', prop: 'chargeGunCode', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '完整充电枪编号', prop: 'fullChargeGunCode', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '起始充电时间', prop: 'chargingStartTime', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '起止充电时间', prop: 'chargingEndTime', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '尖单价', prop: 'sharpUnitPriceByYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '尖电量', prop: 'sharpElectricQuantity', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '计损尖电量', prop: 'sharpLossElectricQuantity', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '尖金额', prop: 'sharpAmountByYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '峰单价', prop: 'peakUnitPriceByYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '峰电量', prop: 'peakElectricQuantity', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '计损峰电量', prop: 'peakLossElectricQuantity', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '峰金额', prop: 'peakAmountByYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '平单价', prop: 'flatUnitPriceByYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '平电量', prop: 'flatElectricQuantity', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '计损平电量', prop: 'flatLossElectricQuantity', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '平金额', prop: 'flatAmountByYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '谷单价', prop: 'valleyUnitPriceByYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '谷电量', prop: 'valleyElectricQuantity', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '计损谷电量', prop: 'valleyLossElectricQuantity', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '谷金额', prop: 'valleyAmountByYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '电表总起值', prop: 'meterTotalStartValue', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '电表总止值', prop: 'meterTotalEndValue', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '总电量', prop: 'totalElectricity', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '计损总电量', prop: 'lossTotalElectric', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '消费金额', prop: 'consumeAmountByYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '订单状态', prop: 'statusName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '停止原因', prop: 'stopReason', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '交易日期', prop: 'transactionDate', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '实际支付金额', prop: 'actualPaidAmountByYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建时间', prop: 'createTime', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
]

export const clientUserRefundOrderTableSettings = [
    {label: '主键', prop: 'id', width: '200', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '用户ID', prop: 'userId', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '用户充值订单ID', prop: 'rechargeOrderId', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '退款订单编号', prop: 'refundOrderCode', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '本平台充值订单编号', prop: 'rechargeOrderCode', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '第三方支付平台订单编号', prop: 'paymentOrderCode', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '支付金额(元)', prop: 'paidAmountYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '本次退款金额(元)', prop: 'refundAmountYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '退款手续费(元)', prop: 'refundFeeYuan', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '交易货币类型(CNY:人民币)', prop: 'currency', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '退款成功时间', prop: 'successTime', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '受理时间', prop: 'receptionTime', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '退款状态', prop: 'refundStatusName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '退款原因', prop: 'refundReason', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '支付平台类型', prop: 'paymentTypeName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '第三方退款订单ID', prop: 'thirdRefundId', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '是否回调成功', prop: 'hasCallbackName', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '回调时间', prop: 'callbackTime', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '退款失败原因', prop: 'refundFailedReason', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '当前退款单的退款入账方', prop: 'userReceivedAccount', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '修改时间', prop: 'modifyTime', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
    {label: '创建时间', prop: 'createTime', width: '160', fixed: false, align: 'center', showOverflowTooltip: true},
]
