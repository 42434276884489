export const timeSpanOptions =[
    {timeSpan:'00:00-00:30'},
    {timeSpan:'00:30-01:00'},
    {timeSpan:'01:00-01:30'},
    {timeSpan:'01:30-02:00'},
    {timeSpan:'02:00-02:30'},
    {timeSpan:'02:30-03:00'},
    {timeSpan:'03:00-03:30'},
    {timeSpan:'03:30-04:00'},
    {timeSpan:'04:00-04:30'},
    {timeSpan:'04:30-05:00'},
    {timeSpan:'05:00-05:30'},
    {timeSpan:'05:30-06:00'},
    {timeSpan:'06:00-06:30'},
    {timeSpan:'06:30-07:00'},
    {timeSpan:'07:00-07:30'},
    {timeSpan:'07:30-08:00'},
    {timeSpan:'08:00-08:30'},
    {timeSpan:'08:30-09:00'},
    {timeSpan:'09:00-09:30'},
    {timeSpan:'09:30-10:00'},
    {timeSpan:'10:00-10:30'},
    {timeSpan:'10:30-11:00'},
    {timeSpan:'11:00-11:30'},
    {timeSpan:'11:30-12:00'},
    {timeSpan:'12:00-12:30'},
    {timeSpan:'12:30-13:00'},
    {timeSpan:'13:00-13:30'},
    {timeSpan:'13:30-14:00'},
    {timeSpan:'14:00-14:30'},
    {timeSpan:'14:30-15:00'},
    {timeSpan:'15:00-15:30'},
    {timeSpan:'15:30-16:00'},
    {timeSpan:'16:00-16:30'},
    {timeSpan:'16:30-17:00'},
    {timeSpan:'17:00-17:30'},
    {timeSpan:'17:30-18:00'},
    {timeSpan:'18:00-18:30'},
    {timeSpan:'18:30-19:00'},
    {timeSpan:'19:00-19:30'},
    {timeSpan:'19:30-20:00'},
    {timeSpan:'20:00-20:30'},
    {timeSpan:'20:30-21:00'},
    {timeSpan:'21:00-21:30'},
    {timeSpan:'21:30-22:00'},
    {timeSpan:'22:00-22:30'},
    {timeSpan:'22:30-23:00'},
    {timeSpan:'23:00-23:30'},
    {timeSpan:'23:30-00:00'},
]