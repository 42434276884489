<template>
  <div class="data-main-wrapper">
    <i-table v-if="table.showTable" :config="table.config" :page="table.page" @change-page="handleChangePage">
      <template slot="search-condition">
        <el-form :inline="true" :model="table.searchCondition" size="small">
          <el-form-item :label="field.stationName + ':'">
            <el-input clearable v-model="table.searchCondition.stationName"
                      :placeholder="form.stationName.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.orgName + ':'">
            <el-select v-model="table.searchCondition.orgId" clearable :placeholder="form.orgId.placeholder">
              <el-option
                  v-for="(item, index) in belongOrg"
                  :key="index"
                  :label="item.orgName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item :label="field.countryRegion + ':'">
                      <i-cascader
                          :options="countryRegionList"
                          :props="{expandTrigger:'hover',value:'name',label:'name',children:'childNode'}"
                          @change="handleSearchCountryRegion"
                          clearable
                          size="medium"
                          v-model="table.countryRegion"
                          filterable
                          :placeholder="form.countryRegion.placeholder">
                      </i-cascader>
                    </el-form-item>
                    <el-form-item :label="field.contactPhone + ':'">
                      <el-input clearable v-model="table.searchCondition.contactPhone"
                                :placeholder="form.contactPhone.placeholder"></el-input>
                    </el-form-item>-->
          <el-form-item :label="field.detailedAddress + ':'">
            <el-input clearable :placeholder="form.detailedAddress.placeholder"
                      v-model="table.searchCondition.fullAddress">
            </el-input>
          </el-form-item>
          <el-form-item :label="field.operateTypeName + ':'">
            <el-select v-model="table.searchCondition.operateType" clearable
                       :placeholder="form.operateTypeName.required">
              <el-option
                  v-for="(item, index) in operateTypes"
                  :key="index"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <!--搜索按钮-->
      <template slot="search-button">
        <el-button type="primary" size="small" @click="handleChangePage(true)">{{ btn.search }}</el-button>
      </template>
      <!--全局操作-->
      <template slot="overall-left">
        <el-button
            type="primary"
            v-for="(btn, index) in btnPosition.obtainPositionButtons(table.buttons, btnPosition.positions.left)"
            :key="index"
            size="small"
            :icon="btn.icon"
            @click="handleOperate(btn.code)">
          {{ btn.name }}
        </el-button>
      </template>
      <!--表头-->
      <template slot="header" slot-scope="scope">
        {{ field[scope.column.property] }}
      </template>
      <!--行内操作-->
      <template slot="row_operate" slot-scope="scope">
        <el-button type="text"
                   v-for="(btn, index) in btnPosition.obtainPositionButtons(table.buttons, btnPosition.positions.row)"
                   :key="index"
                   size="mini"
                   :icon="btn.icon"
                   @click="handleOperate(btn.code, scope.row)">
          {{ btn.name }}
        </el-button>
      </template>
    </i-table>

    <!--drawer-->
    <i-drawer :visible.sync="drawer.visible" :title="drawer.title"
              size="30%" @close="handleCloseDrawer">
      <el-form ref="chargeStationForm" :model="drawer.chargeStationForm" :rules="changeStationRules"
               label-width="110px" label-position="right" size="small">
        <el-form-item :label="field.stationName + ':'" prop="stationName">
          <el-input v-model="drawer.chargeStationForm.stationName"
                    :placeholder="form.stationName.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="field.orgName + ':'" prop="orgId">
          <el-select v-model="drawer.chargeStationForm.orgId" clearable :placeholder="form.orgId.placeholder">
            <el-option
                v-for="(item, index) in belongOrg"
                :key="index"
                :label="item.orgName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="field.contactPerson + ':'" prop="contactPerson">
          <el-input v-model="drawer.chargeStationForm.contactPerson"
                    :placeholder="form.contactPerson.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="field.contactPhone + ':'" prop="contactPhone">
          <el-input v-model="drawer.chargeStationForm.contactPhone"
                    :placeholder="form.contactPhone.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="field.getAddressTypeName + ':'" prop="getAddressType">
          <el-radio-group v-model="drawer.chargeStationForm.getAddressType">
            <el-radio v-for="(item, index) in getAddressTypes" :key="index" :label="item.value"> {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div key="1" v-if="drawer.chargeStationForm.getAddressType === drawer.getAddressType">
          <el-form-item :label="field.countryRegion + ':'" prop="countryRegion">
            <i-cascader
                :options="countryRegionList"
                :props="{expandTrigger:'hover',value:'name',label:'name',children:'childNode'}"
                @change="handleChangeCountryRegion()"
                clearable
                size="medium"
                v-model="drawer.chargeStationForm.countryRegion"
                filterable
                :placeholder="form.countryRegion.placeholder">
            </i-cascader>
          </el-form-item>
          <el-form-item :label="field.detailedAddress + ':'" prop="detailedAddress">
            <el-input type="textarea" rows="2" v-model="drawer.chargeStationForm.detailedAddress"
                      :placeholder="form.detailedAddress.placeholder"></el-input>
          </el-form-item>
        </div>
        <div key="2" v-else> <!--v-if="drawer.chargeStationForm.getAddressType === drawer.longLatType"-->
          <el-form-item :label="field.longitude + ':'" prop="longitude">
            <el-input v-model="drawer.chargeStationForm.longitude"
                      :placeholder="form.longitude.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.latitude + ':'" prop="latitude">
            <el-input v-model="drawer.chargeStationForm.latitude"
                      :placeholder="form.latitude.placeholder"></el-input>
          </el-form-item>
        </div>
        <el-form-item :label="field.operateTypeName + ':'">
          <el-radio-group v-model="drawer.chargeStationForm.operateType">
            <el-radio v-for="(item, index) in operateTypes" :key="index" :label="item.value"> {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!--        <el-form-item :label="field.image.name">
                &lt;!&ndash;圖片上传先不做&ndash;&gt;
                </el-form-item>-->
        <el-form-item :label="field.remark + ':'" prop="remark">
          <el-input type="textarea" rows="2"
                    v-model="drawer.chargeStationForm.remark"
                    :placeholder="form.remark.placeholder"></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button type="primary" @click="handleDrawerSubmit">{{ btn.submit }}</el-button>
        <el-button @click="drawer.visible = false">{{ btn.cancel }}</el-button>
      </template>
    </i-drawer>
  </div>
</template>

<script>
import {defaultPage,defaultPageSizes} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable.vue';
import IDrawer from '@/components/i-drawer/IDrawer.vue';
import ICascader from '@/components/i-cascader/ICascader.vue';
import {chargeStationTableSettings} from '@/config/table-settings';
import {loadCurrentPageTheButtons} from '@/api/system/menu';
import {formatDateTime, hasSuccess, validateNull} from '@/utils/tool';
import {positions} from '@/config/button-position-settings';
import {
  deleteChargeStation,
  downloadChargePileQrCodeByStationId,
  listChargeStationPage,
  saveOrUpdateChargeStation
} from '@/api/business/charge-station'
import {obtainPositionButtons, showData} from '@/utils/power';
import {listOrgSelect} from '@/api/system/organization';
import {loadDataDictByCode} from '@/api/system/data-dict';
import {rules} from '@/utils/rules';
import {loadTree} from '@/api/system/global-administrative-divisions';
import {message} from '@/utils/message';

export default {
  components: {
    'i-table': ITable,
    'i-drawer': IDrawer,
    'i-cascader': ICascader,
  },
  computed: {
    btnPosition: {
      get: function () {
        return {obtainPositionButtons, positions};
      }
    },
    public() {
      return {
        unauthorizedView: this.$t('public.resultMessages.unauthorizedView')
      }
    },
    field() {
      const prefix = 'chargeStationManagePage.field';
      return {
        id: this.$t(`${prefix}.id`),
        stationName: this.$t(`${prefix}.stationName`),
        orgName: this.$t(`${prefix}.orgName`),
        contactPerson: this.$t(`${prefix}.contactPerson`),
        contactPhone: this.$t(`${prefix}.contactPhone`),
        country: this.$t(`${prefix}.country`),
        province: this.$t(`${prefix}.province`),
        city: this.$t(`${prefix}.city`),
        district: this.$t(`${prefix}.district`),
        detailedAddress: this.$t(`${prefix}.detailedAddress`),
        fullAddress: this.$t(`${prefix}.fullAddress`),
        longitude: this.$t(`${prefix}.longitude`),
        latitude: this.$t(`${prefix}.latitude`),
        operateTypeName: this.$t(`${prefix}.operateTypeName`),
        getAddressTypeName: this.$t(`${prefix}.getAddressTypeName`),
        image: this.$t(`${prefix}.image`),
        remark: this.$t(`${prefix}.remark`),
        modifierName: this.$t(`${prefix}.modifierName`),
        modifyTime: this.$t(`${prefix}.modifyTime`),
        creatorName: this.$t(`${prefix}.creatorName`),
        createTime: this.$t(`${prefix}.createTime`),
        countryRegion: this.$t(`${prefix}.countryRegion`),
        operate: this.$t(`${prefix}.operate`),
      }
    },
    form() {
      const prefix = 'chargeStationManagePage.form';
      return {
        stationName: {
          placeholder: this.$t(`${prefix}.stationName.placeholder`),
          required: this.$t(`${prefix}.stationName.required`),
          rule: this.$t(`${prefix}.stationName.rule`)
        },
        orgId: {
          placeholder: this.$t(`${prefix}.orgId.placeholder`),
          required: this.$t(`${prefix}.orgId.required`)
        },
        contactPerson: {
          placeholder: this.$t(`${prefix}.contactPerson.placeholder`),
          required: this.$t(`${prefix}.contactPerson.required`),
          rule: this.$t(`${prefix}.contactPerson.rule`)
        },
        contactPhone: {
          placeholder: this.$t(`${prefix}.contactPhone.placeholder`),
          required: this.$t(`${prefix}.contactPhone.required`),
          rule: this.$t(`${prefix}.contactPhone.rule`),
        },
        province: {placeholder: this.$t(`${prefix}.province.placeholder`)},
        city: {placeholder: this.$t(`${prefix}.city.placeholder`)},
        district: {placeholder: this.$t(`${prefix}.district.placeholder`)},
        detailedAddress: {
          placeholder: this.$t(`${prefix}.detailedAddress.placeholder`),
          required: this.$t(`${prefix}.detailedAddress.required`),
          rule: this.$t(`${prefix}.detailedAddress.rule`)
        },
        longitude: {
          placeholder: this.$t(`${prefix}.longitude.placeholder`),
          required: this.$t(`${prefix}.longitude.required`),
          rule: this.$t(`${prefix}.longitude.rule`)
        },
        latitude: {
          placeholder: this.$t(`${prefix}.latitude.placeholder`),
          required: this.$t(`${prefix}.latitude.required`),
          rule: this.$t(`${prefix}.latitude.rule`)
        },
        operateTypeName: {
          required: this.$t(`${prefix}.operateTypeName.required`)
        },
        getAddressTypeName: {required: this.$t(`${prefix}.getAddressTypeName.required`)},
        remark: {
          placeholder: this.$t(`${prefix}.remark.placeholder`),
          rule: this.$t(`${prefix}.remark.rule`)
        },
        countryRegion: {
          placeholder: this.$t(`${prefix}.countryRegion.placeholder`),
          required: this.$t(`${prefix}.countryRegion.required`)
        }
      }
    },
    btn() {
      return {
        search: this.$t('public.button.search'),
        submit: this.$t('public.button.submit'),
        cancel: this.$t('public.button.cancel'),
        confirm: this.$t('public.button.confirm'),
      }
    },
    changeStationRules() {
      let validContactPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.form.contactPhone.required));
        }
        if (!rules.phone.test(value)) {
          return callback(new Error(this.form.contactPhone.rule));
        }
        return callback();
      }
      let validLongitude = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.form.longitude.required));
        }
        if (!rules.six_decimal_places.test(value)) {
          return callback(new Error(this.form.longitude.rule));
        }
        return callback();
      }
      let validLatitude = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.form.latitude.required));
        }
        if (!rules.six_decimal_places.test(value)) {
          return callback(new Error(this.form.latitude.rule));
        }
        return callback();
      }
      return {
        stationName: [{required: true, message: this.form.stationName.required, trigger: 'blur'},
          {min: 1, max: 30, message: this.form.stationName.rule, trigger: 'blur'}],
        orgId: [{required: true, message: this.form.orgId.required, trigger: 'change'}],
        contactPerson: [{required: true, message: this.form.contactPerson.required, trigger: 'blur'},
          {min: 1, max: 30, message: this.form.contactPerson.rule, trigger: 'blur'}],
        contactPhone: [{required: true, validator: validContactPhone, trigger: 'blur'}],
        getAddressType: [{required: true, message: this.form.getAddressTypeName.required, trigger: 'blur'}],
        detailedAddress: [{required: true, message: this.form.detailedAddress.required, trigger: 'blur'},
          {min: 1, max: 300, message: this.form.detailedAddress.rule, trigger: 'blur'}],
        longitude: [{required: true, validator: validLongitude, trigger: 'blur'}],
        latitude: [{required: true, validator: validLatitude, trigger: 'blur'}],
        remark: [{min: 0, max: 300, message: this.form.remark.rule, trigger: 'blur'}],
        countryRegion: [{type: 'array', required: true, message: this.form.countryRegion.required, trigger: 'change'}]
      }
    },
    confirm() {
      const prefix = 'chargeStationManagePage.confirm';
      return {
        title: this.$t(`${prefix}.title`),
        cancelMessage: this.$t(`${prefix}.cancelMessage`),
      }
    }
  },
  data() {
    return {
      defaultPageSizes,
      table: {
        showTable: false,
        config: {
          columns: [...chargeStationTableSettings],
          rowKey: 'id',
        },
        page: {
          ...defaultPage
        },
        buttons: [],
        searchCondition: {
          stationName: '',
          orgId: null,
          // contactPhone: '',
          // country: '',
          // province: '',
          // city: '',
          // district: '',
          operateType: null,
          fullAddress: '',
        },
        // countryRegion: [],
      },
      /**
       * 运营类型（1-加盟 2-自营 3-第三方）
       */
      operateTypes: [],
      /**
       * 所属机构
       */
      belongOrg: [],
      /**
       * 获取地址类型（1-详细地址 2-经纬度）
       * 详细地址：通过详细地址获取经纬度
       * 经纬度：通过经纬度获取详细地址
       */
      getAddressTypes: [],
      /**
       * 国家地区数据集
       */
      countryRegionList: [],
      drawer: {
        title: '',
        visible: false,
        /**
         * 充电站表单
         */
        chargeStationForm: {
          getAddressType: 1,
          operateType: 2,
        },
        /**
         * 地址类型
         */
        getAddressType: 1,
        /**
         * 经纬度类型
         */
        longLatType: 2,
      },
    }
  },
  mounted() {
    this.initCurrentPageButton();
  },
  watch: {
    /*'table.countryRegion'(val) {
      // 清除国家地区值的时候, 需要清空查询条件的国家、省市区
      if (validateNull(val)) {
        this.table.searchCondition.country = '';
        this.table.searchCondition.province = '';
        this.table.searchCondition.city = '';
        this.table.searchCondition.district = '';
      }
    },*/
    // TODO 将这一块挪到提交的时候做
    // 监听表单中获取地址方式的数值,分别做处理
    /*'drawer.chargeStationForm.getAddressType'(val) {
      // 获取地址方式是通过地址获取,则清空经纬度
      if (!validateNull(val) && this.drawer.getAddressType === val) {
        this.drawer.chargeStationForm.longitude = null;
        this.drawer.chargeStationForm.latitude = null;
      }
      // 获取地址方式是通过经纬度获取,则清空地址数据
      if (!validateNull(val) && this.drawer.longLatType === val) {
        this.drawer.chargeStationForm.country = '';
        this.drawer.chargeStationForm.province = '';
        this.drawer.chargeStationForm.city = '';
        this.drawer.chargeStationForm.district = '';
        this.drawer.chargeStationForm.countryRegion = [];
      }
    }*/
  },
  methods: {
    /**
     * 处理查询选中国家地区
     */
    /*handleSearchCountryRegion(e) {
      let countryRegion = this.table.countryRegion;
      if (countryRegion && countryRegion.length > 0) {
        let length = countryRegion.length;
        for (let index = 1; index <= length; index++) {
          if (index === this.regionLength.country) {
            this.table.searchCondition.country = countryRegion[index - 1];
          } else if (index === this.regionLength.province) {
            this.table.searchCondition.province = countryRegion[index - 1];
          } else if (index === this.regionLength.city) {
            this.table.searchCondition.city = countryRegion[index - 1];
          } else if (index === this.regionLength.district) {
            this.table.searchCondition.district = countryRegion[index - 1];
          }
        }
      }
    },*/
    /**
     * 处理选中国家地区
     */
    handleChangeCountryRegion(e) {
      // 地区信息key名
      let regionKey = ['country', 'province', 'city', 'district'];
      let countryRegion = this.drawer.chargeStationForm.countryRegion;
      if (countryRegion && countryRegion.length > 0) {
        // 清空原来的地址信息
        Object.assign(this.drawer.chargeStationForm, {country: '', province: '', city: '', district: ''});
        countryRegion.forEach((item, index) => {
          this.drawer.chargeStationForm[regionKey[index]] = item;
        });
      }
    },
    /**
     * 处理提交Drawer数据
     */
    handleDrawerSubmit() {
      // 提交form实体到后端
      this.$refs.chargeStationForm.validate(async (valid) => {
        if (valid) {
          // 判断获取方式类型
          let getAddressType = this.drawer.chargeStationForm.getAddressType;
          if (!validateNull(getAddressType) && this.drawer.getAddressType === getAddressType) {
            Object.assign((this.drawer.chargeStationForm, {longitude: null, latitude: null}));
          } else {
            Object.assign(this.drawer.chargeStationForm, {
              country: '',
              province: '',
              city: '',
              district: '',
              countryRegion: []
            });
          }
          let {code} = await saveOrUpdateChargeStation(this.drawer.chargeStationForm);
          if (hasSuccess(code)) {
            await this.initChargeStation();
            this.drawer.visible = false;
          }
        }
      });
    },
    /**
     * 处理关闭Drawer
     */
    handleCloseDrawer() {
      this.drawer.visible = false;
      this.$refs.chargeStationForm.resetFields();
      this.drawer.chargeStationForm = {
        getAddressType: 1,
        operateType: 2,
        longitude: null,
        latitude: null
      };
    },
    /**
     * 行内操作
     * @param btnCode 操作类型编号
     * @param row 当前行数据
     */
    handleOperate(btnCode, row) {
      switch (btnCode) {
        case 'add':
          this.drawer.title = '添加充电站';
          this.drawer.visible = true;
          break;
        case 'update':
          this.handleDrawerUpdate(row);
          this.drawer.title = '修改充电站';
          this.drawer.visible = true;
          break;
        case 'delete':
          this.handleDrawerDelete(row);
          break;
        case 'download-qr-code':
          this.downloadGunQrCode(row.id);
          break;
        case 'data-big-screen':
          // 跳转数据大屏
          window.open(this.$router.resolve({ path: '/stored-energy-big-screen', query: {stationName: row.stationName} }).href, '_blank');
          break;
        default:
          break;
      }
    },
    /**
     * 下载二维码图片
     * @param stationId 充电站ID
     */
    async downloadGunQrCode(stationId) {
      let resp = await downloadChargePileQrCodeByStationId({id: stationId});
      let respType = resp.type;
      if (respType === 'application/json') {
        const reader = new FileReader();
        reader.onload = () => {
          const jsonData = JSON.parse(reader.result);
          if (jsonData.code !== 200) {
            message.error({message: jsonData.message});
          }
        };
        reader.readAsText(resp);
      } else if ( respType === 'application/zip') {
        let blob = new Blob([resp], {type: 'application/zip'});
        let url = window.URL.createObjectURL(blob);
        // 创建a标签
        const link = document.createElement('a');
        link.href = url;
        // 重命名下载文件名
        const {year, month, day, hours, minutes, seconds} = formatDateTime(new Date());
        link.download = `充电枪二维码列表${year}${month}${day}${hours}${minutes}${seconds}.zip`;
        link.click();
        // 释放内存
        URL.revokeObjectURL(url);
      } else {
        console.log('未知的响应类型', respType);
      }
    },
    /**
     * 处理Drawer的删除操作
     * @param row
     */
    handleDrawerDelete(row) {
      this.$confirm(this.$t('chargeStationManagePage.confirm.confirmMessage', {stationName: row.stationName}), this.confirm.title, {
        confirmButtonText: this.btn.confirm,
        cancelButtonText: this.btn.cancel,
        type: 'warning'
      }).then(async () => {
        await deleteChargeStation({id: row.id});
        await this.initChargeStation();
      }).catch(() => {
        message.info({message: this.confirm.cancelMessage});
      });
    },
    /**
     * 处理Drawer的更新操作
     * @param row 当前行数据
     */
    handleDrawerUpdate(row) {
      let station = Object.assign({}, row);
      if (this.drawer.getAddressType === station.getAddressType) {
        let {country, province, city, district} = station;
        // 过滤掉空的数据
        let countryRegion = [country, province, city, district].filter(item => !validateNull(item));
        this.drawer.chargeStationForm = {...station, countryRegion: countryRegion};
      } else {
        this.drawer.chargeStationForm = station;
      }
    },
    /**
     * 处理更改页面
     */
    handleChangePage(hasSearch) {
      if (hasSearch) {
        this.table.page.current = 1;
      }
      this.initChargeStation();
    },
    /**
     * 初始化充电站
     */
    async initChargeStation() {
      const show = showData(this.table.buttons, positions.view);
      if (!show) {
        this.$message.error(this.public.unauthorizedView);
        return;
      }
      let {code, data} = await listChargeStationPage({
        current: this.table.page.current,
        size: this.table.page.size,
        ...this.table.searchCondition
      });
      if (hasSuccess(code)) {
        this.table.page = {
          current: data.current,
          size: data.size,
          total: data.total,
          records: data.records
        }
      }
    },
    /**
     * 初始化页面按钮
     */
    async initCurrentPageButton() {
      let {code, data} = await loadCurrentPageTheButtons({route: this.$route.path});
      if (hasSuccess(code)) {
        this.table.buttons = data;
        this.table.showTable = true;
        // 初始化所属机构下拉列表
        let {data: orgData} = await listOrgSelect();
        this.belongOrg = orgData;
        // 初始化运营类型
        let {data: operateType} = await loadDataDictByCode({code: 'operate_type'});
        // 初始化获取地址方式
        this.operateTypes = operateType;
        // 初始化充电站数据
        this.initChargeStation();
        // 初始化获取地址方式
        let {data: getAddressType} = await loadDataDictByCode({code: 'get_address_type'});
        this.getAddressTypes = getAddressType;
        // 初始化国家、省市区信息
        let {data: countryRegion} = await loadTree();
        this.countryRegionList = countryRegion;
      }
    },
  }
}

</script>

<style scoped lang="scss">

</style>
