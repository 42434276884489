import { Dialog,Drawer} from 'element-ui'


//对话框
const DialogPatchd = {
    extends:Dialog,
    data(){

        return{
            wrapperMouseDowend:false
        }

    },
    methods:{
        handleWrapperClick(){
            //判断用户点击是否点击遮罩层，如果点击则忽略
            if(!this.closeOnClickModal || !this.wrapperMouseDowend) return;
            this.handleClose();
        }
    },
    mounted(){
        // console.log(`${Dialog.name}增强补丁...`);
        this.$el.onmousedown = (e)=>{
            this.wrapperMouseDowend = e.target.classList.contains('el-dialog__wrapper');

        }
    }

}

//抽屉

const DrawerPatchd ={
    extends:Drawer,
    data(){
        

        return{
            wrapperMouseDowend:false
        }
    },
    methods:{
        handleWrapperClick() {
            if (this.wrapperClosable && this.wrapperMouseDowend) {
              this.closeDrawer();
            }
          }

    },
    mounted(){
        // console.log(`${Drawer.name}增强补丁...`);
        this.$el.onmousedown = (e)=>{
            this.wrapperMouseDowend = e.target.classList.contains('el-drawer__container');

        }
    }
}

export default{
    install(Vue){
        Vue.component(Dialog.name,DialogPatchd);
        Vue.component(Drawer.name,DrawerPatchd);
    }
}