<template>
    <div class="system-dict-business_wrapper data-main-wrapper">

        <i-table
        v-if="showPage"
        :config="config"
        :page="page"
        @change-page="changePagingHandler"
        :load="lazyLoadHandler"
        :page-sizes="defaultPageSizes"
        ref="iTableRef"
        >

            <!-- 搜索栏 -->
            <template v-slot:search-condition>
                <el-form :model="search.values" :inline="true" ref="searchRef" :rules="searchFormRules">
                    <el-form-item :label="businessDataDictFields.id+':'" prop="id" ref="dataDictIdRef">
                        <el-input @clear="$refs['dataDictIdRef'].clearValidate()" size="small" v-model="search.values.id"  :placeholder="businessDataDictFrom.id.placeholder" clearable />
                    </el-form-item>
                    <el-form-item :label="businessDataDictFields.name+':'">
                        <el-input size="small" v-model="search.values.name" :placeholder="businessDataDictFrom.name.placeholder"  clearable />
                    </el-form-item>
                </el-form>
                
            </template>
            <!-- 搜索按钮 -->
            <template v-slot:search-button>
                <el-button type="primary"  size="small" @click="searchHandler" icon="el-icon-search">{{ buttonText.search }}</el-button>
            </template>

            <!-- 左侧功能栏 -->
            <template  v-slot:overall-left>
                <el-button 
                v-for="(overallLeftButton,index) in obtainPositionButtons(buttons,positions.left)" 
                :key="index" 
                type="primary"  
                size="small" 
                :icon="overallLeftButton.icon" 
                @click="operateHandler(overallLeftButton.code)"
                >
                    {{overallLeftButton.name}}
                </el-button>
            </template>

            <!-- 右侧工作栏 -->
            <template  v-slot:overall-right>
                <el-button 
                v-for="(overallRightButton,index) in obtainPositionButtons(buttons,positions.right)" 
                :key="index"   
                size="small" 
                :icon="overallRightButton.icon" 
                @click="operateHandler(overallRightButton.code)"
                >
                    {{overallRightButton.name}}
                </el-button>
            </template>
            <!-- 行内插槽 -->

            <!-- 表头 -->
           <template  v-slot:header="scope">
                {{ businessDataDictFields[scope.column.property] }}
            </template>

            <template v-slot:row_statusName="scope">
                <el-tag>{{ scope.row[scope.column.property] }}</el-tag>

            </template>

            <template v-slot:row_operate="scope">
                <el-button 
                type="text" 
                :icon="rowButton.icon" 
                size="mini" 
                v-for="(rowButton,index) in obtainPositionButtons(buttons,positions.row) " 
                :key="index" 
                @click="operateHandler(rowButton.code,scope.row)"
                > 
                    {{ rowButton.name  }}
                </el-button>
            </template>
    
        </i-table>

        <!-- 业务字典修改或者添加的表单抽屉 -->
        <i-drawer
        :title="businessDataDictFormDrawer.title"
        :visible.sync="businessDataDictFormDrawer.hasOpen"
        @close="businessDataDictFormDrawer.data = {status:1};$refs['businessDataDictFromRef'].resetFields();"
        @open="getAllBusinessDataDictList"
        >
            <el-form 
            :model="businessDataDictFormDrawer.data"
            :rules="businessDataDictFormRules"
            ref="businessDataDictFromRef"
            label-width="100px"
            >
                <el-form-item :label="businessDataDictFields.name + ':' " prop="name">
                    <el-input size="small" v-model="businessDataDictFormDrawer.data.name" :placeholder="businessDataDictFrom.name.placeholder" />
                </el-form-item>
                <el-form-item :label="businessDataDictFields.code + ':' " prop="code">
                    <el-input size="small" v-model="businessDataDictFormDrawer.data.code" :placeholder="businessDataDictFrom.code.placeholder" />
                </el-form-item>
                <el-form-item :label="businessDataDictFields.value + ':' " prop="value">
                    <el-input size="small" v-model="businessDataDictFormDrawer.data.value" :placeholder="businessDataDictFrom.value.placeholder" />
                </el-form-item>
                <el-form-item :label="businessDataDictFields.parentId + ':' " prop="parentId">
                    <el-select 
                    v-model="businessDataDictFormDrawer.data.parentId" 
                    :placeholder="businessDataDictFrom.parentId.placeholder"
                    filterable
                    clearable
                    size="small"
                    >
                            <el-option :disabled="businessDataDictFormDrawer.data.id === item.id" :label="item.name" :value="item.id" v-for="(item,index) in allBusinessDataDictList" :key="index" />
                    </el-select>
                </el-form-item>
                
                <el-form-item :label="businessDataDictFields.remarks + ':' " prop="remarks">
                    <el-input type="textarea" v-model="businessDataDictFormDrawer.data.remarks" :placeholder="businessDataDictFrom.remarks.placeholder" size="small" />
                </el-form-item>

                <el-form-item :label="businessDataDictFields.sort + ':' " prop="sort">
                    <el-input size="small" type="number" v-model="businessDataDictFormDrawer.data.sort" :placeholder="businessDataDictFrom.sort.placeholder"></el-input>
                </el-form-item>

                <el-form-item :label="businessDataDictFields.status + ':' " prop="status">
                    <el-switch
                    v-model="businessDataDictFormDrawer.data.status"
                    :active-value="1"
                    :inactive-value="0"
                    >
                    </el-switch>
                </el-form-item>
                
                
            </el-form>

            <template slot="footer">
                <el-button type="primary" size="small" @click="saveOrUpdateBusinessDataDictInfo">{{ buttonText.submit }}</el-button>
                <el-button  size="small" @click="businessDataDictFormDrawer.hasOpen = false"> {{ buttonText.cancel }}</el-button>
            </template>

        </i-drawer>

        

    </div>
</template>

<script>
import {defaultPageSizes,defaultPage} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable'
import {obtainPositionButtons,showData} from '@/utils/power'
import IDrawer from '@/components/i-drawer/IDrawer'
import IDialog from '@/components/i-dialog/IDialog'
import {positions} from '@/config/button-position-settings'
import {businessDataDictTableSettings} from '@/config/table-settings'
import {loadBusinessDataDictPaging,lazyLoadBusinessDataDictChildNode,loadAllBusinessDataDictList,existsDataDiceByCode,saveOrUpdateBusinessDataDict,deleteBusinessDataDictById} from '@/api/system/data-dict'
import {loadCurrentPageTheButtons} from '@/api/system/menu'
import { rules } from '@/utils/rules'

export default{
    data(){

        

        return{
            defaultPageSizes,
            obtainPositionButtons,
            positions,
            buttons:[],
            config:{
                border:true,
                treeProps:{children: 'childNode', hasChildren: 'haveChildNode'},
                rowKey:'id',
                lazy:true,
                columns:[
                    ...businessDataDictTableSettings
                ]
            },
            page:{
                ...defaultPage
            },
            search:{
                values:{}
            },
            businessDataDictFormDrawer:{
                hasOpen:false,
                title:'',
                data:{status:1},
                currentParentId:null
            },
            showPage:false,
            allBusinessDataDictList:[]
        }
    },
    methods:{
        searchHandler(){//处理搜索
            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.resultMessages.unauthorizedView);

                return;
            }
            this.$refs['searchRef'].validate( async (valid,obj)=>{
                if(!valid){
                    this.$message.error(obj['id'][0].message);
                    return;
                }
                const {data} = await loadBusinessDataDictPaging({
                    current:1,
                    size:this.page.size,
                    id:this.search.id,
                    name:this.search.name
                })
                if(data){
                    this.page = data;
                }
            })

           

        },
        async lazyLoadHandler(row, treeNode, resolve){//懒加载子节点
            const {data} = await lazyLoadBusinessDataDictChildNode({
                id:row.id
            });

            if(data){
                resolve(data);
            }
        },
        async changePagingHandler(){ //变更分页
            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.resultMessages.unauthorizedView);

                return;
            }
            const {data} = await loadBusinessDataDictPaging({
                current:this.page.current,
                size:this.page.size,
                id:this.search.id,
                name:this.search.name
            });

            if(data){
                this.page = data;
            }
            
        },
        async getCurrentPageButtons(){ //获取当前页面的按钮
            const {data} = await  loadCurrentPageTheButtons({route:this.$route.path});
            // console.log(buttons);
            if(data){
                this.buttons = data;
            }
           
            //获取到了按钮数据，才开始渲染
            this.showPage = true;

           await this.changePagingHandler();
        
        },
        operateHandler(code,row){ //抽屉弹窗操作处理
 
            switch(code){
                case 'add':
                    this.businessDataDictFormDrawer.title = this.businessDataDictFormDrawerText.addTitle;
                    this.businessDataDictFormDrawer.currentParentId = null;
                    this.businessDataDictFormDrawer.hasOpen = true;
                    
                    break;
                case 'update':
                    this.businessDataDictFormDrawer.title = this.businessDataDictFormDrawerText.updateTitle;
                    this.businessDataDictFormDrawer.data = JSON.parse(JSON.stringify(row));
                    this.businessDataDictFormDrawer.currentParentId = row.parentId;
                    this.businessDataDictFormDrawer.hasOpen = true;
                    break;
                case 'delete':
                    this.deleteBusinessDataDictById(row);
                    
            }

        },
        saveOrUpdateBusinessDataDictInfo(){ //保存或修改业务字典
            this.$refs['businessDataDictFromRef'].validate( async(valid)=>{

                if(valid){
                   const data =  await saveOrUpdateBusinessDataDict(this.businessDataDictFormDrawer.data);

                   if(data&&data.code === 200){
                        let item = JSON.parse(JSON.stringify( this.businessDataDictFormDrawer.data));
                        await this.updateLazyTreeNodeItemHandler(item);
                        this.changePagingHandler();
                        this.businessDataDictFormDrawer.hasOpen = false;
                   }
                }

            })

        },
        async getAllBusinessDataDictList(){
            const {data} = await loadAllBusinessDataDictList();

            this.allBusinessDataDictList = data;
        },
        async deleteBusinessDataDictById(row){

            this.$confirm(this.confirmPopupText.message,this.confirmPopupText.title,{
                confirmButtonText: this.buttonText.confirm,
                cancelButtonText: this.buttonText.cancel,
                type: 'warning',
                center: true
            }).then(async ()=>{
                
                const data = await deleteBusinessDataDictById({
                    id:row.id
                });
                if(data&&data.code === 200){
                    this.deleteLazyLodingNodeHandler(row);
                    this.changePagingHandler();
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.resultMessages.cancelDelete
                });
            });
        },
        async updateLazyTreeNodeItemHandler(item){ //table 懒加载时 更新子节点信息
            //获取在el-table组件中加的ref绑定的对象
            let elTableRef = this.$refs['iTableRef'].getTableRef();
            const store = elTableRef.table.store; //等同于el-table.table.store

            //1.节点移动了
            if(this.businessDataDictFormDrawer.currentParentId!=null&&this.businessDataDictFormDrawer.currentParentId != item.parentId ){
                // console.log('w3')
                //更新原本的节点
                const {data} = await lazyLoadBusinessDataDictChildNode({
                    id:this.businessDataDictFormDrawer.currentParentId
                });
                store.states.lazyTreeNodeMap[this.businessDataDictFormDrawer.currentParentId]  = data;
                
                //更新新的节点
                if(item.parentId!=null&&item.parentId!=''){ //移动的节点非顶级节点


                    
                    const {data} = await lazyLoadBusinessDataDictChildNode({
                        id:item.parentId
                    });
                    store.states.lazyTreeNodeMap[item.parentId]  = data;
                }
                

               
               return;
            }

            //2.修改当前节点的内容
            if(this.businessDataDictFormDrawer.currentParentId == item.parentId && item.parentId!=null){
                const {data} = await lazyLoadBusinessDataDictChildNode({
                        id:item.parentId
                });
                
                store.states.lazyTreeNodeMap[item.parentId]  = data;

                return;
            }
            //3.将当前节点添加到新的父节点上
            if(item.id == null && item.parentId!=null){

                // console.log('w2',store.states.lazyTreeNodeMap)

                const treeNodeKeys = Object.keys(store.states.lazyTreeNodeMap);

                // console.log('treeNodeKeys',treeNodeKeys)

                let currentNodeParentParentNode;
                //查询当前节点父节点的父节点信息
                for(let i = 0; i < treeNodeKeys.length ; i++){
                    currentNodeParentParentNode = store.states.lazyTreeNodeMap[treeNodeKeys[i]].find(child=>child.id == item.parentId);
                    
                    if(currentNodeParentParentNode){
                        break;
                    }
                    // console.log('currentNodeParentParentNode',currentNodeParentParentNode)
                }

                //3.1 如果当前节点所在父节点原本没有子节点，那么更新当前节点所在父节点的父节点下所有子节点
                if(currentNodeParentParentNode){
                    const {data} = await lazyLoadBusinessDataDictChildNode({
                        id:currentNodeParentParentNode.parentId
                    });
                
                    store.states.lazyTreeNodeMap[currentNodeParentParentNode.parentId]  = data;

                    
                }
                //3.2 当前节的父节点已经加载过子节点
                if(store.states.treeData[item.parentId]&&store.states.treeData[item.parentId].loaded){ 

                    const {data} = await lazyLoadBusinessDataDictChildNode({
                        id:item.parentId
                    });
                    
                    store.states.lazyTreeNodeMap[item.parentId]  = data;

                }else{ //3.3 当前节的父节点没有加载过子节点

                    //初始化当前节点的父节点树
                    store.states.treeData[item.parentId] = {
                        display: true,
                        loading: false,
                        loaded: false,
                        expanded: false,
                        children: [],
                        lazy: true,
                        level: 0
                    }
                }

                
            }
           
        },
        deleteLazyLodingNodeHandler(item){ //懒加载时，删除懒加载的节点信息
            //获取在el-table组件中加的ref绑定的对象
            let elTableRef = this.$refs['iTableRef'].getTableRef();
            const store = elTableRef.table.store; //等同于el-table.table.store
            if(item.parentId){//当，当前节点不是顶级节点的时候
                const currentNode =  store.states.lazyTreeNodeMap[item.parentId];//获取当前元素所在的node节点


                let index = currentNode.findIndex(child=>child.id == item.id);

                //删除指定节点
                currentNode.splice(index,1);
                return;
            }
            //当删除的是顶级节点的时候
            const currentNode = store.states.data;
            const index = currentNode.findIndex(child=>child.id == item.id);

            currentNode.splice(index,1);
        },
        

    },
    mounted(){
        // this.getAllBusinessDataDictList();
        this.getCurrentPageButtons();
        // this.changePagingHandler();
    },
    components:{
        ITable,
        IDrawer,
        IDialog
    },
    computed:{
        businessDataDictFields(){
            const prefix = 'businessDataDictPage.field'
            return{
                id:this.$t(`${prefix}.id`),
                name:this.$t(`${prefix}.name`),
                code:this.$t(`${prefix}.code`),
                value:this.$t(`${prefix}.value`),
                parentId:this.$t(`${prefix}.parentId`),
                statusName:this.$t(`${prefix}.statusName`),
                status:this.$t(`${prefix}.status`),
                remarks:this.$t(`${prefix}.remarks`),
                sort:this.$t(`${prefix}.sort`),
                creatorName:this.$t(`${prefix}.creatorName`),
                createTime:this.$t(`${prefix}.createTime`),
                modifierName:this.$t(`${prefix}.modifierName`),
                modifyTime:this.$t(`${prefix}.modifyTime`),
                operate:this.$t(`${prefix}.operate`)
            }
        },
        businessDataDictFrom(){
            const prefix = 'businessDataDictPage.form';
            return{
                id:{
                    placeholder:this.$t(`${prefix}.id.placeholder`)
                },
                name:{
                    placeholder:this.$t(`${prefix}.name.placeholder`)
                },
                code:{
                    placeholder:this.$t(`${prefix}.code.placeholder`)
                },
                value:{
                    placeholder:this.$t(`${prefix}.value.placeholder`)
                },
                parentId:{
                    placeholder:this.$t(`${prefix}.parentId.placeholder`)
                },
                remarks:{
                    placeholder:this.$t(`${prefix}.remarks.placeholder`)
                },
                sort:{
                    placeholder:this.$t(`${prefix}.sort.placeholder`)
                }
                
            }
        },
        businessDataDictFormRules(){

            const checkCode = async (rule,value,callback)=>{
                const {data} = await existsDataDiceByCode({
                    id:this.businessDataDictFormDrawer.data.id,
                    parentId:this.businessDataDictFormDrawer.parentId,
                    code:value
                });

                if(!data||data.exists){               
                    return callback(new Error(this.$t(`${prefix}.name.checkFail.exist`)));
                }
                
                return callback();
            }

            const prefix = 'businessDataDictPage.form';

            return{
                name:[ { required: true, message: this.$t(`${prefix}.name.checkFail.required`), trigger: 'blur' }],
                code:[ { required: true, message: this.$t(`${prefix}.code.checkFail.required`), trigger: 'blur' },{ validator: checkCode, trigger: 'blur' }],
                sort:[ { required: true, message: this.$t(`${prefix}.sort.checkFail.required`), trigger: 'blur' }],
                status:[  { required: true, message: this.$t(`${prefix}.status.checkFail.required`), trigger: 'blur' }]
            }
        },
        buttonText(){
            const prefix = 'public.button';
            return{
                submit:this.$t(`${prefix}.submit`),
                confirm: this.$t(`${prefix}.confirm`),
                cancel: this.$t(`${prefix}.cancel`),
                search: this.$t(`${prefix}.search`),
            }
        },
        confirmPopupText(){
            const prefix = 'businessDataDictPage.confirmPopup';
            return{
                title:this.$t(`${prefix}.title`),
                message:this.$t(`${prefix}.message`)
            }
        },
        businessDataDictFormDrawerText(){
            const prefix = 'businessDataDictPage.businessDataDictFormDrawer';
            return{
                addTitle:this.$t(`${prefix}.addTitle`),
                updateTitle:this.$t(`${prefix}.updateTitle`)
            }
        },
        resultMessages(){
            const prefix = 'public.resultMessages';

            return{
                unauthorizedView: this.$t(`${prefix}.unauthorizedView`),
                cancelDelete: this.$t(`${prefix}.cancelDelete`)
            }
        },
        searchFormRules(){
            const prefix = 'businessDataDictPage.form';
            const checkNumber = (rule, value, callback)=>{
                if(!value){
                    return callback();
                }
                if(!rules.integer.test(value)){
                    return callback(new Error(this.$t(`${prefix}.id.checkFail.checkNumber`)));
                }
                return callback(); 
            }

            return{
                id:[{ validator: checkNumber.bind(this), trigger: 'blur' }]
            }
        }
    }
}


</script>


<style>
.system-dict-business_wrapper{
    height: 100%;
    
}

</style>