<template>
  <div class="data-main-wrapper">
    <i-table v-if="table.showTable" :config="table.config" :page="table.page" :page-sizes="defaultPageSizes" @change-page="handleChangePage">
      <template slot="search-condition">
        <el-form :inline="true" size="small">
          <el-form-item :label="field.brandName.name + ':'">
            <el-select v-model="searchCondition.brandId" clearable :placeholder="field.brandName.placeholder">
              <el-option
                  v-for="(item, index) in chargePileBrandPullDownData"
                  :key="index"
                  :label="item.brandName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="field.brandModelName.name + ':'">
            <el-input clearable v-model="searchCondition.brandModelName"
                      :placeholder="field.brandModelName.placeholder"></el-input>
          </el-form-item>
        </el-form>
      </template>

      <!--搜索按钮-->
      <template slot="search-button">
        <el-button type="primary" size="small" @click="handleChangePage(true)">{{ btn.search }}</el-button>
      </template>

      <!--表头-->
      <template slot="header" slot-scope="scope">
        {{ field[scope.column.property].name }}
      </template>

      <!--全局左-->
      <template slot="overall-left" slot-scope="scope">
        <el-button
            v-for="(overallLeftButton,index) in btnPosition.obtainPositionButtons(buttons,btnPosition.positions.left)"
            :key="index" type="primary" size="small" :icon="overallLeftButton.icon"
            @click="operateHandler(overallLeftButton.code)">{{ overallLeftButton.name }}
        </el-button>
      </template>

      <!--数据行内-->
      <template slot="row_operate" slot-scope="scope">
        <el-button
            type="text"
            :icon="rowButton.icon"
            size="mini"
            v-for="(rowButton,index) in btnPosition.obtainPositionButtons(buttons,btnPosition.positions.row) "
            :key="index"
            @click="operateHandler(rowButton.code,scope.row)">
          {{ rowButton.name }}
        </el-button>
      </template>
    </i-table>

    <i-drawer :visible.sync="brandModelDrawer.visible" :title="brandModelDrawer.title"
              size="30%" @close="handleCloseBrandModelDrawer">
      <el-form :model="brandModelDrawer.chargePileBrandModelForm" ref="chargePileBrandModelForm" label-width="120px"
               size="small"
               label-position="left" :rules="brandModelFormRules">
        <el-form-item :label="field.brandName.name + ':'" prop="brandId">
          <el-select v-model="brandModelDrawer.chargePileBrandModelForm.brandId" clearable
                     :placeholder="field.brandName.placeholder" :disabled="brandModelDrawer.updateLock">
            <el-option
                v-for="(item, index) in chargePileBrandPullDownData"
                :key="index"
                :label="item.brandName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="field.brandModelName.name + ':'" prop="brandModelName">
          <el-input v-model="brandModelDrawer.chargePileBrandModelForm.brandModelName"
                    :placeholder="field.brandModelName.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="field.brandModelTypeName.name + ':'" prop="brandModelType">
          <el-radio-group v-model="brandModelDrawer.chargePileBrandModelForm.brandModelType">
            <el-radio v-for="(item, index) in brandModelDrawer.brandModelTypeData" :key="index" :label="item.value">
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="field.gunNumber.name + ':'" prop="gunNumber">
          <el-input v-model="brandModelDrawer.chargePileBrandModelForm.gunNumber"
                    :placeholder="field.gunNumber.placeholder" :disabled="brandModelDrawer.updateLock"></el-input>
        </el-form-item>
        <el-form-item :label="field.maxPower.name + '(kw):'" prop="maxPower">
          <el-input v-model="brandModelDrawer.chargePileBrandModelForm.maxPower"
                    :placeholder="field.maxPower.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="field.remark.name + ':'" prop="remark">
          <el-input class="item-width" v-model="brandModelDrawer.chargePileBrandModelForm.remark"
                    type="textarea"
                    :rows="2"
                    :placeholder="field.remark.placeholder"></el-input>
        </el-form-item>
      </el-form>

      <template slot="footer">
        <el-button type="primary" @click="handleBrandModelFormSubmit">{{ btn.submit }}</el-button>
        <el-button @click="brandModelDrawer.visible = false">{{ btn.cancel }}</el-button>
      </template>
    </i-drawer>
  </div>
</template>

<script>
import {defaultPage,defaultPageSizes} from "@/config/page-settings";
import {chargePileBrandModelTableSettings} from '@/config/table-settings';
import {obtainPositionButtons, showData} from '@/utils/power';
import {positions} from '@/config/button-position-settings';
import ITable from '@/components/i-table/ITable.vue';
import IDrawer from '@/components/i-drawer/IDrawer.vue';
import {loadCurrentPageTheButtons} from '@/api/system/menu';
import {hasSuccess} from '@/utils/tool';
import {chargePileBrandPullDownList} from '@/api/business/charge-pile-brand';
import {
  chargePileBrandModelPaging,
  saveOrUpdateChargePileBrandModel,
  deleteChargePileBrandModel
} from '@/api/business/charge-pile-brand-model';
import {rules} from '@/utils/rules';
import {message} from '@/utils/message';
import {loadDataDictByCode} from '@/api/system/data-dict';

export default {

  components: {
    'i-table': ITable,
    'i-drawer': IDrawer,
  },
  data() {
    return {
      defaultPageSizes,
      table: {
        showTable: false,
        page: {
          ...defaultPage
        },
        config: {
          columns: [...chargePileBrandModelTableSettings],
          rowKey: 'id'
        },
      },
      buttons: [],
      searchCondition: {},
      chargePileBrandPullDownData: [],
      brandModelDrawer: {
        visible: false,
        title: '',
        chargePileBrandModelForm: {},
        brandModelTypeData: [],
        updateLock: false,
      },
    }
  },
  computed: {
    btnPosition: {
      get: function () {
        return {obtainPositionButtons, positions}
      }
    },
    public() {
      return {
        unauthorizedView: this.$t('public.resultMessages.unauthorizedView'),
      }
    },
    btn() {
      return {
        search: this.$t('public.button.search'),
        submit: this.$t('public.button.submit'),
        cancel: this.$t('public.button.cancel'),
      }
    },
    field() {
      const fieldPrefix = 'chargeBrandModelManagePage.field';
      const formPrefix = 'chargeBrandModelManagePage.form';
      return {
        id: {name: this.$t(`${fieldPrefix}.id`)},
        brandName: {
          name: this.$t(`${fieldPrefix}.brandName`),
          placeholder: this.$t(`${formPrefix}.brandName.placeholder`),
          required: this.$t(`${formPrefix}.brandName.checkFail.required`),
        },
        brandModelName: {
          name: this.$t(`${fieldPrefix}.brandModelName`),
          placeholder: this.$t(`${formPrefix}.brandModelName.placeholder`),
          required: this.$t(`${formPrefix}.brandModelName.checkFail.required`),
          maxSize: this.$t(`${formPrefix}.brandModelName.checkFail.maxSize`),
        },
        brandModelTypeName: {
          name: this.$t(`${fieldPrefix}.brandModelTypeName`),
          required: this.$t(`${formPrefix}.brandModelTypeName.checkFail.required`),
        },
        gunNumber: {
          name: this.$t(`${fieldPrefix}.gunNumber`),
          placeholder: this.$t(`${formPrefix}.gunNumber.placeholder`),
          required: this.$t(`${formPrefix}.gunNumber.checkFail.required`),
          number: this.$t(`${formPrefix}.gunNumber.checkFail.number`),
        },
        maxPower: {
          name: this.$t(`${fieldPrefix}.maxPower`),
          placeholder: this.$t(`${formPrefix}.maxPower.placeholder`),
          required: this.$t(`${formPrefix}.maxPower.checkFail.required`),
          number: this.$t(`${formPrefix}.maxPower.checkFail.number`),
        },
        remark: {name: this.$t(`${fieldPrefix}.remark`)},
        modifierName: {name: this.$t(`${fieldPrefix}.modifierName`)},
        modifyTime: {name: this.$t(`${fieldPrefix}.modifyTime`)},
        creatorName: {name: this.$t(`${fieldPrefix}.creatorName`)},
        createTime: {name: this.$t(`${fieldPrefix}.createTime`)},
        operate: {name: this.$t(`${fieldPrefix}.operate`)},
      }
    },
    operateInfo() {
      const prefix = 'chargeBrandModelManagePage.operateInfo';
      return {
        addTitle: this.$t(`${prefix}.addTitle`),
        updateTitle: this.$t(`${prefix}.updateTitle`),
        deleteTitle: this.$t(`${prefix}.deleteTitle`),
        confirmMessage: this.$t(`${prefix}.deleteConfirm.confirmMessage`),
        cancelMessage: this.$t(`${prefix}.deleteConfirm.cancelMessage`),
      }
    },
    brandModelFormRules() {
      let validGunNumber = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.field.gunNumber.required));
        }
        if (!rules.zInteger.test(value)) {
          return callback(new Error(this.field.gunNumber.number));
        }
        return callback();
      }
      let validMaxPower = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.field.maxPower.required));
        }
        if (!rules.zInteger.test(value)) {
          return callback(new Error(this.field.maxPower.number));
        }
        return callback();
      }
      return {
        brandId: [{required: true, message: this.field.brandName.required, trigger: 'change'}],
        brandModelName: [
          {required: true, message: this.field.brandModelName.required, trigger: 'blur'},
          {min: 1, max: 30, message: this.field.brandModelName.maxSize, trigger: 'blur'}
        ],
        brandModelType: {required: true, message: this.field.brandModelTypeName.required, trigger: 'blur'},
        gunNumber: [{required: true, validator: validGunNumber, trigger: 'blur'}],
        maxPower: [{required: true, validator: validMaxPower, trigger: 'blur'}],
        remark: [{min: 0, max: 300, message: this.field.remark.maxSize, trigger: 'blur'}],
      }
    }
  },
  mounted() {
    this.initCurrentPageButton();
  },
  methods: {
    /**
     * 处理删除品牌型号
     */
    handleDeleteBrandModel(row) {
      this.$confirm(this.$t('chargeBrandModelManagePage.operateInfo.deleteConfirm.confirmMessage', {brandModelName: row.brandModelName}), this.operateInfo.deleteTitle, {
        confirmButtonText: this.btn.confirm,
        cancelButtonText: this.btn.cancel,
        type: 'warning'
      }).then(async () => {
        await deleteChargePileBrandModel({id: row.id});
        await this.initChargePileBrandModelData();
      }).catch(() => {
        message.info({message: this.operateInfo.cancelMessage});
      });
    },
    /**
     * 处理品牌型号表单提交
     */
    handleBrandModelFormSubmit() {
      this.$refs.chargePileBrandModelForm.validate(async (valid) => {
        if (valid) {
          let {code} = await saveOrUpdateChargePileBrandModel(this.brandModelDrawer.chargePileBrandModelForm);
          if (hasSuccess(code)) {
            this.brandModelDrawer.visible = false;
            this.initChargePileBrandModelData();
          }
        }
      });
    },
    /**
     * 处理关闭品牌型号抽屉
     */
    handleCloseBrandModelDrawer() {
      this.brandModelDrawer.visible = false;
      this.$refs.chargePileBrandModelForm.resetFields();
      this.brandModelDrawer.chargePileBrandModelForm = {};
      this.brandModelDrawer.updateLock = false;
    },
    /**
     * 行内操作处理
     * @param code
     * @param row
     */
    operateHandler(code, row) {
      switch (code) {
        case 'add':
          this.brandModelDrawer.title = this.operateInfo.addTitle;
          this.brandModelDrawer.visible = true;
          break;
        case 'update':
          this.brandModelDrawer.chargePileBrandModelForm = Object.assign({}, row);
          this.brandModelDrawer.title = this.operateInfo.updateTitle;
          this.brandModelDrawer.updateLock = true;
          this.brandModelDrawer.visible = true;
          break;
        case 'delete':
          this.handleDeleteBrandModel(row);
          break;
        default:
          break;
      }
    },
    /**
     * 初始化充电桩品牌下拉列表数据
     */
    async initChargePileBrandPullDownData() {
      let {code, data} = await chargePileBrandPullDownList();
      if (hasSuccess(code)) {
        this.chargePileBrandPullDownData = data;
      }
    },
    /**
     * 处理改变页面
     */
    handleChangePage(hasSearch) {
      if (hasSearch) {
        this.table.page.current = 1;
      }
      this.initChargePileBrandModelData();
    },
    /**
     * 初始化充电桩品牌型号数据
     */
    async initChargePileBrandModelData() {
      const show = showData(this.buttons, positions.view);
      if (!show) {
        this.$message.error(this.public.unauthorizedView);
        return;
      }
      let {code, data} = await chargePileBrandModelPaging({
        current: this.table.page.current,
        size: this.table.page.size,
        ...this.searchCondition
      });
      if (hasSuccess(code)) {
        this.table.page = {
          current: data.current,
          size: data.size,
          total: data.total,
          records: data.records,
        };
      }
    },
    /**
     * 初始化页面按钮数据
     */
    async initCurrentPageButton() {
      let {code, data} = await loadCurrentPageTheButtons({route: this.$route.path});
      if (hasSuccess(code)) {
        this.buttons = data;
        this.table.showTable = true;
        await this.initChargePileBrandModelData();
        await this.initChargePileBrandPullDownData();
        let dictData = await loadDataDictByCode({code: 'brand_model_type'});
        this.brandModelDrawer.brandModelTypeData = dictData.data;
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
