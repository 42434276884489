<template>
    <div class="tabs-wrapper">
        <div class="tabs-main">
            <div class="left">
                <i class="el-icon-arrow-left" @click="scrollLeftHandler"></i>

            </div>
            <div class="content" id="tabsItemMain">
                <div :class="$route.path === menuList[0][menuRouteKey]?'tabs-item tabs-item-current':'tabs-item'" @click="$router.push(menuList[0][menuRouteKey]);" v-if="menuList.length> 0">
                   <span class="tabs-item-label">{{  menuList[0][menuNameKey] }}</span>
                </div>
                <div :class="$route.path ===tab[menuRouteKey]?'tabs-item tabs-item-current':'tabs-item'" v-for="(tab,index) in tabs" :key="index" >
                    <div class="tabs-item-label">
                        <span @click="$router.push(tab[menuRouteKey])">{{ tab.name }}</span>
                         <i class="el-icon-close" @click="deleteTab(index)"></i>
                    </div>
                </div>

            </div>
            <div class="reight">
                <i class="el-icon-arrow-right" @click="scrollReightHandler"></i>
            </div>

        </div>

    </div>
</template>

<script>

import {getMenu2MenuListItemAttribute,excludeDuplicates2MeunList,getTabList2SessionStorage,saveTabList2SessionStorage,getTabIndex2TabLIst} from './tabs-hook'


export default{
    data(){

        return{
            tabs:[],
            currentTab:''

        }
    },
    props:{
        menuList:{
            type:Array,
            default:[]
        },
        menuRouteKey:{
            type:String,
            default:'route'
        },
        menuNameKey:{
            type:String,
            default:''
        }

    },
    methods:{
        scrollXHandler(){

             // 获取要绑定事件的元素
            const tabsItemMainElement = document.getElementById("tabsItemMain");
            // document.addEventListener('DOMMouseScroll', handler, false)
            // 添加滚轮滚动监听事件，一般是用下面的方法，上面的是火狐的写法
            tabsItemMainElement.addEventListener('mousewheel', handler, false)
            // 滚动事件的出来函数
            function handler(event) {
                // 获取滚动方向
                const detail = event.wheelDelta || event.detail;
                // 定义滚动方向，其实也可以在赋值的时候写
                const moveForwardStep = 1;
                const moveBackStep = -1;
                // 定义滚动距离
                let step = 0;
                // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
                if (detail < 0) {
                step = moveForwardStep * 100;
                } else {
                step = moveBackStep * 100;
                }
                // 对需要滚动的元素进行滚动操作
                tabsItemMainElement.scrollLeft += step;

            }

        },
        scrollLeftHandler(){ //处理标签移动的函数

            const tabsItemMainElement = document.getElementById("tabsItemMain");

            if(tabsItemMainElement.scrollLeft<=0){
                return;
            }

            let size = tabsItemMainElement.scrollWidth/10;
            // console.log(tabsItemMainElement.scrollLeft);
            if(tabsItemMainElement.scrollLeft<size){
                tabsItemMainElement.scrollLeft = 0
            }else if(tabsItemMainElement.scrollLeft>size){
                tabsItemMainElement.scrollLeft = tabsItemMainElement.scrollLeft - size;
            }
        },
        scrollReightHandler(){ //处理标签移动的函数
            const tabsItemMainElement = document.getElementById("tabsItemMain");
            if(tabsItemMainElement.scrollLeft>=tabsItemMainElement.scrollWidth){
                return;
            }

            let size = tabsItemMainElement.scrollWidth/10;
            tabsItemMainElement.scrollLeft = tabsItemMainElement.scrollLeft + size;

        },
        addTab(){ //往数组中添加一个标签

            if(this.menuList.length === 0){
                return ;
            }
           
            //首页不添加到数组中
            if(this.$route.path == this.menuList[0][this.menuRouteKey]){
                return;
            }
            //获取路由对应的菜单信息
            let meun = getMenu2MenuListItemAttribute(this.menuList,this.menuRouteKey,this.$route.path,'childNode');
            // console.log('item',meun)
            if(meun === null){
                this.$message.error('访问的页面不存在~');
                return;
            }

            //封装需要的信息到标签页
            meun = JSON.parse(JSON.stringify(meun));

            let tab = {};
            tab[this.menuNameKey] = meun[this.menuNameKey];
            tab[this.menuRouteKey] = meun[this.menuRouteKey];

            // console.log('标签',tab);

            //将tabList重新赋值
            let tabList = JSON.parse(JSON.stringify(this.tabs));
            if(tab!=null){
                tabList.push(tab);
            }
           
            //去重
            this.tabs = excludeDuplicates2MeunList(tabList);
            
            saveTabList2SessionStorage('tabs',this.tabs);

        },
        deleteTab(index){ //删除数组中的一个标签
            const currentIndex = getTabIndex2TabLIst(this.tabs,this.menuRouteKey,this.currentTab); 
            const currentMenu = this.tabs[currentIndex];
            // console.log('当前页',currentMenu);
            //当前索引
            // console.log('删除了',this.tabs[index]);
            this.tabs.splice(index,1);
            
            saveTabList2SessionStorage('tabs',this.tabs);
           
            //当被关闭的不是
            //当当前索引大于数组的一半的时候,
            //当当前索引小于数组的一半的时候,
            if(this.tabs.length === 0){ //关闭了所有页面
                this.$router.push(this.menuList[0][this.menuRouteKey]);
            }else if(index<currentIndex){ //关闭的页面的索引小于当前页面时
                this.$router.push(this.tabs[currentIndex-1][this.menuRouteKey]);
            }else if(index == currentIndex){ //关闭的是当前页
                if(this.tabs.length<=index){ //关闭的页面的索引大于数组长度时 索引-1
                    this.$router.push(this.tabs[index-1][this.menuRouteKey]);
                }else{ //索引长度小于数组长度，索引不变
                    this.$router.push(this.tabs[index][this.menuRouteKey])
                }

            }
            
            this.getTabs();

        },
        getTabs(){ //获取路由标签
            let tabList = getTabList2SessionStorage('tabs');

            if(tabList===null||tabList === undefined){
                this.tabs = [];
                return;
            }

            this.tabs = tabList;

            this.addTab();
        }


    },
    mounted(){
        this.scrollXHandler();
        this.currentTab = this.$route.path;

        this.getTabs();
        

       

    },
    created(){
        // console.log(this.$router.options.routes)
        

    },
    watch:{
        $route(newVal,oldVal){
            // console.log('路由发生了变化')
            this.addTab();
            this.currentTab = newVal.path;
        }
    }
}

</script>

<style lang="scss" scope>
.tabs-wrapper{
 
    .tabs-main{
        display: flex;
        width: 100%;
        font-size: 14px;
        line-height: 28px;
        height: 28px;
        -moz-user-select:none; /* Firefox私有属性 */
        -webkit-user-select:none; /* WebKit内核私有属性 */
        -ms-user-select:none; /* IE私有属性(IE10及以后) */
        -khtml-user-select:none; /* KHTML内核私有属性 */
        -o-user-select:none; /* Opera私有属性 */
        user-select:none; /* CSS3属性 */
        .left{
            width: 2%;
            
            .el-icon-arrow-left:hover{
                cursor: pointer;
            }
        }
        .content{
            display: flex;
            // overflow-x: hidden;
            overflow-y: hidden;
            white-space: nowrap;//防止内容坍缩
            grid-gap: 5px;
            width: 100%;
            .tabs-item{
                text-align: center;
                width: fit-content;
                padding-left: 5px;
                padding-right: 5px;
                background-color: #fff;
                border: 1px solid #dcdfe6;
                cursor: pointer;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
                display: flex;
                justify-content: center;
                
                
            }
            .tabs-item-label{
               
                padding-left: 5px;
                padding-right: 5px;
                color: #999;
            }
            .tabs-item-current span{
                
                font-weight: 600;
                color: #409EFF;
            }
            .tabs-item-label span:hover{
                color: #409EFF;
            }
            .el-icon-close{
                margin-left: 10px;
                margin-right: 5px;
            }
            .el-icon-close:hover{
                color: #fff;
                background-color: #409EFF;
                border-radius: 15px;
                
            }
        }
        .content::-webkit-scrollbar{
            display: none; 
        }
        .reight{
            width: 2%;
            text-align: right;
            
            .el-icon-arrow-right:hover{
                cursor: pointer;
            }
        }
        
    }

}

</style>