<template>
    <div class="system-dict-system_wrapper data-main-wrapper">

        <i-table
        :config="config"
        :page="page"
        @change-page="changePagingHandler"
        :load="lazyLoadHandler"
        >

           <!-- 表头 -->
           <template slot="header" slot-scope="scope">
                {{ systemDataDictFields[scope.column.property] }}
            </template>
            <!-- 搜索栏 -->
            <template slot="search-condition">
                <el-form :model="search" :inline="true" :rules="formRules" ref="searchRef">
                    <el-form-item :label="systemDataDictFields.id + ':'" prop="id" ref="dataDictIdRef">
                        <el-input @clear="$refs['dataDictIdRef'].clearValidate()" size="small" v-model="search.id" :placeholder="form.id.placeholder" clearable />
                    </el-form-item>
                    <el-form-item :label="systemDataDictFields.name + ':'">
                        <el-input size="small" v-model="search.name" :placeholder="form.name.placeholder" clearable />
                    </el-form-item>
                </el-form>
                
            </template>
            <!-- 搜索按钮 -->
            <template slot="search-button">
                <el-button type="primary"  size="small" @click="searchHandler" icon="el-icon-search">{{ buttonText.search }}</el-button>
            </template>
    
        </i-table>

        

    </div>
</template>

<script>

import {defaultPageSizes,defaultPage} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable'
import {obtainPositionButtons,showData} from '@/utils/power'
import {positions} from '@/config/button-position-settings'
import {systemDataDictTableSettings} from '@/config/table-settings'
import {loadSystemDataDictPaging,lazyLoadSystemDataDictChildNode} from '@/api/system/data-dict'
import {loadCurrentPageTheButtons} from '@/api/system/menu'
import {rules} from '@/utils/rules'
export default{
    data(){

        return{
            defaultPageSizes,
            obtainPositionButtons,
            positions,
            buttonsL:[],
            config:{
                border:true,
                treeProps:{children: 'childNode', hasChildren: 'haveChildNode'},
                rowKey:'id',
                lazy:true,
                columns:[
                    ...systemDataDictTableSettings
                ]
            },
            page:{
                ...defaultPageSizes
            },
            search:{}
        }
    },
    methods:{
         searchHandler(){//处理搜索

            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.resultMessage.unauthorizedView);

                return;
            }

            this.$refs['searchRef'].validate( async (valid,obj)=>{
                if(!valid){
                    this.$message.error(obj['id'][0].message);
                    return;
                }

                const {data} = await loadSystemDataDictPaging({
                    current:1,
                    size:this.page.size,
                    id:this.search.id,
                    name:this.search.name
                })

                if(data){
                    this.page = data;
                }

            })
           

        },
        async lazyLoadHandler(row, treeNode, resolve){//懒加载子节点
            const {data} = await lazyLoadSystemDataDictChildNode({
                id:row.id
            });

            if(data){
                resolve(data);
            }
             
            
        },
        async changePagingHandler(){
            
            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.resultMessage.unauthorizedView);

                return;
            }

            const {data} = await loadSystemDataDictPaging({
                current:this.page.current,
                size:this.page.size,
                id:this.search.id,
                name:this.search.name
            });

            if(data){
                this.page = data;
            }
            
        },
        async getCurrentPageButtons(){ //获取当前页面的按钮
            const {data} = await  loadCurrentPageTheButtons({route:this.$route.path});
            // console.log(buttons);
            if(data){
                this.buttons = data;
            }
           
            //获取到了按钮数据，才开始渲染
            this.showPage = true;

             this.changePagingHandler();
        
        },

    },
    mounted(){
        this.getCurrentPageButtons();
    },
    components:{
        ITable,
    },
    computed:{
        systemDataDictFields(){
            const prefix = 'systemDataDictPage.field';
            return{
                id:this.$t(`${prefix}.id`),
                name:this.$t(`${prefix}.name`),
                code:this.$t(`${prefix}.code`),
                value:this.$t(`${prefix}.value`),
                remarks:this.$t(`${prefix}.remarks`),
                creatorName:this.$t(`${prefix}.creatorName`),
                createTime:this.$t(`${prefix}.createTime`),
                modifierName:this.$t(`${prefix}.modifierName`),
                modifyTime:this.$t(`${prefix}.modifyTime`)
            }
        },
        form(){

            const prefix = 'systemDataDictPage.form';

            return{
                id:{
                    placeholder:this.$t(`${prefix}.id.placeholder`),
                },
                name:{
                    placeholder:this.$t(`${prefix}.name.placeholder`),
                }
            }
        },
        formRules(){
            const prefix = 'systemDataDictPage.form';
            const checkNumber = (rule,value,callback)=>{
                if(!value){
                    return callback();
                }

                if(!rules.integer.test(value)){
                    return callback(new Error(this.$t(`${prefix}.id.checkFail.checkNumber`)));
                }

                return callback();
            }

            return{
                id:[{ validator: checkNumber.bind(this), trigger: 'blur' }]
            }

        },
        buttonText(){
            const prefix = 'public.button';
            return{
                search:this.$t(`${prefix}.search`)
            }
        },
        resultMessage(){
            const prefix = 'public.resultMessage';

            return{
                unauthorizedView: this.$t(`${prefix}.unauthorizedView`),
            }
        }
    }
}


</script>


<style>
.system-dict-system_wrapper{
    height: 100%;
    
}

</style>