import {validateNull} from '@/utils/tool';

const keyName = 'energy-';

/**
 * 存储webStorage数据
 * @param params 存储参数
 */
export const setStore = (params = {}) => {
    let {name, content, type} = params;
    name = keyName + name;
    let obj = {
        dataType: typeof (content),
        content: content,
        type: type,
        datetime: new Date().getTime()
    }
    if (type) {
        window.sessionStorage.setItem(name, JSON.stringify(obj));
    } else {
        window.localStorage.setItem(name, JSON.stringify(obj));
    }
}

/**
 * 获取webStorage数据
 * @param params 参数
 * @returns {number|*}
 */
export const getStore = (params = {}) => {
    let {name, debug} = params;
    name = keyName + name;
    let obj = {}, content;
    obj = window.sessionStorage.getItem(name);
    if (validateNull(obj)) {
        obj = window.localStorage.getItem(name);
    }
    if (validateNull(obj)) {
        return;
    }
    try {
        obj = JSON.parse(obj);
    } catch (e) {
        return obj;
    }
    if (debug) {
        return obj;
    }
    if (obj.dataType === 'string') {
        content = obj.content;
    } else if (obj.dataType === 'number') {
        content = Number(obj.content);
    } else if (obj.dataType === 'boolean') {
        content = eval(obj.content);
    } else if (obj.dataType === 'object') {
        content = obj.content;
    }
    return content;
};

/**
 * 删除webStorage
 * @param params 参数
 */
export const removeStore = (params = {}) => {
    let {name, type} = params;
    name = keyName + name;
    if (type) {
        window.sessionStorage.removeItem(name);
    } else {
        window.localStorage.removeItem(name);
    }
};
