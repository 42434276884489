



/**
 * 数组去重
 * @param {Array} selectedArr 需要去重的数组
 * 
 */ 
export function excludeDuplicates2ChargeRuleTimeSpan(selectedArr){
    // console.log('selectedArr',selectedArr);
    return Array.from(new Set(selectedArr.map(JSON.stringify)), JSON.parse);
}

export function hasSelectedInaAllChargeRuleConfig(chargeRuleConfig,timeSpansProp,key,value){

    let entity = {
        configIndex:-1,
        timeScopeIndex:-1
    }

    for(let configIndex in chargeRuleConfig){
        
        let selectedIndex = have(chargeRuleConfig[configIndex][timeSpansProp],key,value);
        if(selectedIndex !=-1){
            entity.configIndex = configIndex;
            entity.timeScopeIndex = selectedIndex;

            break;
        }
    }

    return entity;
    

}

export function have(arr,key,value){

    // console.log('arr',arr);

    let index = -1;
    
   for(let i = 0;i<arr.length ;i++){
        if(arr[i][key] === value){

            index = i;
            break;
        }
        
   }

    return index;
}

