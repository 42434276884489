export const rules = {
    englishNumbers: /^[a-zA-Z0-9]{1,15}$/, //英文字母加数字校验长度最长为15位
    zInteger: /^[1-9]\d*$/, // 正整数
    count0: /^([0-9]\d{0,2}|1000)$/, //0-1000的整数
    count0_10: /^([0-9]{0,1}|10)$/, //0-10的正整数
    count10: /^([1-9]{0,1}|10)$/, //1-10的正整数
    count2_1000: /^([2-9]{0,1}|[0-9]\d{1,2}|1000)$/, //2-1000的正整数
    count5_50: /(^[1-4][0-9]$)|50|(^[5-9]$)/, //5-50的正整数
    count1_50: /(^[1-4][0-9]$)|50|(^[1-9]$)/, //1-50的正整数
    count: /^([1-9]\d{0,2}|1000)$/, //1-1000的正整数
    count1w: /^([1-9]\d{0,3}|10000)$/, //1-10000的正整数
    count0_1w: /^([0-9]\d{0,3}|10000)$/, //0-10000的正整数
    count10w: /^([0-9]\d{0,4}|100000)$/, //0-100000的正整数
    integer: /^0*?[1-9]\d*$/, // 整数
    zFloat100: /^100(\.0*)?$|^0*$|^[0-9]?[0-9]?(\.[0-9]{0,6})?$/, //0-100正浮点数
    zFloat: /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/, //正浮点数
    zFloat2: /^[1-9]\d{0,8}$|^0\.\d{1,2}$|^[1-9]\d{0,8}\.\d{1,2}$/, //保留两位小数,整数位最大不过9位
    zFloat2_0: /^[1-9]\d{0,8}$|^0\.\d{1,2}$|^[1-9]\d{0,8}\.\d{1,2}$|0$/, //保留两位小数,整数位最大不过9位,可以为0
    zFloat3:/^[1-9]\d{0,8}$|[0-9]+\.\d{1,2}$|^[1-9]\d{0,8}\.\d{1,2}$/,//保留两位小数,整数位最大不过9位 可以是0.01 00.01 000.01 0000.01
    zFloat0_100: /^[1-9]\d{0,1}$|^0\.\d{1,2}$|^[1-9]\d{0,1}\.\d{1,2}$|100$/, //0.01-100正浮点数
    zFloat0_10: /^[1-9]$|^0\.\d{1}$|^[1-9]\.\d{1}$/, //0.1-9.9
    pwd_char20: /^(((?!([\u4e00-\u9fa5]|\s)).)+){1,20}$/, // 1-20位数的密码，不可输入中文 空格
    pwd_char: /^\d{6}$/, //6位数字密码
    pwd_en: /^(?=.*[0-9].*)(?=.*[a-z].*)(?=.*[A-Z].*)[0-9A-Za-z_]{8,10}$/, //必须包含大小写字母或数字，长度8~10(密码)
    code: /^[a-zA-Z0-9]{5}$/, //验证码
    str_en6_10: /^[a-zA-Z0-9]{6,10}$/, //只能为字母或数字，长度6-10
    str_en: /^[a-zA-Z0-9]{2,100}$/, //只能为字母或数字，长度2-100
    str_char1: /^[\u4e00-\u9fa5_a-zA-Z0-9]{1,100}$/, //只能为中文、字母或数字，长度2-100
    str_char: /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,100}$/, //只能为中文、字母或数字，长度2-100
    str_char300: /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,300}$/, //只能为中文、字母或数字，长度2-300
    str_char20: /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,20}$/, //只能为中文、字母或数字，长度2-20
    phone: /^(\+86)?1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/, // 手机号
    email: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/, //邮箱
    url: /^(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?$/,//网络请求
    phone_landline: /^((\d{11})|(\d{3}-\d{8})|(\d{4}-\d{7}))$/, //同时匹配手机号码和固话
    six_decimal_places: /^[0-9]+\.[0-9]{1,6}$/, //保留最多6位小数

};
