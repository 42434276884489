<template>
  <div class="home-wrapper">

    <div class="home-main">
      <!-- <div class="home-left">
         <div class="title">
              新能源充电桩-后台管理
          </div>
         <div class="slogan">
              —— 将复杂的事情简单化 ——
         </div>
         <div class="slogan">
              —— 行路难 行路难 多歧路 今安在 ——
         </div>
      </div> -->
      <div class="home-right">
        <!-- <img src="../../assets/admin-home.png"/> -->
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data() {

    return {}
  },
  methods: {},
  components: {}
}

</script>

<style lang="scss">
.home-wrapper {
  background-color: #fff;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  .home-main {
    display: flex;
    width: 96%;
    justify-content: center;

    .home-left {
      margin: auto 0;
      width: 45%;
      color: #999;
      text-align: center;

      .title {
        font-size: 40px;
        font-weight: 600;

      }

      .slogan {
        font-size: 30px;
        font-weight: 600;
        margin-top: 20px;
      }

    }

    .home-right {
      width: 100%;  
      overflow: hidden;
      background-image: url('../../assets/admin-home.png');
      // background-position: center 0;
      background-repeat: no-repeat;
      background-attachment:fixed;
      background-size: cover;
      -webkit-background-size: cover;/* 兼容Webkit内核浏览器如Chrome和Safari */
      -o-background-size: cover;/* 兼容Opera */
      // margin: auto 0;
      zoom: 1;

      img {
        width: 100%;

      }
    }
  }
}

</style>
