import {get, post} from '@/utils/request'

/**
 * 获取数据权限列表
 * @returns
 */
export function loadDataScopeList() {

    return get({
        url: '/data-scope/list'
    })
}

/**
 * 获取当前登录用户的数据权限
 */
export const getDataScopeByUser = () => {
    return get({url: '/data-scope/get-by-user'});
}

/**
 * 分页查询数据权限
 */
export const listDataScopePage = (params = {}) => {
    return get({url: '/data-scope/list-page', params: {...params}});
}
