import {get, post} from '@/utils/request'


/**
 * 通过code加载字典
 * @param {*} data
 * @returns
 */
export function loadDataDictByCode(data){

    return get({
        url:'/data-dict/load-by-code',
        params:{
            ...data
        }
    })
}

/**
 * 通过code数组查询多个字典数据
 * @param params 字典编号数组
 */
export const loadDataDictByCodes = (params = []) => {
    return get({url: '/data-dict/load-by-codes', params: {codes: params.toString()}});
}

/**
 * 系统字典分页查询功能
 * @param {*} data
 * @returns
 */

export function loadSystemDataDictPaging(data){

    return get({
        url:'/data-dict/system/load-paging',
        params:{
            ...data
        }
    })
}

/**
 * 懒加载系统数据字典子节点
 * @param {*} data
 * @returns
 */
export function lazyLoadSystemDataDictChildNode(data){
    return get({
        url:'/data-dict/system/lazy-load-child-node',
        params:{
            ...data
        }
    })
}
/**
 * 获取业务字典分页数据
 * @param {*} data 请求参数
 * @returns 分页数据
 */
export function loadBusinessDataDictPaging(data){

    return get({
        url:'/data-dict/business/load-paging',
        params:{
            ...data
        }
    })
}

/**
 * 懒加载业务字典子节点数据
 * @param {*} data 请求参数
 * @returns 业务字典子节点列表
 */
export function lazyLoadBusinessDataDictChildNode(data){
    return get({
        url:'/data-dict/business/lazy-load-child-node',
        params:{
            ...data
        }
    })
}

/**
 * 获取所有的业务字典集合
 * @returns
 */
export function loadAllBusinessDataDictList(){

    return get({
        url:'/data-dict/business/load-all-list'
    })
}

 /**
  * 判断当前code在所在上级字典中是否存在
  * @param {*} data
  */
export function existsDataDiceByCode(data){

    return get({
        url:'/data-dict/exists/code',
        params:{
            ...data
        }
    })

}


export function saveOrUpdateBusinessDataDict(data){

    return post(
        {
            url:'/data-dict/business/save-or-update',
            data:{
                ...data
            }
        }
    )
}

export function deleteBusinessDataDictById(data){

    return post({
        url:'/data-dict/business/delete-by-id',
        data:{
            ...data
        }
    })
}
