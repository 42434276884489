import {get, post} from '@/utils/request'

const prefix = '/stored-energy';

/**
 * 获取当天储能数据
 */
export const getStoredEnergyDataBigScreenDay = (params = {}) => {
    return get({url: `${prefix}/get`, params: {...params}});
};

/**
 * 导出储能数据大屏数据
 */
export const exportStoredEnergyDataOfDay = (params = {}) => {
    return post({url: `${prefix}/export`, data: {...params}, responseType: 'blob'});
};
