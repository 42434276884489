<template>
  <div id="app">
    <router-view class="app-views" ></router-view>
  </div>
</template>

<script>
// import '@/assets/scss/theme/default/index.scss'
import { Loading } from 'element-ui';
import {getArrayItemByItemAttribute} from '@/utils/tool'
import {themes} from '@/config/theme-settings'
export default {
  name: 'App',
  data(){

    return{

      themes:[
        ...themes
      ],
      loading:true

    }

  },
  methods:{

     changeTheme(){

      let settings = localStorage.getItem('settings');
      let url = 'default'
      if(settings != null){
        settings = JSON.parse(settings);
        url = settings.theme;
      }
      let theme = getArrayItemByItemAttribute(this.themes,'code',url);
      let loadingInstance = Loading.service({
        body:true,
        text:this.$t('public.loadingMessage.resourceLoading'),
        background:theme.color,
        customClass:'loading-main'
      });

      // import(`@/assets/scss/theme/${url}/index.scss`);

       require(`@/assets/scss/theme/${url}/index.scss`);

      setTimeout(() => {
          this.$nextTick(()=>{
            loadingInstance.close();
          })
      }, 1500);




    }

  },
  mounted(){


  },
  created(){
    this.changeTheme();
    /*if (sessionStorage.getItem('routeNow')!==null){
      console.log("触发了app的push-routeNow")
      this.$router.push({
        path:sessionStorage.getItem('routeNow')
      });
    }else {
      this.$router.push('/');
    }*/


  },
  watch:{
    $route(newVal,oldVal){
        sessionStorage.setItem('routeNow',this.$route.path);
    }
  },
  computed:{
    // variables(){
    //   return variables;
    // }
  }
}
</script>

<style lang="scss">
#app{
  background-color: var(--bg-theme-main-page-color);
  height: 100vh;
  overflow: hidden;

}
// .app-views{
//   animation: fadeIn  5s;
// }

:root{
  --bg-theme-page-color:#fff;
  --bg-theme-main-page-color:#f0f2f5;
}






</style>
