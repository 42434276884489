<template>
  <div class="data-main-wrapper">
    <i-table v-if="table.showTable" :config="table.config" :page="table.page" :page-sizes="defaultPageSizes" @change-page="handleChangePage">
      <template slot="search-condition">
        <el-form :inline="true" size="small">
          <el-form-item :label="field.name.name + ':'">
            <el-input clearable v-model="table.searchCondition.name" :placeholder="field.name.placeholder"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template slot="search-button">
        <el-button type="primary" size="small" @click="handleChangePage">{{btn.search}}</el-button>
      </template>
      <!--表头-->
      <template slot="header" slot-scope="scope">
        {{ field[scope.column.property].name }}
      </template>
    </i-table>
  </div>
</template>

<script>
import {defaultPageSizes,defaultPage} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable.vue';
import {dataScopeTableSettings} from '@/config/table-settings';
import {obtainPositionButtons, showData} from '@/utils/power';
import {positions} from '@/config/button-position-settings';
import {loadCurrentPageTheButtons} from '@/api/system/menu';
import {hasSuccess} from '@/utils/tool';
import {listDataScopePage} from '@/api/system/data-scope';

export default {

  components: {
    'i-table': ITable,
  },
  data() {
    return {
      defaultPageSizes,
      table: {
        showTable: false,
        page: {
          ...defaultPage
        },
        config: {
          columns: [...dataScopeTableSettings],
          rowKey: 'id'
        },
        searchCondition: {
          name: ''
        },
      },
      buttons: []
    }
  },
  computed: {
    /**
     * 获取页面按钮和按钮位置
     */
    btnPosition: {
      get: function () {
        return {obtainPositionButtons, positions}
      }
    },
    public() {
      return {
        unauthorizedView: this.$t('public.resultMessages.unauthorizedView'),
      }
    },
    btn() {
      return {
        search: this.$t('public.button.search'),
      }
    },
    field() {
      const fieldPrefix = 'dataScopeManagePage.field';
      const formPrefix = 'dataScopeManagePage.form';
      return {
        id: {name: this.$t(`${fieldPrefix}.id`)},
        code: {name: this.$t(`${fieldPrefix}.code`)},
        name: {
          name: this.$t(`${fieldPrefix}.name`),
          placeholder: this.$t(`${formPrefix}.name.placeholder`),
        },
        remark: {name: this.$t(`${fieldPrefix}.remark`)},
      }
    }
  },
  mounted() {
    this.initCurrentPageButton();
  },
  methods: {
    handleChangePage() {
      this.initDataScope();
    },
    /**
     * 初始化数据权限数据
     */
    async initDataScope() {
      const show = showData(this.buttons, positions.view);
      if (!show) {
        this.$message.error(this.public.unauthorizedView);
        return;
      }
      let {code, data} = await listDataScopePage({
        current: this.table.page.current,
        size: this.table.page.size,
        ...this.table.searchCondition
      });
      if (hasSuccess(code)) {
        this.table.page = {
          current: data.current,
          size: data.size,
          total: data.total,
          records: data.records,
        };
      }
    },
    /**
     * 初始化页面按钮数据
     */
    async initCurrentPageButton() {
      let {code, data} = await loadCurrentPageTheButtons({route: this.$route.path});
      if (hasSuccess(code)) {
        // 初始化页面按钮
        this.buttons = data;
        this.table.showTable = true;
        await this.initDataScope();
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
