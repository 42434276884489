export default {
    public: {//国际化公共映射
        button: {
            submit: '提交',
            confirm: '确定',
            cancel: '取消',
            search: '搜索',
            delete:'删除',
            enable: '启用',
            disabled: '禁用',
            login: '确认登录'
        },
        field: {},
        resultMessages: {
            success: '操作成功~',
            fail: '操作失败~',
            unauthorizedOperate: '您无权操作当前功能~',
            unauthorizedView: '您无权查看当前页面数据~',
            cancelDelete: '删除已取消~'
        },
        loadingMessage:{
            resourceLoading:'资源正在加载中......'
        },
        filter: {
            treePlaceholder: '输入关键字进行过滤',
        },
        elDatePicker:{//element-ui 日期范围选择器公共国际化
            rangeSeparator:'至',
            startPlaceholder:'开始日期',
            endPlaceholder:'结束日期'
        }

    },
    roleManagePage: {//角色管理页 国际化映射
        field: {
            id: '角色编号',
            name: '角色名称',
            alias: '角色别名',
            remarks: '备注',
            sort: '排序',
            dataScope: '权限范围',
            creatorName: '创建人',
            createTime: '创建时间',
            modifierName: '修改人',
            modifyTime: '修改时间',
            operate: '操作',
        },
        form: {
            id: {
                placeholder: '请输入角色编号',
            },
            name: {
                placeholder: '请输入角色名称',
                checkFail: {
                    required: '角色名称为必填项'
                }
            },
            alias: {
                placeholder: '请输入角色别名',
                checkFail: {
                    required: '角色别名为必填项'
                }
            },
            remarks: {
                placeholder: '请输入角色编号'
            },
            sort: {
                placeholder: '请输入角色排序',
                checkFail: {
                    required: '请为角色排序'
                }
            },
            dataScope: {
                placeholder: '请为选择角色数据权限',
                checkFail: {
                    required: '角色的数据权限不能为空'
                }
            }


        },
        roleFormDrawer: {
            addTitle: '添加角色',
            updateTitle: '修改角色信息'
        },
        menuPowerDialog: {
            title: '设置菜单权限',

        },
        dataPowerDialog: {
            title: '设置数据权限'
        },
        confirmPopup: {
            title: '提示',
            message: '此操作将永久删除该角色(该角色下的用户也将无法正常登录),是否继续？'

        }

    },
    menuManagePage:{//菜单管理页 国际化映射
        field:{
            id:'菜单编号',
            name:'菜单名称',
            code:'菜单编码',
            route:'菜单路由',
            icon:'菜单图标',
            openNewWindowName:'新窗口打开',
            typeName:'菜单类型',
            sort:'排序',
            position:'按钮位置',
            parent:'上级菜单',
            remarks:'备注',
            creatorName:'创建人',
            createTime:'创建时间',
            modifierName:'修改人',
            modifyTime:'修改时间',
            operate:'操作',

        },
        form:{
            id:{
                placeholder:'请输入菜单编号',
                checkFail:{
                    checkSearchId:'菜单编号的数据类型不合法'
                }
            },
            name:{
                placeholder:'请输入菜单名称',
                checkFail:{
                    required:'菜单名称为必填项'
                }
            },
            code: {
                placeholder: '输入菜单编码',
                checkFail: {
                    required: '菜单编码为必填项',
                    exist: '该菜单的上级菜单下已存在相同的菜单编码'
                }
            },
            route: {
                placeholder: '请输入菜单路由',
                checkFail: {
                    required: '菜单路由为必填项',
                    exist: '该菜单路由地址已存在'
                }
            },
            type: {
                placeholder: '请选择菜单类型',
                checkFail: {
                    required: '菜单类型为必选项',
                }
            },
            sort: {
                placeholder: '请为菜单排序',
                checkFail: {
                    required: '菜单必须为其排序',
                }
            },
            position: {
                placeholder: '请选择按钮位置',
                checkFail: {
                    required: '按钮位置未必选项',

                }
            },
            parentId:{
                placeholder:'请选上级菜单'
            },
            remarks:{
                placeholder:'请输入菜单备注'
            }

        },
        menuFormDrawer:{
            addTitle:'新增菜单',
            updateTitle:'修改菜单信息'
        },
        confirmPopup:{
            title:'提示',
            message:'此操作将永久删除该菜单, 是否继续?'

        }
    },
    systemDataDictPage:{//系统字典国际化
        field:{
            id:'字典编号',
            name:'字典名称',
            code:'字典编码',
            value:'字典值',
            remarks:'备注',
            creatorName:'创建人',
            createTime:'创建时间',
            modifierName:'修改人',
            modifyTime:'修改时间'
        },
        form:{
            id:{
                placeholder:'请输入字典编号',
                checkFail:{
                    checkNumber:'字典编号应为纯正整数的数字'
                }
            },
            name:{
                placeholder:'请输入字典编号',
                checkFail:{

                }
            }

        }

    },
    businessDataDictPage:{//业务字典国际化
        field:{
            id:'字典编号',
            name:'字典名称',
            code:'字典编码',
            value:'字典值',
            parentId:'上级字典',
            statusName:'是否启用',
            status:'是否启用',
            remarks:'备注',
            sort:'排序',
            creatorName:'创建人',
            createTime:'创建时间',
            modifierName:'修改人',
            modifyTime:'修改时间',
            operate:'操作'
        },
        form:{


            id:{
                placeholder:'请输入字典编号',
                checkFail:{
                    checkNumber:'字典编号应为纯正整数的数字'
                }
            },
            name:{
                placeholder:'请输入字典编号',
                checkFail:{
                    required:'字典名称为必填项'
                }
            },
            code:{
                placeholder:'请输入字典编码',
                checkFail:{
                    required:'字典名称为必填项',
                    exist:'该字典编码已经存在'
                }
            },
            value:{
                placeholder:'请输入字典值',
            },
            parentId:{
                placeholder:'请选择上级字典'
            },
            remarks:{
                placeholder:'请输入字典备注'
            },
            sort:{
                placeholder:'请为字典排序',
                checkFail:{
                    required:'排序字段为必填项'
                }
            },
            status:{
                checkFail:{
                    required:'是否启用字段为必填项'
                }
            }
        },
        businessDataDictFormDrawer:{
            addTitle:'新增字典',
            updateTitle:'修改字典信息'

        },
        confirmPopup: {
            title: '提示',
            message: '此操作将永久该字典数据,是否继续？'

        }
    },
    globalAdministrativeDivisionsPage:{ //全球国家行政区划
        field:{
            name:'名称',
            parentId:'上级行政区编号',
            parentName:'上级行政区名称',
            code:'行政区代码',
            remarks:'备注',
        },
        title:{
            allAdministrativeDivisions:'全部行政区划',
            administrativeDivisionsDetails:'行政区划详情'
        },
        form:{
            search:{
                placeholder:'请输入关键词查询国家或地区'
            }
        }
    },
    chargeRulePage:{
        field:{
            id:'充电规则编号',
            name:'充电规则名称',
            orgName:'所属机构名称',
            remarks:'备注',
            unit:'单位',
            serviceCharge:'服务费价格',
            electricChargeTypeName:'电费计费类型',
            timeSpanType:'时段类型',
            electricCharge:'电费价格',
            creatorName:'创建人',
            createTime:'创建时间',
            modifierName:'修改人',
            modifyTime:'修改时间',
            operate:'操作',
            rule:'规则'
        },
        form:{
            id:{
                placeholder:'请输入充电规则编号',

            },
            name:{
                placeholder:'请输入充电规则名称',
                checkFail:{
                    required:'充电规则名称为必填项'
                }
            },
            orgId:{
                placeholder:'请选择所属机构',
                checkFail:{
                    required:'所属机构为必选项'
                }
            },
            remark:{
                placeholder:'请输入规则的备注',
            },
            electricChargeType:{
                placeholder:'请选择电费计费类型',
                checkFail:{
                    required:'电费计费类型为必选项'
                }
            },
            electricCharge:{
                placeholder:'请输入电费价格,单位：元/度,最低不能低于0.01元',
                checkFail:{
                    required:'电费价格为必填项',
                }
            },
            serviceCharge:{
                placeholder:'请输入服务费价格,最低不能低于0.01元',
                checkFail:{
                    required:'服务费价格为必填项',

                }
            },
            unit:{
                placeholder:'请选择服务费的计量单位',
                checkFail:{
                    required:'服务费的计量单位必选项'
                }
            },
            timeSpanType:{
                placeholder:'请选择时段类型',
                checkFail:{
                    required:'时段类型必选项'
                }
            },
            addTimeSpanRule:{
                checkFail:{
                    max:'规则数量最多不能超过4个',
                    min:'规则数量最少不等少于1个'
                }
            },
            selection:{
                checkFail:{
                    required:'所有的时间区间必须被选择,不能留空~'
                }
            },
            charge:{
                checkFail:{
                    checkMoney:'金额必须为正数,金额只保留两位小数'
                }
            },
            chargeRuleItemList:{
                checkFail:{
                    required:'分时计费模式，最少必须配置一项时间区间计费规则~'
                }
            }
        },
        chargeRuleFormDrawer:{
            addTitle:'添加充电桩规则',
            updateTitle:'修改充电桩规则',
            baseInfoTitle:'基本信息',
            configTitle:'配置信息'
        },
        button:{
            addTimeSpanRule:'添加时间区间规则'
        },
        confirmPopup:{
            title:'提示',
            message:'此操作将永久删除该充电规则, 是否继续?'

        }

    },
    clientUserWalletAccountPage:{//平台用户钱包国际化
        field:{
            id:'编号',
            userId:'用户编号',
            nickname:'用户昵称',
            accumulateRechargeByYuan:'累计充值',
            remainingSumByYuan:'剩余金额',
            creatorName:'创建人',
            createTime:'创建时间',
            modifierName:'修改人',
            modifyTime:'修改时间',
            operate:'操作'
        },
        form:{
            nickname:{
                placeholder:'请输入用户昵称'
            }
        }

    },
    clientUserRechargeOrderPage:{//平台用户充值订单
        field:{
            id:'编号',
            userId:'用户编号',
            nickname:'用户昵称',
            mobile:'下单手机号',
            orderCode:'订单编号',
            amountByYuan:'支付金额',
            paymentTypeName:'支付平台',
            paymentOrderCode:'第三方支付订单编号',
            paymentTime:'支付时间',
            orderTime:'下单时间',
            statusName:'订单状态',
            remarks:'备注',
            creatorName:'创建人',
            createTime:'创建时间',
            modifierName:'修改人',
            modifyTime:'修改时间',
            operate:'操作'
        },
        form:{
            orderCode: {
                placeholder:'请输入订单编号'
            },
            paymentOrderCode:{
                placeholder:'请输入第三方支付订单编号'
            },
            statusName:{
                placeholder:'请选择订单状态'
            }
        }

    },
    webUserManagePage: {
        field: {
            id: '编号',
            orgId: '所属机构',
            roleAlias: '角色名称',
            username: '用户账号',
            nickname: '用户昵称',
            password: '用户密码',
            mobile: '手机号码',
            avatar: '头像',
            birthday: '出生日期',
            email: '邮箱',
            genderName: '性别',
            enableName: '是否启用',
            lastLoginTime: '用户最后登录时间',
            loginTypeName: '登录类型',
            orgName: '机构名称',
            modifierName: '修改人',
            modifyTime: '修改时间',
            creatorName: '创建人',
            createTime: '创建时间',
            operate: '操作'
        },
        form: {
            orgId: {
                placeholder:'请选择所属机构',
                checkFail: {
                    required:'请选择所属机构',
                }
            },
            roleId: {
              placeholder: '请选择用户角色',
              required: '请选择用户角色',
            },
            username: {
                placeholder:'请输入用户账号',
                checkFail: {
                    required:'用户账号不能为空',
                    rule: '只能填写英文或数字的字符, 且最大长度为15个字符',
                }
            },
            password: {
                placeholder:'请输入用户密码',
                checkFail: {
                    required:'请输入用户密码',
                }
            },
            nickname: {
                placeholder:'请输入用户昵称',
                checkFail: {
                    required: '请填写用户昵称',
                    rule: '长度在 1 到 20 个字符',
                }
            },
            mobile: {
                placeholder:'请输入用户手机号码',
                checkFail: {
                    rule: '请填写正确的手机号',
                }
            },
            avatar: {
                placeholder:'请上传头像',
                checkFail: {
                    rule: '请填写正确的头像地址'
                }
            },
            email: {
                placeholder:'请输入邮件',
                checkFail: {
                    rule: '请填写正确的邮箱',
                }
            },
            enable: {
                checkFail: {
                    required:'请选择是否启用',
                }
            },
            loginType: {
                checkFail: {
                    required:'请选择登录类型',
                }
            },
            birthday: {
                placeholder: '选择出生日期',
            }
        },
        drawer: {
            add: '添加用户',
            update: '编辑用户信息',
            setUserRole: '设置用户角色',
            resetPassword: {
                confirm: {
                    warn: '重置用户【{username}】密码, 是否继续?',
                    title: '重置用户密码',
                    cancel: '已取消重置密码',
                }
            },
            delete: {
                info: '不能将自己删除噢',
                confirm: {
                    warn: '刪除系統用户【{username}】, 是否继续?',
                    title: '刪除系統用户',
                    cancel: '已取消删除系统用户'
                }
            }
        },
        searchCondition: {
            username: '用户账号',
            nickname: '用户昵称'
        },
        dialog: {
            warn: '没有角色可设置, 请联系当前机构管理员添加角色',
        }
    },
    organizationManagePage: {
        field:{
            id: '编号',
            orgName: '机构名称',
            parentOrgName: '父级机构',
            contactPerson: '联系人',
            contactPhone: '联系电话',
            typeName: '机构类型',
            parentId: '父级机构',
            type: '机构类型',
            sort: '排序',
            address: '详细地址',
            level: '级别',
            remark: '备注',
            modifierName: '修改人',
            modifyTime: '修改时间',
            creatorName: '创建人',
            createTime: '创建时间',
            operate: '操作',
        },
        form:{
            orgName: {
                placeholder: '请填写机构名称',
                checkFail: {
                    required: '请填写机构名称',
                    rule: '长度在1到20个字符',
                },
            },
            parentId: {
                placeholder: '请选择父级机构',
                checkFail: {
                    required: '请选择父级机构'
                }
            },
            contactPerson: {
                placeholder: '请填写联系人',
                checkFail: {
                    required: '请填写联系人名称'
                }
            },
            contactPhone: {
                placeholder: '请填写联系人电话',
                checkFail: {
                    required: '请填写联系电话',
                    rule: '请填写正确的手机号'
                }
            },
            type: {
                checkFail: {
                    required: '请选择机构类型'
                }
            },
            address: {
                placeholder: '请输入详细地址',
                checkFail: {
                    rule: '请填写0-230个字符'
                }
            },
            remark: {
                placeholder: '请输入备注',
                checkFail: {
                    rule: '请填写0-230个字符'
                }
            },
            defaultOrgName: '全部',
        },
        drawer:{
            save: '新增机构',
            update: '编辑机构',
            orgNotNull: '机构不能为空',
            confirm: {
                title: '刪除机构',
                confirmMessage: '刪除机构【{orgName}】, 是否继续?',
                cancelMessage: '已取消删除机构',
            }
        },
        searchCondition:{
            parentId: '查询选择机构的所有子孙机构',
        },
    },
    loginManagePage: {
        loginTitle: '欢迎登录！',
        form: {
            username: {
                placeholder: '请填写用户名',
                checkFail: {
                    required: '请填写用户名'
                }
            },
            password: {
                placeholder: '请填写用户密码',
                checkFail: {
                    required: '请填写用户密码',
                    rule: '1-20位数的密码，不可输入中文、空格',
                }
            },
            errorMsg: '账号或密码请填写正确'
        }
    },
    chargeStationManagePage: {
        field: {
            id: '编号',
            orgName: '所属机构',
            stationName: '充电站名称',
            contactPerson: '联系人',
            contactPhone:'联系电话',
            country: '国家',
            province: '省份/直辖市',
            city: '城市/市级地区',
            district: '区/县',
            detailedAddress: '详细地址',
            fullAddress: '完整的地址',
            longitude: '经度',
            latitude: '纬度',
            operateTypeName: '运营类型',
            getAddressTypeName: '获取地址方式',
            image: '站点图片',
            remark: '备注',
            modifierName: '修改人',
            modifyTime: '修改时间',
            creatorName: '创建人',
            createTime: '创建时间',
            countryRegion: '国家/地区',
            operate: '操作',
        },
        form: {
            stationName:{placeholder: '请填写充电站名称', required: '请填写充电站名称', rule: '请填写1-30个字符'},
            orgId:{placeholder: '请选择所属机构', required: '请选择所属机构'},
            contactPerson:{placeholder: '请填写联系人', required: '请填写联系人', rule: '请填写1-30个字符'},
            contactPhone:{placeholder: '请填写联系电话', required: '请填写联系电话', rule: '请填写正确的联系电话'},
            province:{placeholder: '请选择省份'},
            city:{placeholder: '请选择城市'},
            district:{placeholder: '请选择区/县'},
            detailedAddress:{placeholder: '请填写详细地址', required: '请填写详细地址', rule: '请填写1-300个字符'},
            longitude:{placeholder: '请填写经度',required: '请填写经度', rule: '请填写正确的经度, 至少 1 位小数且不要超过 6 位'},
            latitude:{placeholder: '请填写纬度',required: '请填写纬度', rule: '请填写正确的纬度, 至少 1 位小数且不要超过 6 位'},
            operateTypeName:{required: '请选择运营类型'},
            getAddressTypeName:{required: '请选择获取地址方式'},
            remark:{placeholder: '请填写备注', rule: '请填写0-300个字符'},
            countryRegion: {placeholder: '请选择国家地区(可输进行模糊查询)', required: '请选择国家地区'}
        },
        confirm: {
            confirmMessage: '刪除机构【{stationName}】, 是否继续?',
            title: '删除充电站',
            cancelMessage: '已取消删除充电站'
        }
    },
    chargePileManagePage: {
        field: {
            id: '编号',
            orgName: '所属机构',
            stationName: '所属充电站',
            chargeRuleName: '计费规则',
            chargePileCode: '充电桩编号',
            chargePileName: '充电桩名称',
            brandName: '品牌名称',
            brandModelName: '品牌型号名称',
            brandModelTypeName: '品牌型号类型名称',
            gunNumber: '枪口数量',
            maxPower: '最大功率(kw)',
            onlineStatusName: '在线状态',
            serviceStatusName: '服务状态',
            useScopeName: '使用范围',
            enableName: '是否启用',
            remark: '备注',
            modifierName: '修改人',
            modifyTime: '修改时间',
            creatorName: '创建人',
            createTime: '创建时间',
            operate: '操作',
        },
        form: {
            orgName: {
                placeholder: '请选择所属机构',
                checkFail: {
                    required: '请选择所属机构',
                },
            },
            stationName: {
                placeholder: '请选择所属充电站',
                checkFail: {
                    required: '请选择所属充电站',
                },
            },
            chargeRuleName: {
                placeholder: '请选择计费规则',
                checkFail: {
                    required: '请选择计费规则',
                },
            },
            chargePileCode: {
                placeholder: '系统自动生成',
                search: '请填写充电桩编号',
            },
            chargePileName: {
                placeholder: '请填写充电桩名称',
                checkFail: {
                    required: '请填写充电桩名称',
                    maxSize: '请填写 1-30 个字符',
                }
            },
            brandName: {
                placeholder: '请选择充电桩品牌',
                checkFail: {
                    required: '请选择充电桩品牌',
                }
            },
            brandModelName: {
                checkFail: {
                    required: '请选择充电桩品牌型号',
                }
            },
            /*chargePileTypeName: {
                placeholder: '请选择充电桩类型',
                checkFail: {
                    required: '请选择充电桩类型',
                }
            },
            protocolTypeName: {
                placeholder: '请选择充电桩协议',
                checkFail: {
                    required: '请选择充电桩协议'
                }
            },
            electricCurrent: {
                placeholder: '请填写充电桩最大电流',
                checkFail: {
                    required: '请填写充电桩最大电流',
                    maxSize: '请填写数值类型数据, 整数位不超过 9 位且小数位 1~2 位',
                }
            },
            electricVoltage: {
                placeholder: '请填写充电桩最大电压',
                checkFail: {
                    required: '请填写充电桩最大电压',
                    maxSize: '请填写数值类型数据, 整数位不超过 9 位且小数位 1~2 位',
                }
            },
            portNumber: {
                placeholder: '请填写充电桩端口数量',
                checkFail: {
                    required: '请填写充电桩端口数量',
                    number: '请填写正整数数值',
                }
            },
            maxPower: {
                placeholder: '请填写充电桩最大功率数',
                checkFail: {
                    required: '请填写充电桩最大功率数',
                    maxSize: '请填写数值类型数据, 整数位不超过 9 位且小数位 1~2 位',
                }
            },*/
            useScopeName: {
                placeholder: '请选择使用范围',
                checkFail: {
                    required: '请选择使用范围',
                }
            },
            enableName: {
                placeholder: '请选择启用/禁用',
                checkFail: {
                    required: '请选择启用/禁用',
                }
            },
            remark: {
                placeholder: '请填写备注',
                checkFail: {
                    maxSize: '请填写 0-300 个字符',
                }
            },
        },
        chargePileDrawer: {
            saveTitle: '添加充电桩',
            updateTitle: '修改充电桩',
        },
        confirm: {
            confirmMessage: '刪除充电桩【{chargePileName}】, 是否继续?',
            title: '删除充电桩',
            cancelMessage: '已取消删除充电桩',
        },
        gunDrawer: {
            title: '配置充电桩枪口',
            baseInfoTitle: '基本信息',
            settingTitle: '配置信息',
            sign: '枪口',
            addGun: {
                title: '添加充电桩枪口',
                warnMessage: '配置枪口数超过充电桩枪口数, 无法新增',
                placeholder: '系统自动生成枪口编号',
                infoMessage: '没有充电桩数据可配置, 无需提交',
            },
            gunCode: '枪口编号',
            gunName: {
                name: '枪口名称',
                placeholder: '请填写枪口名称',
                required: '请填写枪口名称',
                maxSize: '请填写1~20个字符'
            },
        },
        chargeRuleDialog: {
            title: '设置计费规则',
            chargeRule: {
                placeholder: '请选择计费规则',
                required: '请选择计费规则',
            },
        }
    },
    clientWechatAppletUser: {
        field: {
            id: '主键',
            userId: '用户ID',
            nickname: '昵称',
            mobile: '手机号码',
            avatar: '头像',
            genderName: '性别',
            email: '邮箱',
            identityTypeName: '身份类型',
            modifierName: '修改人',
            modifyTime: '修改时间',
            creatorName: '创建人',
            createTime: '创建时间',
        },
        form: {
            mobile: {
                placeholder: '请填写用户手机号',
            },
            nickname: {
                placeholder: '请填写用户昵称',
            }
        }
    },
    mainManagePage: {
        field: {
            personalData: '个人资料',
            logout: '退出登录',
            userInfo: '个人信息',
            avatar: '头像',
            orgName: '所属机构',
            username: '用户账号',
            nickname: '用户昵称',
            mobile: '手机号码',
            email: '邮箱',
            changePassword: '修改密码',
            originalPassword: '原始密码',
            newPassword: '新密码',
            confirmPassword: '确认密码',
        },
        mainDrawer: {
            orgName: {
                checkFail: {
                    required: '请选择所属机构',
                    maxSize: '长度在 1 到 20 个字符',
                }
            },
            username: {
                checkFail: {
                    required: '用户账号不能为空',
                    maxSize: '只能填写英文或数字的字符, 且最大长度为15个字符',
                }
            },
            nickname: {
                placeholder: '请填写用户昵称',
                checkFail: {
                    required: '请填写用户昵称',
                    maxSize: '长度在 1 到 20 个字符'
                }
            },
            mobile: {
                placeholder: '请填写手机号码',
                checkFail: {
                    notNull: '请填写正确的手机号'
                }
            },
            email: {
                placeholder: '请填写邮箱地址',
                checkFail: {
                    notNull: '请填写正确的邮箱',
                }
            },
            avatar: {
                checkFail: {
                    notNull: '请填写正确的头像地址'
                }
            },
            originalPassword: {
                placeholder: '请填写原始密码',
                checkFail: {
                    required: '请填写原始密码',
                    maxSize: '1-20位数的密码，不可输入中文、空格',
                }
            },
            newPassword: {
                placeholder: '请填写新密码',
                checkFail: {
                    required: '请填写新密码',
                    maxSize: '1-20位数的密码，不可输入中文、空格'
                }
            },
            confirmPassword: {
                placeholder: '请填写确认密码',
                checkFail: {
                    required: '请再次新密码进行确认',
                    confirm: '两次输入密码不一致',
                }
            },
            drawerTitle: '查看个人信息',
            passwordConsistency: '新密码与原始密码一致, 无需修改'
        },
    },
    dataScopeManagePage: {
        field: {
            id: '编号',
            code: '权限编号',
            name: '数据范围名称',
            remark: '数据范围描述',
        },
        form: {
            name: {
                placeholder: '请填写数据范围名称'
            }
        }
    },
    chargePileBrandManagePage: {
        field: {
            id: '主键',
            brandName: '品牌名称',
            protocolTypeCode: '协议类型编号',
            protocolTypeName: '协议类型名称',
            remark: '备注',
            modifierName: '修改人',
            modifyTime: '修改时间',
            creatorName: '创建人',
            createTime: '创建时间',
            operate: '操作',
        },
        form: {
            brandName: {
                placeholder: '请填写品牌名称',
                checkFail: {
                    required: '请填写品牌名称',
                    maxSize: '请填写1-30个字符'
                }
            },
            protocolTypeCode: {
                formName: '品牌协议',
                placeholder: '请选择品牌协议类型',
                checkFail: {
                    required: '请选择品牌协议类型'
                }
            },
            remark: {
                placeholder: '请填写备注信息',
                checkFail: {
                    maxSize: '请填写0-300个字符'
                }
            },
        },
        operateInfo: {
            addTitle: '添加充电桩品牌',
            updateTitle: '修改充电桩品牌',
            deleteTitle: '删除充电桩品牌',
            deleteConfirm: {
                confirmMessage: '刪除品牌【{brandName}】, 是否继续?',
                cancelMessage: '已取消删除机构',
            }
        }
    },
    chargeBrandModelManagePage: {
        field: {
            id: '主键',
            brandName: '品牌名称',
            brandModelName: '品牌型号名称',
            brandModelTypeName: '品牌型号类型',
            gunNumber: '充电枪数量',
            maxPower: '最大功率',
            remark: '备注',
            modifierName: '修改人',
            modifyTime: '修改时间',
            creatorName: '创建人',
            createTime: '创建时间',
            operate: '操作'
        },
        form: {
            brandName: {
                placeholder: '请选择充电桩品牌',
                checkFail: {
                    required: '请选择充电桩品牌'
                }
            },
            brandModelName: {
                placeholder: '请填写品牌型号名称',
                checkFail: {
                    required: '请填写品牌型号名称',
                    maxSize: '请填写1-30个字符'
                }
            },
            brandModelTypeName: {
                checkFail: {
                    required: '请选择品牌型号类型'
                }
            },
            gunNumber: {
                placeholder: '请填写充电枪数量',
                checkFail: {
                    required: '请填写充电枪数量',
                    number: '只能填写数字类型'
                },
            },
            maxPower: {
                placeholder: '请填写最大功率数',
                checkFail: {
                    required: '请填写最大功率数',
                    number: '只能填写数字类型'
                }
            },
            remark: {
                placeholder: '请填写备注信息',
                checkFail: {
                    maxSize: '请填写0-300个字符'
                }
            },
        },
        operateInfo: {
            addTitle: '添加充电桩品牌型号',
            updateTitle: '修改充电桩品牌型号',
            deleteTitle: '删除充电桩品牌型号',
            deleteConfirm: {
                confirmMessage: '刪除品牌【{brandModelName}】, 是否继续?',
                cancelMessage: '已取消删除品牌型号',
            }
        }
    },
    clientUserChargingOrderManagePage: {
        field: {
            id: '主键',
            chargingOrderCode: '充电订单编号',
            transactionSerialNumber: '交易流水号',
            mobile: '用户手机号',
            orgName: '机构名称',
            stationName: '充电站名称',
            chargePileCode: '充电桩编号',
            chargePileName: '充电桩名称',
            chargeGunCode: '充电枪编号',
            fullChargeGunCode: '完整充电枪编号',
            chargingStartTime: '起始充电时间',
            chargingEndTime: '起止充电时间',
            sharpUnitPriceByYuan: '尖单价',
            sharpElectricQuantity: '尖电量',
            sharpLossElectricQuantity: '计损尖电量',
            sharpAmountByYuan: '尖金额',
            peakUnitPriceByYuan: '峰单价',
            peakElectricQuantity: '峰电量',
            peakLossElectricQuantity: '计损峰电量',
            peakAmountByYuan: '峰金额',
            flatUnitPriceByYuan: '平单价',
            flatElectricQuantity: '平电量',
            flatLossElectricQuantity: '计损平电量',
            flatAmountByYuan: '平金额',
            valleyUnitPriceByYuan: '谷单价',
            valleyElectricQuantity: '谷电量',
            valleyLossElectricQuantity: '计损谷电量',
            valleyAmountByYuan: '谷金额',
            meterTotalStartValue: '电表总起值',
            meterTotalEndValue: '电表总止值',
            totalElectricity: '总电量',
            lossTotalElectric: '计损总电量',
            consumeAmountByYuan: '消费金额',
            statusName: '订单状态',
            stopReason: '停止原因',
            transactionDate: '交易日期',
            actualPaidAmountByYuan: '实际支付金额',
            createTime: '创建时间',
        },
        form: {
            chargingOrderCode: {
                placeholder: '请填写充电订单编号',
            },
            orgName: {
                placeholder: '请填写机构名称',
            },
            stationName: {
                placeholder: '请填写充电站名称',
            },
            statusName: {
                placeholder: '请选择充电订单状态',
            }
        }
    },

    clientUserRefundOrderManagePage: {
        field: {
            id: '编号',
            userId: '用户ID',
            rechargeOrderId: '用户充值订单ID',
            refundOrderCode: '退款订单编号',
            rechargeOrderCode: '支付订单编号',
            paymentOrderCode: '第三方支付订单编号',
            paidAmountYuan: '支付金额',
            refundAmountYuan: '本次退款金额',
            refundFeeYuan: '退款手续费',
            currency: '交易货币类型',
            successTime: '退款成功时间',
            receptionTime: '受理时间',
            refundStatusName: '退款状态',
            refundReason: '退款原因',
            paymentTypeName: '支付平台类型',
            thirdRefundId: '第三方退款订单ID',
            hasCallbackName: '是否回调成功',
            callbackTime: '回调时间',
            refundFailedReason: '退款失败原因',
            userReceivedAccount: '当前退款单的退款入账方',
            modifyTime: '修改时间',
            createTime: '创建时间',
        },
        form: {
            refundOrderCode: {
                placeholder: '填写退款订单编号',
            },
            rechargeOrderCode: {
                placeholder: '填写平台支付订单编号',
            },
            paymentOrderCode: {
                placeholder: '填写第三方支付订单编号'
            },
            refundStatusName: {
                placeholder: '退款状态'
            }
        }
    }
}
