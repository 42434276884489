import {get, post} from '@/utils/request'

/**
 * 查询机构树
 */
export const grantTree = () => {
    return get({url: '/organization/grant-tree'});
}

/**
 * 查询机构下拉列表
 */
export const listOrgSelect = (params = {}) => {
    return get({url: '/organization/list-select', params: {...params}});
}

/**
 * 查询机构列表
 * @param params
 */
export const listOrganizationPage = (params = {}) => {
    return get({url: '/organization/list', params: {...params}});
}

/**
 * 新增或修改机构
 * @param params
 */
export const saveOrUpdateOrganization = (params = {}) => {
    return post({url: '/organization/save-or-update', data: {...params}});
}

/**
 * 删除机构
 * @param params
 */
export const deleteOrganization = (params = {}) => {
    return post({url: '/organization/delete', data: {...params}});
}

/**
 * 查询当前机构的先祖机构
 * @param params
 */
export const listOrgParent = (params = {}) => {
    return get({url: '/organization/list-parent', params: {...params}});
}
