<template>
    <div class="charge-rule_wrapper">

        <i-table :config="config" :page="page" v-if="showPage" :page-sizes="defaultPageSizes">
            <!-- 搜索栏 -->
            <template slot="search-condition">
                <el-form :model="search.values" :inline="true" ref="searchRef">

                    <el-form-item :label="field.name+':'">
                        <el-input size="small" v-model="search.values.name" :placeholder="form.name.placeholder" clearable />
                    </el-form-item>
                </el-form>
            </template>

             <!-- 搜索按钮 -->
             <template slot="search-button">
                <el-button type="primary"  size="small" @click="searchHandler" icon="el-icon-search">{{ buttonText.search }}</el-button>
            </template>

            <!-- 表头 -->
            <template slot="header" slot-scope="scope">
                {{ field[scope.column.property] }}
            </template>

            <!-- 左侧功能栏 -->
            <template slot="overall-left">
                <el-button v-for="(overallLeftButton,index) in obtainPositionButtons(buttons,positions.left)" :key="index" type="primary"  size="small" :icon="overallLeftButton.icon" @click="operateHandler(overallLeftButton.code)">{{overallLeftButton.name}}</el-button>
            </template>

            <!-- 右侧工作栏 -->
            <template slot="overall-right">
                <el-button v-for="(overallRightButton,index) in obtainPositionButtons(buttons,positions.right)" :key="index"   size="small" :icon="overallRightButton.icon" @click="operateHandler(overallRightButton.code)">{{overallRightButton.name}}</el-button>
            </template>

            <!-- 数据行内 -->
            <template slot="row_operate" slot-scope="scope">
                <el-button
                type="text"
                :icon="rowButton.icon"
                size="mini"
                v-for="(rowButton,index) in obtainPositionButtons(buttons,positions.row) "
                :key="index"
                @click="operateHandler(rowButton.code,scope.row)"
                >
                    {{ rowButton.name  }}
                </el-button>
            </template>

        </i-table>

        <!-- 修改或者新增电费表单抽屉 -->
        <i-drawer 
        :visible.sync="chargeRuleFormDrawer.hasOpen"
        :title="chargeRuleFormDrawer.title"
        size="60%"
        @close="closeDrawerHandler"
        >

            <div class="charge-rule-form-drawer">
                <el-form :model="chargeRuleFormDrawer.data" label-width="120px" ref="chargeRuleFormDrawerRef" :rules="chargeRuleFormRules">
                    <i-title :title="chargeRuleFormDrawerText.baseInfoTitle"></i-title>

                    <el-form-item :label="field.name + ':'" prop="name">
                        <el-input size="small" v-model="chargeRuleFormDrawer.data.name" :placeholder="form.name.placeholder"/>
                    </el-form-item>

                    <el-form-item :label="field.orgName + ':'" prop="orgId">
                        <el-select 
                        v-model="chargeRuleFormDrawer.data.orgId" 
                        :placeholder="form.orgId.placeholder"
                        clearable
                        filterable
                        size="small"
                        >
                        <el-option :label="item.orgName" :value="item.id" v-for="(item,index) in orgList" :key="index"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item  :label="field.remarks + ':'">
                        <el-input type="textarea" v-model="chargeRuleFormDrawer.data.remarks" :placeholder="form.remarks.placeholder"/>
                    </el-form-item>
                    <i-title :title="chargeRuleFormDrawerText.configTitle"></i-title>

                    <!-- 电费计费类型 -->
                    <el-form-item :label="field.electricChargeTypeName + ':'" prop="electricChargeType">
                        <el-select 
                        v-model="chargeRuleFormDrawer.data.electricChargeType" 
                        :placeholder="form.electricChargeType.placeholder"
                        
                        filterable
                        size="small"
                        >
                        <el-option  size="small"  :label="item.name" :value="item.value" v-for="(item,index) in chargeTypeList" :key="index"></el-option>
                        </el-select>

                    </el-form-item>

                    <!-- 添加规则 -->
                    <el-form-item v-show="chargeRuleFormDrawer.data.electricChargeType == 1">
                        <el-button size="small" type="" @click="addChargeTimeSpanRule">{{ buttonText.addTimeSpanRule }}</el-button>
                    </el-form-item>

                    <!-- 全天同价电费价格 -->
                    <el-form-item :label="field.electricCharge+ ':'" v-if="chargeRuleFormDrawer.data.electricChargeType == 0" prop="electricChargeByYuan">
                        <el-input size="small" v-model="chargeRuleFormDrawer.data.electricChargeByYuan" type="text" :placeholder="form.electricCharge.placeholder" />
                    </el-form-item>

                    <el-form-item :label="field.serviceCharge+ ':'" v-if="chargeRuleFormDrawer.data.electricChargeType == 0" :rules="[{'required': true}]">
                        <el-row :gutter="10">

                            <el-col :span="15">

                                <el-form-item prop="serviceChargeByYuan" >
                                    <el-input size="small" v-model="chargeRuleFormDrawer.data.serviceChargeByYuan" :placeholder="form.serviceCharge.placeholder" type="text"></el-input>
                                </el-form-item>
                                
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="unit">
                                    <el-select size="small" v-model="chargeRuleFormDrawer.data.unit" :placeholder="form.unit.placeholder" clearable>
                                        <el-option :label="item.name" :value="item.value" v-for="(item,index) in serviceChargeUnitList" :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                            
                            </el-col>
                        </el-row>
                    </el-form-item>
                    
                    <!-- 动态添加表单 -->
                    <div v-for="(item,index) in chargeRuleItemList" :key="index" >
                    <div v-if="chargeRuleFormDrawer.data.electricChargeType == 1">
                            <i-title :title="`${field.rule + (index+1)}`">
                                <template slot="right">
                                    <el-button size="small" type="text" @click="deleteChargeRuleItemHandler(index,item)">{{ buttonText.delete }}</el-button>
                                </template>
                            </i-title>
                            <el-form :model="item" label-width="120px"  ref="chargeRuleItemsRef" :rules="chargeRuleItemFormRules">
                                <el-form-item :label="field.timeSpanType + ':'" prop="chargeTimeSpanType">
                                        <el-select size="small" v-model="item.chargeTimeSpanType" :placeholder="form.timeSpanType.placeholder">
                                            <el-option :label="item.name" :value="item.value" v-for="(item,index) in chargeTimeSpanTypeList" :key="index"></el-option>
                                        </el-select>
                                </el-form-item>

                                <el-form-item :label="field.electricCharge + ':'" prop="electricChargeByYuan">
                                    <el-input size="small" v-model="item.electricChargeByYuan" type="text" :placeholder="form.electricChargeType.placeholder"></el-input>
                                </el-form-item>

                                <el-form-item :label="field.serviceCharge+ ':'" :rules="[{'required': true}]">

                                    <el-row :gutter="10">
                                        <el-col :span="15">
                                            <el-form-item prop="serviceChargeByYuan">
                                                <el-input size="small" v-model="item.serviceChargeByYuan" :placeholder="form.serviceCharge.placeholder" type="text"></el-input>
                                            </el-form-item>
                                            
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item prop="unit">
                                                <el-select size="small" v-model="item.unit" :placeholder="form.unit.placeholder" clearable>
                                                    <el-option :label="item.name" :value="item.value" v-for="(item,index) in serviceChargeUnitList" :key="index"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </el-form>
                    </div>
                    </div>

                    <div v-if="chargeRuleFormDrawer.data.electricChargeType == 1 && chargeRuleItemList.length > 0">
                        <i-title title="时间区间选择器"></i-title>

                        <i-charge-rules-selector
                        :time-span-options="timeSpanOptions"
                        :charge-rule-config="chargeRuleItemList"
                        :charge-rule-config-props="{timeSpans:'timeSpans',usePowerPeriodType:'chargeTimeSpanType'}"
                        ></i-charge-rules-selector>
                    </div>

                    
    
                    
                    
                </el-form>
            </div>

            <template slot="footer">
                <el-button type="primary" size="small" @click="saveOrUpdateChargeRule">{{ buttonText.submit }}</el-button>
                <el-button  size="small" @click="chargeRuleFormDrawer.hasOpen = false">{{ buttonText.cancel }}</el-button>
            </template>

        

        </i-drawer>


    </div>
</template>

<script>
import {defaultPageSizes,defaultPage} from "@/config/page-settings";
import {chargeRuleTableSettings} from '@/config/table-settings'
import ITable from '@/components/i-table/ITable.vue'
import {obtainPositionButtons,showData} from '@/utils/power'
import {loadCurrentPageTheButtons} from '@/api/system/menu'
import {positions} from '@/config/button-position-settings'
import IDrawer from '@/components/i-drawer/IDrawer.vue'
import ITitle from '@/components/i-title/ITitle.vue'
import IChargeRulesSelector from '@/components/i-charge-rules-selecteor/IChargeRulesSelector.vue'
import {timeSpanOptions} from '@/config/time-span-settings'

import {loadChargeRulePaging,saveOrUpdateChargeRule,loadChargeRuleItemList,deleteChargeRuleById} from '@/api/business/charge-rule'
import {loadDataDictByCode} from '@/api/system/data-dict'
import {listOrgSelect} from '@/api/system/organization'
import {rules} from '@/utils/rules'
export default{
    components:{
        ITable,
        IDrawer,
        ITitle,
       IChargeRulesSelector
    },
    data(){

       
         return{
           defaultPageSizes,
            obtainPositionButtons,
            positions,
            buttons:[],
            config:{
                border:true,
                columns:[
                    ...chargeRuleTableSettings
                ]
            },
            page:{
               ...defaultPageSizes
            },
            search:{
                values:{}
            },
            showPage:false,
            chargeRuleFormDrawer:{//计费抽屉组件相关
                hasOpen:false,
                data:{}
            },
            orgList:[],//机构列表
            chargeTypeList:[],//计费规则
            chargeRuleItemList:[],
            chargeTimeSpanTypeList:[],
            serviceChargeUnitList:[],
            timeSpanOptions

        }
    },
    methods:{
        operateHandler(code,row){

            switch(code){
                case 'add':
                    this.chargeRuleFormDrawer.hasOpen = true;
                    this.chargeRuleFormDrawer.title = this.chargeRuleFormDrawerText.addTitle;
                break;
                case 'update':
                    this.getChargeItemList(row.id);
                    this.chargeRuleFormDrawer.hasOpen = true;
                    this.chargeRuleFormDrawer.title = this.chargeRuleFormDrawerText.updateTitle;
                    this.chargeRuleFormDrawer.data = JSON.parse(JSON.stringify(row));
                    break;
                case 'delete':
                    this.deleteChargeRuleHandler(row);
                    break;
            }

        },
        closeDrawerHandler(){//关闭抽屉时的回调
            this.$refs['chargeRuleFormDrawerRef'].resetFields();
            if(this.chargeRuleItemList.length>0){
                this.$refs['chargeRuleItemsRef'].forEach(ref=>ref.resetFields());
            }
            this.chargeRuleFormDrawer.data = {};
            this.chargeRuleItemList = [];
            
            

        },
        async getCurrentPageButtons(){//获取当前页按钮权限
            const {data} = await  loadCurrentPageTheButtons({route:this.$route.path});
            // console.log(buttons);
            this.buttons = data;
            //获取到了按钮数据，才开始渲染
            this.showPage = true;

            this.changePagingHandler();
        
        },
        async changePagingHandler(){//获取分页数据
            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.resultMessages.unauthorizedView);

                return;
            }

            const {data} = await loadChargeRulePaging({
                current:this.page.current,
                size:this.page.size,
                name:this.search.values.name
           });

           this.page = data;

        },
        async searchHandler(){//搜索
            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.resultMessages.unauthorizedView);

                return;
            }

           const {data} = await loadChargeRulePaging({
                current:1,
                size:this.page.size,
                name:this.search.values.name
           });

           this.page = data;
        },
         saveOrUpdateChargeRule(){//保存或修改规则内容

            let refList = [];

            refList.push(this.$refs['chargeRuleFormDrawerRef'].validate());

           if(this.chargeRuleItemList.length>0){

            refList = [...refList,...this.$refs['chargeRuleItemsRef'].map(v=>v.validate())];
           }
            console.log('refs',refList)
            
            Promise.all(refList).then( async ()=>{

                if(this.chargeRuleFormDrawer.data.electricChargeType === 1){

                    if(this.chargeRuleItemList.length === 0 ){
                        this.$message.error(this.form.chargeRuleItemList.checkFail.required);
                        return;
                    }

                    let count = 0;
                    //获取时区数量
                    this.chargeRuleItemList.forEach(e=> count += e.timeSpans.length);
                    //所有时区必须选满，如果没有选满则表单校验不成功
                    if(count<48){
                        this.$message.error(this.form.selection.checkFail.required);
                        return;
                    }
                }

                
                let data = {...this.chargeRuleFormDrawer.data};
                data.chargeRuleItemList = this.chargeRuleItemList;
                // console.log('all',data);
                const result = await saveOrUpdateChargeRule({
                    ...data
                });

                if(result&&result.code === 200){
                    this.$message.success(this.resultMessages.success);
                    this.changePagingHandler();
                }

                this.chargeRuleFormDrawer.hasOpen = false;

            }).catch((e)=>{
                console.log('校验失败~',e)
            });


            

        },
        addChargeTimeSpanRule(){

            if(this.chargeRuleItemList.length >=4){

                this.$message.warning('规则数量不能超过4个')

                return;

            }
            this.chargeRuleItemList.push({
                chargeTimeSpanType:this.chargeTimeSpanTypeList[0].value,
                timeSpans:[]
            })
        },
        deleteChargeRuleItemHandler(index,item){

            this.$nextTick(()=>{
                this.chargeRuleItemList.splice(index,1);
            })
           

           
        },
        async getChargeTimeSpanTypeList(){
            const {data} = await loadDataDictByCode({
                code:'time_scope_type'
            });

            this.chargeTimeSpanTypeList = data;
        },
        async getServiceChargeUnitList(){
            const {data} = await loadDataDictByCode({
                code:'service_charge_unit'
            });

            this.serviceChargeUnitList = data;
        },
        async getChargeTypeList(){
            const {data} = await loadDataDictByCode({
                code:'electric_charge_type'
            });

            this.chargeTypeList = data;
        },
        async getOrgList(){
            const {data} = await listOrgSelect();

            this.orgList = data;
        },
        async getChargeItemList(chargeRuleId){
            
            const {data} = await loadChargeRuleItemList({
                id:chargeRuleId
            });

            this.chargeRuleItemList = data;
        },
        async deleteChargeRuleHandler(data){
            this.$confirm(this.confirmPopupText.message,this.confirmPopupText.title,{
                confirmButtonText: this.buttonText.confirm,
                cancelButtonText: this.buttonText.cancel,
                type: 'warning',
                center: true
            }).then(async ()=>{
                
                const result = await deleteChargeRuleById({
                    id:data.id
                });
                if(result&&result.code === 200){
                    this.changePagingHandler();
                    
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.resultMessages.cancelDelete
                });
            });
        }

    },
    mounted(){
        this.getCurrentPageButtons();
        this.getChargeTimeSpanTypeList();
        this.getServiceChargeUnitList();
        this.getChargeTypeList();
        this.getOrgList();
    },
    computed:{
        field(){
            const prefix = 'chargeRulePage.field';

            return{
                id:this.$t(`${prefix}.id`),
                name:this.$t(`${prefix}.name`),
                orgName:this.$t(`${prefix}.orgName`),
                electricChargeTypeName:this.$t(`${prefix}.electricChargeTypeName`),
                serviceCharge:this.$t(`${prefix}.serviceCharge`),
                unit:this.$t(`${prefix}.unit`),
                electricCharge:this.$t(`${prefix}.electricCharge`),
                timeSpanType:this.$t(`${prefix}.timeSpanType`),
                remarks:this.$t(`${prefix}.remarks`),
                creatorName:this.$t(`${prefix}.creatorName`),
                createTime:this.$t(`${prefix}.createTime`),
                modifierName:this.$t(`${prefix}.modifierName`),
                modifyTime:this.$t(`${prefix}.modifyTime`),
                operate:this.$t(`${prefix}.operate`),
                rule:this.$t(`${prefix}.rule`)
            }
        },
        resultMessages(){

            const prefix = 'public.resultMessages';
            return{
                unauthorizedView:this.$t(`${prefix}.unauthorizedView`),
                cancelDelete:this.$t(`${prefix}.cancelDelete`),
                success: this.$t(`${prefix}.success`),
                fail: this.$t(`${prefix}.fail`),
            }
        },
        form(){

            const prefix = 'chargeRulePage.form';
            return{
                id:{
                    placeholder:this.$t(`${prefix}.id.placeholder`)
                },
                name:{
                    placeholder:this.$t(`${prefix}.name.placeholder`)
                },
                orgId:{
                    placeholder:this.$t(`${prefix}.orgId.placeholder`)
                },
                remarks:{
                    placeholder:this.$t(`${prefix}.orgId.placeholder`)
                },
                electricChargeType:{
                    placeholder:this.$t(`${prefix}.electricChargeType.placeholder`)
                },
                electricCharge:{
                    placeholder:this.$t(`${prefix}.electricCharge.placeholder`)
                },
                serviceCharge:{
                    placeholder:this.$t(`${prefix}.serviceCharge.placeholder`)
                },
                unit:{
                    placeholder:this.$t(`${prefix}.unit.placeholder`)
                },
                timeSpanType:{
                    placeholder:this.$t(`${prefix}.timeSpanType.placeholder`)
                },
                selection:{
                    checkFail:{
                        required:this.$t(`${prefix}.selection.checkFail.required`)
                    }
                },
                chargeRuleItemList:{
                    checkFail:{
                        required:this.$t(`${prefix}.chargeRuleItemList.checkFail.required`)
                    }
                }
            }
        },
        buttonText(){
            const prefix = 'public.button';
            return{
                submit:this.$t(`${prefix}.submit`),
                confirm:this.$t(`${prefix}.confirm`),
                cancel:this.$t(`${prefix}.cancel`),
                search:this.$t(`${prefix}.search`),
                delete:this.$t(`${prefix}.delete`),
                addTimeSpanRule:this.$t(`chargeRulePage.button.addTimeSpanRule`)
            }
        },
        chargeRuleFormDrawerText(){

            const prefix = 'chargeRulePage.chargeRuleFormDrawer'

            return{
                addTitle:this.$t(`${prefix}.addTitle`),
                updateTitle:this.$t(`${prefix}.updateTitle`),
                baseInfoTitle:this.$t(`${prefix}.baseInfoTitle`),
                configTitle:this.$t(`${prefix}.configTitle`),
            }
        },
        confirmPopupText(){
            const prefix = 'chargeRulePage.confirmPopup'
            return{
                title:this.$t(`${prefix}.title`),
                message:this.$t(`${prefix}.message`)
            }
        },
        chargeRuleFormRules(){
            const prefix = 'chargeRulePage.form';

            //金额判断是否为正数,判断金额是否为保留两位的小数且小于100个w
            const checkMoney = (rule,value,callback)=>{

                // console.log('value',value);

                if(!rules.zFloat3.test(value)){

                    return callback(new Error(this.$t(`${prefix}.charge.checkFail.checkMoney`)));
                }

                return callback();
            }

            //判断是不是三个

            return{
                name:[{ required: true, message: this.$t(`${prefix}.name.checkFail.required`), trigger: 'blur' }],
                orgId:[{ required: true, message: this.$t(`${prefix}.orgId.checkFail.required`), trigger: 'change' }],
                electricChargeType:[{ required: true, message: this.$t(`${prefix}.electricChargeType.checkFail.required`), trigger: 'change' }],
                electricChargeByYuan:[{ required: true, message: this.$t(`${prefix}.electricCharge.checkFail.required`), trigger: 'blur' },{validator:checkMoney,trigger:'blur'}],
                serviceChargeByYuan:[{ required: true, message: this.$t(`${prefix}.serviceCharge.checkFail.required`), trigger: 'blur' },{validator:checkMoney,trigger:'blur'}],
                unit:[{ required: true, message: this.$t(`${prefix}.unit.checkFail.required`), trigger: 'change' }]

            }

        },
        chargeRuleItemFormRules(){
            const prefix = 'chargeRulePage.form';
            //金额判断是否为正数,判断金额是否为保留两位的小数且小于100个w
            const checkMoney = (rule,value,callback)=>{
                if(!rules.zFloat3.test(value)){

                    return callback(new Error(this.$t(`${prefix}.charge.checkFail.checkMoney`)));
                }

                return callback();
            }
            return{
                chargeTimeSpanType:[{ required: true, message: this.$t(`${prefix}.timeSpanType.checkFail.required`), trigger: 'change' }],
                electricChargeByYuan:[{ required: true, message: this.$t(`${prefix}.electricCharge.checkFail.required`), trigger: 'blur' },{validator:checkMoney,trigger:'blur'}],
                serviceChargeByYuan:[{ required: true, message: this.$t(`${prefix}.serviceCharge.checkFail.required`), trigger: 'blur' },{validator:checkMoney,trigger:'blur'}],
                unit:[{ required: true, message: this.$t(`${prefix}.unit.checkFail.required`), trigger: 'change' }]
            }
        }
    }
}

</script>

<style lang="scss">
.charge-rule_wrapper{
    height: 100%;
    background-color: var(--bg-theme-page-color);
    .el-textarea__inner{ //element-ui 文本框 样式
        height: 200px;
        resize: none;
    }
    .charge-rule-form-drawer{
        height: 90%;
        overflow-y: auto;
    }
    .charge-rule-form-drawer::-webkit-scrollbar{
        display: none;
    }
}
</style>