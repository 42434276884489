import {get, post} from '@/utils/request'


/**
 * 充电规则分页查询接口
 * @param {*} data 
 * @returns 
 */
export function loadChargeRulePaging(data){


    return get({
        url:'/charge-rule/load-paging',
        params:{...data}
    })


}

/**
 * 新增或修改充电规则
 * @param {*} data 
 * @returns 
 */
export function saveOrUpdateChargeRule(data){

    return post({
        url:'/charge-rule/save-or-update',
        data:{...data}
    });
}

/**
 * 加载充电规则项
 * @param {*} data 
 * @returns 
 */

export function loadChargeRuleItemList(data){

    return get({
        url:'/charge-rule/load-rule-items',
        params:{...data}
    })
}

/**
 * 通过充电规则编号删除充电规则
 * @param {*} data 
 * @returns 
 */
export function deleteChargeRuleById(data){

    return post({
        url:'/charge-rule/delete-by-id',
        data:{...data}
    })
}

/**
 * 获取充电规则选项列表
 * @param {*} data 
 * @returns 
 */
export function loadChargeRuleOptionList(data){

    return get({
        url:'/charge-rule/load-option-list',
        params:{...data}
    });
}