
import {get} from "@/utils/request";


/**
 * 平台用户充值订单多条件分页查询
 * @param data
 * @returns {Promise<unknown>}
 */
export function loadClientRechargeOrderPaging(data){

    return get({
        url:'/order/client-user-recharge/load-paging',
        params:{
            ...data
        }
    })

}

/**
 * 获取订单状态列表参数
 * @returns {Promise<unknown>}
 */
export function loadClientRechargeOrderStatusList(){

    return get({
        url:'/order/client-user-recharge/status-list'
    });
}
