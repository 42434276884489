<template>
    <div class="global-administrative-divisions_wrapper loading-parent" >

        <div class="global-administrative-divisions_main">
            <div class="global-administrative-divisions_main_left">
              <i-title :title="title.allAdministrativeDivisions" class="global-administrative-divisions_main_left_title"></i-title>
              <el-input
              :placeholder="form.search.placeholder"
              v-model="filterText"
              size="small"
              clearable
              >
              </el-input>
              <div class="global-administrative-divisions_main_left_main">
                <el-tree 
                :data="globalAdministrativeDivisionsList" 
                :props="{label:'name',children:'childNode'}"
                @node-click="clickNodeHandler"
                
                ref="tree"
                :filter-node-method="filterNode"
                >

                </el-tree>
              </div>
              

            </div>
            <div class="global-administrative-divisions_main_right">
                <i-title :title="title.administrativeDivisionsDetails"></i-title>
                <el-descriptions   :column="2" border class="global-administrative-divisions_descriptions" v-show="globalAdministrativeDivisions">
                  <el-descriptions-item :label="field.parentId">{{ globalAdministrativeDivisions.parentId }}</el-descriptions-item>
                  <el-descriptions-item :label="field.parentName">{{ globalAdministrativeDivisions.parentName }}</el-descriptions-item>
                  <el-descriptions-item :label="field.name">{{ globalAdministrativeDivisions.name }}</el-descriptions-item>
                  <el-descriptions-item :label="field.code">{{ globalAdministrativeDivisions.code }}</el-descriptions-item>
                  <el-descriptions-item :label="field.remarks">{{ globalAdministrativeDivisions.remarks }}</el-descriptions-item>
                </el-descriptions>
                <div class="global-administrative-divisions_descriptions_footer">
                  <div class="global-administrative-divisions_descriptions_footer_button">
                    <!-- <el-button size="small" type="primary">新增</el-button>
                    <el-button size="small" type="primary">修改</el-button>
                    <el-button size="small" type="primary">删除</el-button> -->
                  </div>
                 
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ITitle from '@/components/i-title/ITitle.vue'
import { Loading } from 'element-ui'
import {loadTree} from '@/api/system/global-administrative-divisions'

export default{
  components: { ITitle },
    data(){

        return{
            globalAdministrativeDivisions:{},
            globalAdministrativeDivisionsList:[],
            filterText:'',
            show : false
        }
    },
    methods:{
      clickNodeHandler(data){

        // console.log('data',data);

        this.globalAdministrativeDivisions = data;

      },
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      async getGlobalAdministrativeDivisionsTree(){
        const loadingInstance = Loading.service({
          target:'.loading-parent',
          background:'#fff',
          customClass:'loading-main',
          text:this.$t('public.loadingMessage.resourceLoading'),
          body:false
        });

        const {data} = await loadTree();
        // console.log('data',data);

        if(data){
          this.globalAdministrativeDivisionsList = data;
          this.globalAdministrativeDivisions = data[0];
        }

        loadingInstance.close();

        
      }
    },
    mounted(){

      this.getGlobalAdministrativeDivisionsTree();
    },
    computed:{
        field(){
          const prefix = 'globalAdministrativeDivisionsPage.field';
          return{
           
            parentId: this.$t(`${prefix}.parentId`),
            parentName: this.$t(`${prefix}.parentName`),
            name: this.$t(`${prefix}.name`),
            code: this.$t(`${prefix}.code`),
            remarks: this.$t(`${prefix}.remarks`),
          }
        },
        title(){
          const prefix = 'globalAdministrativeDivisionsPage.title';
          return{
            allAdministrativeDivisions:this.$t(`${prefix}.allAdministrativeDivisions`),
            administrativeDivisionsDetails:this.$t(`${prefix}.administrativeDivisionsDetails`)
          }
        },
        form(){
          const prefix = 'globalAdministrativeDivisionsPage.form';
          return{
          
            search:{
              placeholder:this.$t(`${prefix}.search.placeholder`)
            }
          }
        }
        
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    }
}

</script>

<style lang="scss">
.global-administrative-divisions_wrapper{
    height: 100%;

    //loading图标
    // .el-loading-spinner .el-loading-text{
    // color: #000;
    // }
    // .el-loading-spinner .path{
    // stroke: #000;
    // }
    .global-administrative-divisions_main{
        // width: 100%;
        display: flex;
        gap: 10px;
        height: 100%;
        .global-administrative-divisions_main_left{
            padding: 10px;
            width: 35%;
            background-color:var(--bg-theme-page-color);
             position: relative;
            //  height: 100%;
             .global-administrative-divisions_main_left_title{
                // padding-bottom: 20px;
                top: 0;
             }
            .global-administrative-divisions_main_left_main{
              overflow: auto;
              
              height: 85%;
            }
            .el-input{
              padding-top: 10px;
              padding-bottom: 10px;
            }
            
        }
        .global-administrative-divisions_main_left_main::-webkit-scrollbar{
          display: none;
        }
        .global-administrative-divisions_main_right{
            background-color:var(--bg-theme-page-color);
            width: 65%;
            padding: 10px;
            position: relative;
            .global-administrative-divisions_descriptions{
              padding-top: 10px;
            }
            .global-administrative-divisions_descriptions_footer{
             
              position: absolute;
              bottom: 0;
              background-color: var(--bg-theme-page-color);
              // width: 100%;
              overflow: hidden;
              padding-bottom: 20px;
              .global-administrative-divisions_descriptions_footer_button{
                display: flex;
                justify-content: center;
                
              }
            }
            
        }

    }
}

</style>