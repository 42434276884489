
import {get, post} from '@/utils/request'

/**
 * 获取当前角色拥有的菜单权限
 * 
 * */ 
export function loadLeftMenu(){

    return get({
        url:'/menu/load-left-menu'
    });

}


/**
 * 获取当前角色拥有的菜单权限
 * @param {*} data 请求参数
 * */ 
export function loadCurrentPageTheButtons(data){

  
    return get({
        url:'/menu/load-current-page-the-buttons',
        params:{...data}
    });

}

/**
 * 菜单列表分页查询
 * @param {*} data 请求参数
 * 
 * */ 
export function loadMenuPaging(data){


    return get({
        url:'/menu/load-paging',
        params:{...data}
    });
}

export function lazyLoadMenuChildNode(data){
    return get({
        url:'/menu/lazy-load-child-node',
        params:{...data}
    });
}
/**
 * 删除菜单
 * @param {*} data 请求参数 
 * */ 
export function deleteMenuById(data){
    return post({
        url:'/menu/delete-by-id',
        data:{...data}

    });
}

/**
 * 
 * 加载所有的菜单
 * @param {*} data  请求参数
 * 
 * */ 

export function loadAllMenuList(){

    return get({
        url:'/menu/load-all-list',
        
    });

}

/**
 * 保存或者修改菜单
 * @param {*} data 
 * @returns 
 */
export function saveOrUpdateMenu(data){

    return post({
        url:'/menu/save-or-update',
        data:{
            ...data
        }
    });
}

/**
 * 判断这个编码是否存在
 * @param {*} data 
 * @returns 
 */
export function existsByCode(data){
    return get({
        url:'/menu/exists/code',
        params:{
            ...data
        }
    });
}


/**
 * 判断这个路由是否已经存在了
 * @param {*} data 
 * @returns 
 */
export function existsByRoute(data){
    return get({
        url:'/menu/exists/route',
        params:{
            ...data
        }
    });
}

/**
 * 通过数据权限获取菜单树
 */
export function loadMenuTreeByDataScope(){
    
    return get({
        url:'/menu/load-by-data-scope'
    })

}

