<template>
    <div class="i-left-menu">
        <el-menu 
        class="i-left-menu_sidebar"
        :default-active="defaultActive"
        router
        :collapse="collapse"
        unique-opened
        collapse-transition
        >
            <i-left-menu-item 
            :data="dataList"
            :child-node-key="childNodeKey"
            :route-key="routeKey"
            :name-key="nameKey"
            :icon-key="iconKey"
            ></i-left-menu-item>
          
        </el-menu>

    </div>
</template>

<script>

import ILeftMenuItem from './i-left-menu-item/ILeftMenuItem.vue';

export default{
    data(){

        return{
            

        }   

    },
    props:{
        dataList:{
            type:Array,
            default:[]
        },
        defaultActive:{
            type:String,
            default:''
        },
        collapse:{
            type:Boolean,
            default:false
        },
        childNodeKey:{
            type:String,
            default:()=>'childNode'
        },
        routeKey:{
            type:String,
            default:()=>'route'
        },
        nameKey:{
            type:String,
            default:()=>'name'
        },
        iconKey:{
            type:String,
            default:()=>'icon'
        }
    },
    methods:{

    },
    components:{
        ILeftMenuItem
    }
}

</script>

<style lang="scss">
.i-left-menu{
    // 解决侧边栏折叠卡顿的问题
    .i-left-menu_sidebar:not(.el-menu--collapse) {
        width: 240px;
        height:100%;
        //匹配icon图标
        [class^=iconfont]{
            margin-right: 10px;
            // margin-left: 10px;
            //margin-right: 5px;
            width: 24px;
            text-align: center;
            font-size: 18px;
            vertical-align: middle;
            
           
        }
    
    }
    .el-menu--collapse  .el-submenu__title span{
        display: none;
    }
    // 使用自定义图标时，收索菜单 箭头图标依然存在，通过一下css配置隐藏
    .el-menu--collapse  .el-submenu__title .el-icon-arrow-right{
        display: none;
    }

    .el-menu--collapse{
        .el-menu-item [class^=iconfont],.el-submenu .el-submenu__title [class^=iconfont]{
            // margin-left: 5px;
            margin: 0 auto;
            width: 24px;
            text-align: center;
            font-size: 18px;
            vertical-align: middle;
            
        }

    }



}

.el-menu--vertical{
   .el-menu-item [class^=iconfont]+span{
        margin-left: 10px;
   }
   .el-submenu__title [class^=iconfont]+span{
        margin-left: 10px;
   }
}


</style>