<template>
    <div class="i-drawer-wrapper">
        <el-drawer 
        v-bind="$attrs"
        :visible.sync = "visible"
        :before-close="beforeCloseHandler"
       v-on="$listeners"
        >
            <template slot="title">
                <slot name="title"></slot>
            </template>
            <div class="i-drawer">
                <template>
                    <slot></slot>
                </template>
                <div class="i-drawer-bottom">
                    <slot name="footer"></slot>
                </div>
            </div>

        </el-drawer>

    </div>
</template>

<script>
export default{
    props:{
        visible:{
            type:Boolean,
            default:()=>false
        }
    },
    methods:{
        beforeCloseHandler(){
            //更新父类数据
            this.$emit('update:visible',false);
        }
    }
}

</script>

<style lang="scss">
.i-drawer{
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    
    .i-drawer-bottom{
        position: absolute;
        bottom: 0;
        padding-bottom: 20px;
    }
}


</style>