<template>
    <div class="settings-wrapper">
        <div class="settings-main">
            <el-form label-width="100px" class="setting-form">
                <el-form-item label="系统名称：">
                    <el-input  size="medium"  v-model="settings.name" placeholder="系统名称" />

                </el-form-item>
                <el-form-item label="版本号：">
                    <el-input  size="medium"  v-model="settings.version" placeholder="系统名称" />
                </el-form-item>

                <el-form-item label="系统主题：">
                    <el-radio-group v-model="settings.theme">
                        <el-radio :label="theme.code" v-for="(theme,index) in themes" :key="index">{{ theme.name }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item >
                    <el-button type="primary" size="small" @click="changeTheme">确定更改</el-button>
                </el-form-item>

            </el-form>

        </div>
    </div>
</template>
<script>
import {themes} from '@/config/theme-settings'


export default{
    data(){

        return{
            countryRegion:[],
            settings:{
                name:'',
                version:'',
                theme:'',
                
            },
            themes:[
                ...themes
                
            ]
        }
    },
    methods:{
        initTheme(){
            
            let settings = localStorage.getItem('settings');
            if(settings === null||settings===undefined){
                this.settings.theme = this.themes[0].code;
                this.settings.name = 'Charging Station Admin';
                this.settings.version = 'V1.0.0.1';
                localStorage.setItem('settings',JSON.stringify(this.settings));
                return;
            }
            settings = JSON.parse(settings);
            this.settings = settings;
            
        },
        changeTheme(){
            localStorage.setItem('settings',JSON.stringify(this.settings));
            location.reload();
        },


    },
    created(){
        this.initTheme();

    }

}


</script>

<style lang="scss">

.settings-wrapper{
    display: flex;
    justify-content: center;
    background-color: #fff;
    height: 100%;
    overflow: hidden;
    .settings-main{
        // padding-top: 20px;
        width: 96%;
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        .setting-form{
            width: 500px;
            
        }
    }
}

</style>