<template>
    <div>
        <template v-for="(item, index) in data">
            <!-- 因为有子集和无子集渲染html标签不一样，所以要分为两种情况
                情况一：有子集的情况：                         -->
            <el-submenu
                :key="index"
                :index="item[routeKey]"
                v-if="item[childNodeKey]&&item[childNodeKey].length > 0"
            >
                <template slot="title">
                    <i :class="item[iconKey]"></i>
                    <span>{{ item[nameKey] }}</span>
                </template>
                <i-left-menu-item :data="item[childNodeKey]"></i-left-menu-item>
                
            </el-submenu>
            <!-- 情况二：没子集的情况： -->
            <el-menu-item :key="item[routeKey]" v-else :index="item[routeKey]">
                <i :class="item[iconKey]"></i>
                <span slot="title">{{ item[nameKey] }}</span>
            </el-menu-item>
        </template>
    </div>
</template>

<script>
export default {
    name:'i-left-menu-item',
    props:{
        data:{
            type:Array,
            default:[]
        },
        childNodeKey:{
            type:String,
            default:()=>'childNode'
        },
        routeKey:{
            type:String,
            default:()=>'route'
        },
        nameKey:{
            type:String,
            default:()=>'name'
        },
        iconKey:{
            type:String,
            default:()=>'icon'
        }
    }

}
</script>

<style scoped>
.el-menu--collapse  .el-submenu__title span{
    display: none;
  }

</style>