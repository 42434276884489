<template>
    <div class="i-icon-library">
        <div class="i-icon-library_wrapper">
            <div class="i-icon-library_item" v-for="(icon,index) in icons" :key="index" @click="selectIconHandle(fontFamily+' '+cssPrefixText+icon[iconKey])">
                <span :class="fontFamily+' '+cssPrefixText+icon[iconKey]"></span>
                <p>{{ cssPrefixText+icon[iconKey] }}</p>
            </div>

        </div>

    </div>
</template>

<script>

export default{
    data(){

        return{

        }
    },
    props:{
        icons:{
            type:Array,
            default:()=>[]
        },
        iconKey:{
            type:String,
            default:()=>'font_class'
        },
        fontFamily:{
            type:String,
            default:()=>''
        },
        cssPrefixText:{
            type:String,
            default:()=>'icon-'
        }

    },
    methods:{
        selectIconHandle(iconClass){
            // console.log('选中了',iconClass)
            this.$emit('select-icon',iconClass);
        }
    }
}

</script>

<style lang="scss">
.i-icon-library{
    width: 100%;
    // height: 600px;

 
    .i-icon-library_wrapper{

        display: flex;
        flex-wrap: wrap;
        grid-gap: 10px;
    }
    .i-icon-library_item{
        width: 8%;
        font-size: 12px;
        text-align: center;
        p{
          
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            white-space: nowrap;
            width: 100%;
            -webkit-line-clamp: 1;
            //-webkit-box-orient:vrtical;
            
        }
    }
    .i-icon-library_item:hover{
        cursor: pointer;
        // transform: scale(1.4);
    }
}
</style>