import {get, post} from '@/utils/request';

const prefix = '/charge-pile';

/**
 * 查询充电桩分页数据
 * @param params 查询条件
 */
export const chargePilePaging = (params = {}) => {
    return get({url: `${prefix}/list-page`, params: {...params}});
}

/**
 * 新增或修改充电桩
 * @param params 充电桩实体数据
 */
export const saveOrUpdateChargePile = (params = {}) => {
    return post({url: `${prefix}/save-or-update`, data: {...params}});
}

/**
 * 删除充电桩
 * @param params 充电桩id
 */
export const deleteChargePile = (params = {}) => {
    return post({url: `${prefix}/delete`, data: {...params}});
}

/**
 * 查询充电桩协议
 */
export const listChargePileProtocolType = () => {
    return get({url: `${prefix}/protocol-type`});
}

/**
 * 充电桩设置计费规则
 * @param params 充电桩ID和计费规则ID
 */
export const chargePileSetChargeRule = (params = {}) => {
    return post({url: `${prefix}/set-charge-rule`, data: {...params}});
}
