<template>
    <div class="data-main-wrapper">
        <i-table
        v-if="showPage"
        ref="iTableRef"
        :config="config"
        :page="page"
        :load="lazyLoadHandler"
        :page-sizes="defaultPageSizes"
        @change-page="changePagingHandler"
        >

            <!-- 搜索栏 -->
            <template slot="search-condition">
                <el-form :model="search.values" :inline="true" :rules="searchRules" ref="searchRef">
                    <el-form-item :label="field.id+':'" prop="id" ref="menuIdRef">
                        <el-input @clear="$refs['menuIdRef'].clearValidate()" size="small" v-model="search.values.id" :placeholder="form.id.placeholder" clearable />
                    </el-form-item>
                    <el-form-item :label="field.name+':'">
                        <el-input size="small" v-model="search.values.name" :placeholder="form.name.placeholder" clearable />
                    </el-form-item>
                </el-form>

            </template>
            <!-- 搜索按钮 -->
            <template slot="search-button">
                <el-button type="primary"  size="small" @click="searchHandler" icon="el-icon-search">{{ buttonText.search }}</el-button>
            </template>

            <!-- 表头 -->
            <template slot="header" slot-scope="scope">
                {{ field[scope.column.property] }}
            </template>

            <!-- 左侧功能栏 -->
            <template slot="overall-left">
                <el-button v-for="(overallLeftButton,index) in obtainPositionButtons(buttons,positions.left)" :key="index" type="primary"  size="small" :icon="overallLeftButton.icon" @click="operateHandler(overallLeftButton.code)">{{overallLeftButton.name}}</el-button>
            </template>

            <!-- 右侧工作栏 -->
            <template slot="overall-right">
                <el-button v-for="(overallRightButton,index) in obtainPositionButtons(buttons,positions.right)" :key="index"   size="small" :icon="overallRightButton.icon" @click="operateHandler(overallRightButton.code)">{{overallRightButton.name}}</el-button>
            </template>

            <template slot="row_icon" slot-scope="scope">

                <i :class="scope.row[scope.column.property]"></i>

            </template>

            <template slot="row_operate" slot-scope="scope">
                <el-button
                type="text"
                :icon="rowButton.icon"
                size="mini"
                v-for="(rowButton,index) in obtainPositionButtons(buttons,positions.row) "
                :key="index"
                @click="operateHandler(rowButton.code,scope.row)"
                >
                    {{ rowButton.name  }}
                </el-button>
            </template>


        </i-table>

        <i-drawer
        :visible.sync="menuFormDrawer.hasOpen"
        :title="menuFormDrawer.title"
        @close="menuFormDrawer.data = {};$refs['menuFormRef'].resetFields();"
        @open="openMenuFromDrawerHandler"
        >
            <el-form
            :model="menuFormDrawer.data"
            :rules="menuFormRules"
            ref="menuFormRef"
            label-width="120px"
            >
                <el-form-item :label="field.name+':'" prop="name">
                    <el-input size="small" v-model="menuFormDrawer.data.name" :placeholder="form.name.placeholder" clearable />
                </el-form-item>
                <el-form-item :label="field.icon+':'" >

                    <div  v-if="menuFormDrawer.data.icon" style="display: flex;grid-gap: 10px;">
                        <div class="system-menus_menu_form_icon i-frame" @click="iconsLiraryDialog.hasOpen = true">
                            <span :class="menuFormDrawer.data.icon"  ></span>
                        </div>
                        <el-button size="small" class="system-menus_delete_icon"  type="text" @click="menuFormDrawer.data.icon = ''">{{ buttonText.delete }}</el-button>
                    </div>
                    <div v-else class="system-menus_menu_form_icon i-frame theme-color" @click="iconsLiraryDialog.hasOpen = true">
                        <span  class="iconfont icon-add "></span>

                    </div>




                </el-form-item>
                <el-form-item :label="field.code+':'" prop="code">
                    <el-input size="small" v-model="menuFormDrawer.data.code" :placeholder="form.code.placeholder" clearable />
                </el-form-item>
                <el-form-item :label="field.route+':'"  prop="route">
                    <el-input size="small" v-model="menuFormDrawer.data.route" :placeholder="form.name.placeholder" clearable />
                </el-form-item>
                <el-form-item :label="field.typeName+':'"  prop="type">
                    <el-radio
                    v-model="menuFormDrawer.data.type"
                    :label="type.value" v-for="(type,index) in menuTypes"
                    :key="index"
                    >
                        {{type.name}}
                    </el-radio>


                </el-form-item>
                <el-form-item :label="field.position+':'"  v-if="menuFormDrawer.data.type === 1" prop="position">
                    <el-select size="small" v-model="menuFormDrawer.data.position" :placeholder="form.position.placeholder" clearable>
                      <el-option :label="opsition.name" :value="opsition.value" v-for="(opsition,index) in buttonPositions" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="field.parent+':'" >
                    <el-select
                        v-model="menuFormDrawer.data.parentId"
                        :placeholder="form.parentId.placeholder"
                        clearable
                        filterable
                        size="small"
                    >
                        <el-option
                        :disabled="menuFormDrawer.data.id === menu.id"
                        :label="menu.name"
                        :value="menu.id"
                        v-for="(menu,index) in parentMenuList"
                        :key="index"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item :label="field.sort+':'"  prop="sort">
                    <el-input size="small" type="number" v-model="menuFormDrawer.data.sort" :placeholder="form.sort.placeholder" clearable />
                </el-form-item>
                <el-form-item :label="field.remarks+':'" >
                    <el-input type="textarea" size="small" v-model="menuFormDrawer.data.remarks" :placeholder="form.remarks.placeholder"></el-input>
                </el-form-item>

                <el-form-item :label="field.openNewWindowName+':'" >
                    <el-switch v-model="menuFormDrawer.data.openNewWindow" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>

            </el-form>

            <template slot="footer">
                <el-button size="small" type="primary" @click="saveOrUpdate">{{ buttonText.submit }}</el-button>
                <el-button size="small"  @click="menuFormDrawer.hasOpen = false">{{ buttonText.cancel }}</el-button>
            </template>


        </i-drawer>

        <i-dialog
        :visible.sync="iconsLiraryDialog.hasOpen"
        title="图标库"
        width="70%"
        >
            <div class="system-menus_icon-library">
                <i-icon-library
                :icons="icons.glyphs"
                :css-prefix-text="icons.css_prefix_text"
                :fontFamily="icons.font_family"
                icon-key="font_class"
                @select-icon="selectMenuIcon"
                >
                </i-icon-library>
            </div>
        </i-dialog>


    </div>
</template>

<script>

import {defaultPageSizes,defaultPage} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable'
import {obtainPositionButtons,showData} from '@/utils/power'
import IDrawer from '@/components/i-drawer/IDrawer'
import IDialog from '@/components/i-dialog/IDialog'
import IIconLibrary from '@/components/i-icon-library/IIconLibrary.vue'
import {positions} from '@/config/button-position-settings'
import {menuTableSettings} from '@/config/table-settings'
import icons from '@/assets/fonts/iconfont.json'
import {loadCurrentPageTheButtons,loadMenuPaging,lazyLoadMenuChildNode,loadAllMenuList,saveOrUpdateMenu,deleteMenuById,existsByCode,existsByRoute} from '@/api/system/menu'
import {loadDataDictByCode} from '@/api/system/data-dict'
import { rules } from '@/utils/rules'

export default{
    

    data(){
       
       
        return{
            defaultPageSizes,
            obtainPositionButtons,
            positions,
            buttons:[],
            page:{
                ...defaultPage
            },
            config:{
                border:true,
                treeProps:{children: 'childNode', hasChildren: 'haveChildNode'},
                rowKey:'id',
                lazy:true,
                columns:[
                    ...menuTableSettings
                ]

            },
            search:{
                rules:{
                    // id:[{ validator: checkSearchId, trigger: 'blur' }]
                },
                values:{}
            },
            menuFormDrawer:{
                hasOpen:false,
                title:'',
                data:{},
                currentParentId:null
            },
            menuTypes:[
               
            ],
            buttonPositions:[],
            parentMenuList:[
               
            ],
            iconsLiraryDialog:{
                hasOpen:false
            },
            icons,
            showPage:false
        }
    },
    methods:{
         searchHandler(){

           
            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.resultMessages.unauthorizedView);

                return;
            }

            this.$refs['searchRef'].validate(async(valid,obj)=>{

                if(!valid){
                    this.$message.error(obj['id'][0].message);
                    return;
                }

                const {data} = await loadMenuPaging(
                    {
                        current:1,
                        size:this.page.size,
                        id:this.search.values.id,
                        name:this.search.values.name
                    }
                );

                this.page = data;

            })
            
        },
        async changePagingHandler(){

            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.resultMessages.unauthorizedView);

                return;
            }

            const {data} = await loadMenuPaging(
                {
                    current:this.page.current,
                    size:this.page.size,
                    id:this.search.values.id,
                    name:this.search.values.name
                }
            );

                this.page = data;

           
        },
        operateHandler(code,row){
            
            switch(code){
                case 'add':
                    this.menuFormDrawer.hasOpen = true;
                    this.menuFormDrawer.title = this.menuFormDrawerText.addTitle;
                break;
                case 'update':
                    this.menuFormDrawer.hasOpen = true;
                    this.menuFormDrawer.title = this.menuFormDrawerText.updateTitle;
                    this.menuFormDrawer.currentParentId = row.parentId;
                    this.menuFormDrawer.data = JSON.parse(JSON.stringify(row));
                    break;
                case 'delete':
                    this.deleteMenu(row);

            }

        },
        async lazyLoadHandler(row, treeNode, resolve){//懒加载子节点
            //模拟ajax请求
            const {data} = await lazyLoadMenuChildNode({
                id:row.id
            });
            if(data){
                resolve([
                    ...data  
                ])
            }
            
        },
        selectMenuIcon(icon){
            this.menuFormDrawer.data.icon = icon;
            this.iconsLiraryDialog.hasOpen = false;
        },
        async getCurrentPageButtons(){
            const {data} = await  loadCurrentPageTheButtons({route:this.$route.path});
            // console.log(buttons);
            this.buttons = data;
            //获取到了按钮数据，才开始渲染
            this.showPage = true;

            this.changePagingHandler();
        
        },
         saveOrUpdate(){

            this.$refs['menuFormRef'].validate(async (valid)=>{
                if(valid){
                    
                    const data = await saveOrUpdateMenu(this.menuFormDrawer.data);
                    if(data&&data.code === 200){
                        await this.updateLazyTreeNodeItemHandler(this.menuFormDrawer.data);
                         this.changePagingHandler();
                        this.menuFormDrawer.hasOpen =false;
                    }
                    
                }

            })

        },
       async getAllMenuList(){//获取所有的菜单
           let {data} =  await loadAllMenuList();
          

           this.parentMenuList = data;
           
           

        },
        async getMenuType(){
           const {data} = await  loadDataDictByCode({code:'menu_type'});
            
           if(data){
            this.menuTypes = data;
           }
        },
        deleteMenu(row){
            this.$confirm(this.confirmPopupText.message,this.confirmPopupText.title,{
                confirmButtonText: this.buttonText.confirm,
                cancelButtonText: this.buttonText.cancel,
                type: 'warning',
                center: true
            }).then(async ()=>{
                //判断当前行
                const data = await deleteMenuById({id:row.id,type:row.type});
                if(data&&data.code === 200){
                    this.deleteLazyLodingNodeHandler(row)
                    this.changePagingHandler();
                }
                
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.resultMessages.cancelDelete
                });
            });
 

        },
        deleteLazyLodingNodeHandler(item){ //懒加载时，删除懒加载的节点信息
            //获取在el-table组件中加的ref绑定的对象
            let elTableRef = this.$refs['iTableRef'].getTableRef();
            const store = elTableRef.table.store; //等同于el-table.table.store
            // console.log('store',store)
            if(item.parentId){//当，当前节点不是顶级节点的时候
                const currentNode =  store.states.lazyTreeNodeMap[item.parentId];//获取当前元素所在的node节点


                let index = currentNode.findIndex(child=>child.id == item.id);

                //删除指定节点
                currentNode.splice(index,1);
                return;
            }
            //当删除的是顶级节点的时候
            const currentNode = store.states.data;
            const index = currentNode.findIndex(child=>child.id == item.id);

            currentNode.splice(index,1);
        },
        async updateLazyTreeNodeItemHandler(item){ //table 懒加载时 更新子节点信息
            //获取在el-table组件中加的ref绑定的对象
            let elTableRef = this.$refs['iTableRef'].getTableRef();
            const store = elTableRef.table.store; //等同于el-table.table.store

            //1.节点移动了
            if(this.menuFormDrawer.currentParentId!=null&&this.menuFormDrawer.currentParentId != item.parentId ){
                // console.log('w3')
                //更新原本的节点
                const {data} = await lazyLoadMenuChildNode({
                    id:this.menuFormDrawer.currentParentId
                });
                store.states.lazyTreeNodeMap[this.menuFormDrawer.currentParentId]  = data;
                
                //更新新的节点
                if(item.parentId!=null&&item.parentId!=''){ //移动的节点非顶级节点


                    
                    const {data} = await lazyLoadMenuChildNode({
                        id:item.parentId
                    });
                    store.states.lazyTreeNodeMap[item.parentId]  = data;
                }
                

               
               return;
            }

            //2.修改当前节点的内容
            if(this.menuFormDrawer.currentParentId == item.parentId && item.parentId!=null){
                const {data} = await lazyLoadMenuChildNode({
                        id:item.parentId
                });
                
                store.states.lazyTreeNodeMap[item.parentId]  = data;

                return;
            }
            //3.将当前节点添加到新的父节点上
            if(item.id == null && item.parentId!=null){

                // console.log('w2',store.states.lazyTreeNodeMap)

                const treeNodeKeys = Object.keys(store.states.lazyTreeNodeMap);

                // console.log('treeNodeKeys',treeNodeKeys)

                let currentNodeParentParentNode;
                //查询当前节点父节点的父节点信息
                for(let i = 0; i < treeNodeKeys.length ; i++){
                    currentNodeParentParentNode = store.states.lazyTreeNodeMap[treeNodeKeys[i]].find(child=>child.id == item.parentId);
                    
                    if(currentNodeParentParentNode){
                        break;
                    }
                    // console.log('currentNodeParentParentNode',currentNodeParentParentNode)
                }

                //3.1 如果当前节点所在父节点原本没有子节点，那么更新当前节点所在父节点的父节点下所有子节点
                if(currentNodeParentParentNode){
                    const {data} = await lazyLoadMenuChildNode({
                        id:currentNodeParentParentNode.parentId
                    });
                
                    store.states.lazyTreeNodeMap[currentNodeParentParentNode.parentId]  = data;

                    
                }
                //3.2 当前节的父节点已经加载过子节点
                if(store.states.treeData[item.parentId]&&store.states.treeData[item.parentId].loaded){ 

                    const {data} = await lazyLoadMenuChildNode({
                        id:item.parentId
                    });
                    
                    store.states.lazyTreeNodeMap[item.parentId]  = data;

                }else{ //3.3 当前节的父节点没有加载过子节点

                    //初始化当前节点的父节点树
                    store.states.treeData[item.parentId] = {
                        display: true,
                        loading: false,
                        loaded: false,
                        expanded: false,
                        children: [],
                        lazy: true,
                        level: 0
                    }
                }

                
            }
           
        },
        async getButtonPosition(){ //获取 按钮位置
            const {data} = await  loadDataDictByCode({code:'menu_position'});

            if(data){
                this.buttonPositions = data;
            }


           
        },
        openMenuFromDrawerHandler(){//抽屉打开前回调
            this.getButtonPosition();
            this.getMenuType();
            this.getAllMenuList();
        }
        
    },
    mounted(){
        
        this.getCurrentPageButtons();
       

        
        // console.log('我执行了')
       
    },
    created(){
    },
    components:{
        ITable,
        IDialog,
        IDrawer,
        IIconLibrary
    },
    computed:{
        field(){
            const prefix = 'menuManagePage.field';
            return{
                id:this.$t(`${prefix}.id`),
                name:this.$t(`${prefix}.name`),
                code:this.$t(`${prefix}.code`),
                route:this.$t(`${prefix}.route`),
                icon:this.$t(`${prefix}.icon`),
                openNewWindowName:this.$t(`${prefix}.openNewWindowName`),
                typeName:this.$t(`${prefix}.typeName`),
                sort:this.$t(`${prefix}.sort`),
                position:this.$t(`${prefix}.position`),
                parent:this.$t(`${prefix}.parent`),
                remarks:this.$t(`${prefix}.remarks`),
                creatorName:this.$t(`${prefix}.creatorName`),
                createTime:this.$t(`${prefix}.createTime`),
                modifierName:this.$t(`${prefix}.modifierName`),
                modifyTime:this.$t(`${prefix}.modifyTime`),
                operate:this.$t(`${prefix}.operate`),
            }
        },
        form(){
            const prefix = 'menuManagePage.form';

            return{
                id:{
                    placeholder:this.$t(`${prefix}.id.placeholder`),
                },
                name:{
                    placeholder:this.$t(`${prefix}.name.placeholder`),
                },
                code:{
                    placeholder:this.$t(`${prefix}.code.placeholder`),
                },
                route:{
                    placeholder:this.$t(`${prefix}.route.placeholder`),
                },
                type:{
                    placeholder:this.$t(`${prefix}.type.placeholder`),
                },
                sort:{
                    placeholder:this.$t(`${prefix}.sort.placeholder`),
                },
                position:{
                    placeholder:this.$t(`${prefix}.position.placeholder`),
                },
                parentId:{
                    placeholder:this.$t(`${prefix}.parentId.placeholder`),
                },
                remarks:{
                    placeholder:this.$t(`${prefix}.remarks.placeholder`),
                }
            }
        },
        buttonText(){
            const prefix = 'public.button';
            return{
                submit:this.$t(`${prefix}.submit`),
                confirm:this.$t(`${prefix}.confirm`),
                cancel:this.$t(`${prefix}.cancel`),
                search:this.$t(`${prefix}.search`),
                delete:this.$t(`${prefix}.delete`)
            }
        },
        menuFormRules(){
            const prefix = 'menuManagePage.form';
            const suffix = 'checkFail.required';
            let checkRoute = async function(rule, value, callback){
                const {data} = await existsByRoute({
                    id:this.menuFormDrawer.data.id,
                    route:value
                });
        
                if(!data||data.exists){               
                    return callback(new Error(this.$t(`${prefix}.route.checkFail.exist`)));
                }
                
                return callback();
                
            }

            let checkCode = async function (rule, value, callback){
                const {data} = await existsByCode({
                    id:this.menuFormDrawer.data.id,
                    parentId:this.menuFormDrawer.parentId,
                    code:value
                });
            
                if(!data||data.exists){               
                    return callback(new Error(this.$t(`${prefix}.code.checkFail.exist`)));
                }
                
                return callback();
                
            }
            return{
                name:[ { required: true, message: this.$t(`${prefix}.name.${suffix}`), trigger: 'blur' }],
                code:[ 
                    { required: true, message: this.$t(`${prefix}.code.${suffix}`), trigger: 'blur' },
                    { validator: checkCode.bind(this), trigger: 'blur' }
                ],
                route:[
                    { required: true, message: this.$t(`${prefix}.route.${suffix}`), trigger: 'blur' },
                    { validator: checkRoute.bind(this), trigger: 'blur' }
                ],
                sort:[{ required: true, message: this.$t(`${prefix}.sort.${suffix}`), trigger: 'blur' }],
                type:[{ required: true, message: this.$t(`${prefix}.type.${suffix}`), trigger: 'blur' }],
                position:[{ required: true, message: this.$t(`${prefix}.position.${suffix}`), trigger: 'blur'}]
            }
        },
        searchRules(){
            const prefix = 'menuManagePage.form';
            const checkSearchId = (rule, value, callback)=>{
                if(!value){
                    return callback();
                }
                if(!rules.integer.test(value)){
                    return callback(new Error(this.$t(`${prefix}.id.checkFail.checkSearchId`)));
                }
                return callback(); 
            }


            return{
                id:[{ validator: checkSearchId, trigger: 'blur' }]
            }
        },
        resultMessages(){
            const prefix = 'public.resultMessages';
            return{
                unauthorizedView:this.$t(`${prefix}.unauthorizedView`),
                cancelDelete:this.$t(`${prefix}.cancelDelete`)
            }
        },
        menuFormDrawerText(){
            const prefix = 'menuManagePage.menuFormDrawer';
            return{
                addTitle:this.$t(`${prefix}.addTitle`),
                updateTitle:this.$t(`${prefix}.updateTitle`)
            }
        },
        confirmPopupText(){
            const prefix = 'menuManagePage.confirmPopup';
            return{
                title:this.$t(`${prefix}.title`),
                message:this.$t(`${prefix}.message`)
            }
        }
    }
}

</script>

<style lang="scss">
.menu-wrapper{

    height: 100%;
    background-color: #fff;
    .el-textarea__inner{ //element-ui 文本框 样式
        height: 200px;
        resize: none;
    }



}

.system-menus_menu_form_icon{
    width: 60px;
    height: 60px;
    // line-height: 24px;
    text-align: center;
    position: relative;

    span[class^=iconfont]{
        font-size: 40px;
        line-height: 60px;
    }
}
.system-menus_menu_form_icon:hover{
    cursor: pointer;
}
.system-menus_icon-library{
    height: 400px;
    overflow-y: scroll;
    overflow-x:hidden ;
    display: flex;
    justify-content: center;

}

</style>
