<template>
    <div class="i-charge-rules-selector_wrapper" >
        <!-- 选择器组件 -->
        <vue-selecto
        dragContainer=".i-charge-rules-selector_time_span"
	    v-bind:selectableTargets='[".i-charge-rules-selector_time_span .i-charge-rules-selector_time_span_item"]'
	    v-bind:hitRate='1'
	    v-bind:selectByClick='true'
	    v-bind:selectFromInside='true'
	    v-bind:ratio='0'
	    @select="selecteHandler"
	    @selectEnd="selectEndHandler"
        ></vue-selecto>

        <div class="i-charge-rules-selector_operate_wrapper">
            <div class="i-charge-rules-selector_charge_rule_options">
                <div 
                class="i-charge-rules-selector_charge_rule_option_item" 
                v-for="(item,index) in chargeRuleConfig" 
                @click="changeSelectedChargeRuleConfigHandler(index,item)"
                :key="index" :style="{'background-color':usePowerPeriodTypeOptions[have(usePowerPeriodTypeOptions,usePowerPeriodTypeOptionsProps.value,item[chargeRuleConfigProps.usePowerPeriodType])][usePowerPeriodTypeOptionsProps.color] } "
                >
                   
                    规则{{ index+1 }} - {{ usePowerPeriodTypeOptions[have(usePowerPeriodTypeOptions,usePowerPeriodTypeOptionsProps.value,item[chargeRuleConfigProps.usePowerPeriodType])][usePowerPeriodTypeOptionsProps.name] }}
                </div>    
            </div>
           

        </div>

        

        <div class="i-charge-rules-selector_time_span" id="selector" @blur="blurHandler">
            <div class="i-charge-rules-selector_time_span_item" v-for="(item,index) in timeSpanOptions" :key="index" :id="index" :index="index">
                {{ item[timeSpanOptionProps.timeSpan] }}
            </div>
        </div>



    </div>
</template>

<script>
import { VueSelecto } from 'vue-selecto'
import {excludeDuplicates2ChargeRuleTimeSpan,hasSelectedInaAllChargeRuleConfig, have} from './i-charge-rules-selector-hook'
export default {
    data(){

        return{
            currentChargeRuleConfigIndex:-1,
            have
        }
    },
    props:{
        usePowerPeriodTypeOptions:{ //用电时段类型
            type:Array,
            default:()=>[
                {name:'尖',value:3,color:'red'},
                {name:'峰',value:2,color:'#ec9bad'},
                {name:'平',value:1,color:'#409EFF'},
                {name:'谷',value:0,color:'#00c3ac'},
            ]
        },
        usePowerPeriodTypeOptionsProps:{
            type:Object,
            default:()=>{
                return{
                    name:'name',
                    value:'value',
                    color:'color'
                }
            }
        },
        timeSpanOptions:{ //时间范围选项
            type:Array,
            default:()=>[]
        },
        timeSpanOptionProps:{
            type:Object,
            default:()=>{
                return{timeSpan:'timeSpan'}
            }
        },
        chargeRuleConfig:{
            type:Array,
            default:()=>[]
        },
        chargeRuleConfigProps:{
            type:Object,
            default:()=>{
                return{timeSpans:'timeSpans',usePowerPeriodType:'usePowerPeriodType'}
            }
        }
        
    },
    methods:{
        blurHandler(){
            console.log('我被触发了~');
        },
        selecteHandler(e){

        },
        selectEndHandler(e){

            if(this.currentChargeRuleConfigIndex == -1){
                this.$message.warning('未选择用电量时间段规则')
                return;
            }
            let selectedArr = [];
           
            e.selected.map(item => {
               //1.判断当前选中的元素是否已经被选中了
                let entity =  hasSelectedInaAllChargeRuleConfig(
                    this.chargeRuleConfig,
                    this.chargeRuleConfigProps.timeSpans,
                    this.timeSpanOptionProps.timeSpan,
                    this.timeSpanOptions[item.id][this.timeSpanOptionProps.timeSpan]
                    )
                // console.log('entity',entity);
               //2.判断当前选中的规则
               if(entity.configIndex!=-1){
                    //3.删除对应的选中的时间区间
                    this.chargeRuleConfig[entity.configIndex][this.chargeRuleConfigProps.timeSpans].splice(entity.timeScopeIndex,1);
                    //4.恢复原本的样式
                    item.style.backgroundColor = "#fff";
                    item.style.color = '#999';

                    return;
               }

               
                //将选中的时间区间写到规则数组中去
                selectedArr.push(this.timeSpanOptions[item.id]);
                let usePowerPeriodTypeIndex = have(this.usePowerPeriodTypeOptions,this.usePowerPeriodTypeOptionsProps.value,this.chargeRuleConfig[this.currentChargeRuleConfigIndex][this.chargeRuleConfigProps.usePowerPeriodType])
                item.style.backgroundColor = this.usePowerPeriodTypeOptions[usePowerPeriodTypeIndex].color;
                item.style.color = '#fff';
            })
            

            //去重
            selectedArr = excludeDuplicates2ChargeRuleTimeSpan(selectedArr);
            //重新赋值
            this.chargeRuleConfig[this.currentChargeRuleConfigIndex][this.chargeRuleConfigProps.timeSpans] = [...this.chargeRuleConfig[this.currentChargeRuleConfigIndex][this.chargeRuleConfigProps.timeSpans],...selectedArr];
           
        },

        initIChargeRulesSelector(){ //回显数据

            let timeSpanElements = document.getElementsByClassName('i-charge-rules-selector_time_span_item');

            for(let i =0;i<timeSpanElements.length;i++){

        
                timeSpanElements[i].style.backgroundColor = "#fff";
                timeSpanElements[i].style.color = '#999';

            }

            // console.log('el',timeSpanElements)

            for(let configIndex in this.chargeRuleConfig){

                let timeSpans = this.chargeRuleConfig[configIndex][this.chargeRuleConfigProps.timeSpans]

                for(let timeSpanIndex in timeSpans){

                    //获取所在索引
                    let optionIndex = have(this.timeSpanOptions,this.timeSpanOptionProps.timeSpan,timeSpans[timeSpanIndex][this.timeSpanOptionProps.timeSpan])
                    if(optionIndex!=-1){

                        //设置
                        //获取当前用电量时段类型（尖/峰/平/谷)
                        let usePowerPeriodType = this.chargeRuleConfig[configIndex][this.chargeRuleConfigProps.usePowerPeriodType];
                        let usePowerPeriodTypeIndex = have(this.usePowerPeriodTypeOptions,'value',usePowerPeriodType);
           
                        timeSpanElements[optionIndex].style.backgroundColor = this.usePowerPeriodTypeOptions[usePowerPeriodTypeIndex].color;
                        timeSpanElements[optionIndex].style.color = "#fff";

                    }else{
                        
                    }
                    

                }

            }

        },
        changeSelectedChargeRuleConfigHandler(index,item){
            this.currentChargeRuleConfigIndex = index;
        }

    },
    mounted(){
        if(this.chargeRuleConfig.length>0){
            this.currentChargeRuleConfigIndex = 0;
        }
        this.initIChargeRulesSelector();
        
    },
    components:{
        VueSelecto
    },
    watch:{
        chargeRuleConfig:function(newValue,oldValue){
            this.initIChargeRulesSelector();
            
            let index = -1;

            console.log('l',this.chargeRuleConfig.length)

            if(this.chargeRuleConfig.length == 0){

                this.changeSelectedChargeRuleConfigHandler(index);
                return;
            }

            if(this.chargeRuleConfig.length<=this.currentChargeRuleConfigIndex ){
                index = this.chargeRuleConfig.length-1;
            }else{
                index = this.currentChargeRuleConfigIndex;
            }
            
        }
    }
}
</script>

<style lang="scss">
.i-charge-rules-selector_wrapper{

    .i-charge-rules-selector_charge_rule_options{
        display: flex;
        flex-wrap: wrap;
        grid-gap: 10px;
        padding: 20px;
        white-space: nowrap;
        .i-charge-rules-selector_charge_rule_option_item{
            -moz-user-select:none; /* Firefox私有属性 */
            -webkit-user-select:none; /* WebKit内核私有属性 */
            -ms-user-select:none; /* IE私有属性(IE10及以后) */
            -khtml-user-select:none; /* KHTML内核私有属性 */
            -o-user-select:none; /* Opera私有属性 */
            user-select:none; /* CSS3属性 */
            color: #fff;
            // border-radius: 100%;
            font-size: 12px;
            height: 24px;
            line-height: 24px;
            padding-left: 5px;
            padding-right: 5px;
        }
        .i-charge-rules-selector_charge_rule_option_item:hover{
            cursor: pointer;
        }
        .selected_charge_rule_option{
            border: 2px solid #999;
        }
    }
    
    .i-charge-rules-selector_time_span{
       
        border: 2px solid #eee;
        padding: 40px;
        // height: 400px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        grid-gap: 10px;
        justify-content: flex-start;
        white-space: nowrap;
        .i-charge-rules-selector_time_span_item{
            // display: inline-block;
            -moz-user-select:none; /* Firefox私有属性 */
            -webkit-user-select:none; /* WebKit内核私有属性 */
            -ms-user-select:none; /* IE私有属性(IE10及以后) */
            -khtml-user-select:none; /* KHTML内核私有属性 */
            -o-user-select:none; /* Opera私有属性 */
            user-select:none; /* CSS3属性 */
            // border-radius: 5px;
            // width: 100px;
            padding-left: 5px;
            padding-right: 5px;
            height: 24px;
            line-height: 24px;
            border: 1px solid #eee;
            // background: #eee;
            font-size: 12px;

            color: #999;
        }
        .i-charge-rules-selector_time_span_item:hover{
            cursor: pointer;
        }
    }
}

.selected{
    color: #fff;
    background-color: #4af;
}
</style>