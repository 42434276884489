<template>
    <div class="table-wrapper">
        <div class="table-view-main">
            <!-- 搜素区 -->
            <div class="search-main-wrapper">
                <div class="search-condition">
                    <slot name="search-condition">
                       
                    </slot>
                </div>
                <div class="search-btn">
                    <slot name="search-button">
                       
                    </slot>
                </div>

            </div>
            <!-- 全局操作区 -->
            <div class="overall-main-wrapper">
                <div class="operate-left">
                    <slot name="overall-left">
                        
                    </slot>
                </div>
                <div class="operate-right">
                    <slot name="overall-right">
                        
                    </slot>
                </div>
            </div>
            <div class="table-main-wrapper">
                <div class="table-main">
                    <el-table
                    ref="table"
                    v-if="config.columns.length>0"
                    :data="page.records"
                    :border="config.border?config.border:true"
                    :height="height"
                    :tree-props="config.treeProps"
                    :lazy="config.lazy"
                    :row-key="config.rowKey"
                    v-bind="$attrs"
                    size="small"
                    >
                        <el-table-column v-for="(column,index) in config.columns" :key="index"
                        :label="column.label"
                        :prop="column.prop"
                        :width="column.width"
                        :fixed="column.fixed"
                        :align="column.align"
                        :row-key="config.rowKey"
                        :show-overflow-tooltip="column.showOverflowTooltip"
                        >
                            <template slot="header" slot-scope="scope">
                                <slot name="header" v-bind="scope" >
                                    {{ scope.column.label }}
                                </slot>
        
                            </template>
                            <template slot-scope="scope">
                                <!-- 加上一个限定的前缀，防止有行内插槽名与字段名一致 -->
                                <slot :name="'row_'+column.prop" v-bind="scope">
                                    {{ scope.row[column.prop] }}
                                </slot> 
                            </template>
                        </el-table-column>

                    </el-table>
                </div>
            </div>
            <div class="table-page-wrapper">
                <el-pagination
                small
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.current"
                :page-sizes="pageSizes"
                :page-size="page.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.total">
                </el-pagination>
            </div>
        </div>
       
    </div>
</template>

<script>

export default{
    data(){

        return{

            height:0,
            timer:null,
            trigger:false,

        }
    },
    props:{
        page:{
            type:Object,
            default:()=>{}
        },
        config:{
            type:Object,
            default:()=>{}
        },
        pageSizes:{
            type:Array,
            default:()=>[20,40,80,100,200,400]
        }
    },
    methods:{
        handleSizeChange(size){ //处理每页显示条数
            this.page.size =size;
            this.$emit('change-page');
           
        },
        handleCurrentChange(current){ //处理当前页
            this.page.current = current;
            this.$emit('change-page');
        },
        countTableHeight(){

            let tableWrapperElement = document.getElementsByClassName('table-wrapper')[0];
            let searchMainWrapperElement = document.getElementsByClassName('search-main-wrapper')[0];
            let overallMainWrapperElement = document.getElementsByClassName('overall-main-wrapper')[0]
            let tablePageMainWrapperElement = document.getElementsByClassName('table-page-wrapper')[0]
        //    console.log('视口发生了变化')

            this.$nextTick(()=>{
                this.height = tableWrapperElement.offsetHeight - (searchMainWrapperElement.offsetHeight + overallMainWrapperElement.offsetHeight + tablePageMainWrapperElement.offsetHeight) ;
                
            });

           



        },
        resizeHandler(){
            clearTimeout(this.timer);
            this.timer = setTimeout(()=>{
                this.countTableHeight();
            },500);

            // this.countTableHeight();
            
           
        },
        getTableRef(){
            return this.$refs;
        }
    },
    watch:{
       

    },
    mounted(){
        
        
        window.addEventListener('resize',this.resizeHandler);
        this.countTableHeight();

    },
    beforeDestroy(){
        window.removeEventListener('resize',this.resizeHandler);
        
    },
}

</script>

<style lang="scss" scoped>
.table-wrapper{
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: 100%;
    .table-view-main{
        width: 96%;
       
        .search-main-wrapper{
            //min-height: 50px;
            display: flex;
            justify-content: space-between;
            // align-items: center;
            padding-top: 24px;
            //padding-bottom: 24px;
            .search-condition{
                display: flex;
                flex-wrap: wrap;
                grid-gap: 10px;
               
            
                
            }

        }
        .overall-main-wrapper{
            // padding-top: 10px;
            padding-bottom: 24px;
            display: flex;
            justify-content: space-between;

        }
         .table-page-wrapper{
            height: 70px;
            justify-content: center;
            display: flex;
            align-items: center;
            overflow-x: hidden;
            
        }
    }
    
    
}
</style>