import {getStore, setStore, removeStore} from '@/utils/store'
import {storeKey} from "@/constant/store-key";

/**
 * 获取本地缓存token
 * @returns {number|*}
 */
export const getAccessToken = () => {
    return getStore({name: storeKey.accessToken});
}

/**
 * 设置本地缓存token
 * @param value
 */
export const setAccessToken = (value = '') => {
    setStore({name: storeKey.accessToken, content: value});
}

/**
 * 移除本地缓存token
 */
export const removeAccessToken = () => {
    removeStore({name: storeKey.accessToken});
}
