<template>
    <div class="orders-recharge_wrapper">

        <i-table
            :config="config"
            :page="page"
            @change-page="changePagingHandler"
            :page-sizes="defaultPageSizes"
        >
            <!--搜索项目-->
            <template v-slot:search-condition>
                <el-form :model="search.values" :inline="true" ref="searchRef">
                    <el-form-item :label="field.orderCode+':'">
                        <el-input size="small" v-model="search.values.orderCode" :placeholder="form.orderCode.placeholder" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="field.paymentOrderCode+':'">
                        <el-input size="small" v-model="search.values.paymentOrderCode" :placeholder="form.paymentOrderCode.placeholder" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="field.statusName+':'">
                        <el-select
                            v-model="search.values.status"
                            size="small"
                            :placeholder="form.statusName.placeholder"
                            clearable
                        >
                              <el-option
                                  v-for="(item,index) in orderStatusList"
                                  :key="index"
                                  :label="item.name"
                                  :value="item.value"
                              >
                              </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="field.orderTime+':'">
                        <el-date-picker
                            size="small"
                            v-model="times"
                            type="daterange"
                            :range-separator="elDatePickerText.rangeSeparator"
                            :start-placeholder="elDatePickerText.startPlaceholder"
                            :end-placeholder="elDatePickerText.endPlaceholder"
                            value-format="yyyy-MM-dd"
                            align="right"
                            unlink-panels
                            @change="changeDateHandler"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </template>
            <!-- 搜索按钮 -->
            <template v-slot:search-button>
                <el-button type="primary"  size="small" @click="searchHandler" icon="el-icon-search">{{ buttonText.search }}</el-button>
            </template>

            <!--头部信息-->
            <template v-slot:header="scope">
                {{ field[scope.column.property] }}
            </template>
            <!-- 左侧功能栏 -->
            <template v-slot:overall-left>
                <el-button v-for="(overallLeftButton,index) in obtainPositionButtons(buttons,positions.left)" :key="index" type="primary"  size="small" :icon="overallLeftButton.icon" @click="operateHandler(overallLeftButton.code)">{{overallLeftButton.name}}</el-button>
            </template>

            <!-- 右侧工作栏 -->
            <template v-slot:overall-right>
                <el-button v-for="(overallRightButton,index) in obtainPositionButtons(buttons,positions.right)" :key="index"   size="small" :icon="overallRightButton.icon" @click="operateHandler(overallRightButton.code)">{{overallRightButton.name}}</el-button>
            </template>

            <!-- 数据行内 -->
            <template v-slot:row_operate="scope">
                <el-button
                    type="text"
                    :icon="rowButton.icon"
                    size="mini"
                    v-for="(rowButton,index) in obtainPositionButtons(buttons,positions.row) "
                    :key="index"
                    @click="operateHandler(rowButton.code,scope.row)"
                >
                {{ rowButton.name  }}
              </el-button>
            </template>

        </i-table>


    </div>
</template>

<script>
import {defaultPage,defaultPageSizes} from "@/config/page-settings";
import { clientUserRechargeOrderTableSettings } from "@/config/table-settings";
import {positions} from "@/config/button-position-settings";
import {obtainPositionButtons, showData} from "@/utils/power";
import ITable from "@/components/i-table/ITable.vue";
import {loadClientRechargeOrderPaging, loadClientRechargeOrderStatusList} from "@/api/order/client-user-recharge-order";
import {loadCurrentPageTheButtons} from "@/api/system/menu";
export default{
    components:{
      ITable
    },
    data(){

        return{
            defaultPageSizes,
            positions,
            obtainPositionButtons,
            config:{
                border:true,
                columns:[
                  ...clientUserRechargeOrderTableSettings
                ]
            },
            page:{
                ...defaultPage
            },
            search:{
                values:{}
            },
            showPage:false,
            buttons:[],
            times:[],
            orderStatusList:[]
          }
    },
    methods:{
      async getCurrentPageButtons(){//获取当前页按钮权限
        const {data} = await loadCurrentPageTheButtons({route:this.$route.path});
        // console.log(buttons);
        this.buttons = data;
        //获取到了按钮数据，才开始渲染
        this.showPage = true;

        await this.changePagingHandler();

      },
      async searchHandler(){
        const show = showData(this.buttons,positions.view);

        if(!show){
          this.$message.error(this.resultMessages.unauthorizedView);
          return;
        }

        const result = await loadClientRechargeOrderPaging({
          current: 1,
          size:this.page.size,
          ...this.search.values
        });

        if (result&&result.code === 200){
          this.page = result.data;
        }
      },
      operateHandler(code,row){

      },
      changeDateHandler(env){
          console.log('env',env);
          if (env){
              this.search.values.startTime = env[0];
              this.search.values.endTime = env[1];
          }else {
              this.search.values.startTime = null;
              this.search.values.endTime = null;
          }
      },
      async getOrderStatusList(){
         const result = await loadClientRechargeOrderStatusList();
         if (result&&result.code === 200){

           this.orderStatusList = result.data;
         }
      },
      async changePagingHandler(){
        const show = showData(this.buttons,positions.view);

        if(!show){
          this.$message.error(this.resultMessages.unauthorizedView);
          return;
        }

        const result = await loadClientRechargeOrderPaging({
          current: this.page.current,
          size:this.page.size,
          ...this.search.values
        });

        if (result&&result.code === 200){
          this.page = result.data;
        }

      },

    },
  mounted() {
     this.getOrderStatusList();
     this.getCurrentPageButtons();
  },
  computed:{
        buttonText(){
            const prefix = 'public.button';
            return{
                search:this.$t(`${prefix}.search`)
            }
        },
        resultMessages(){
            const prefix = 'public.resultMessages';

            return{
                unauthorizedView:this.$t(`${prefix}.unauthorizedView`),
            }
        },
        field(){

            const prefix = 'clientUserRechargeOrderPage.field';

            return{
                id:this.$t(`${prefix}.id`),
                userId:this.$t(`${prefix}.userId`),
                mobile:this.$t(`${prefix}.mobile`),
                nickname:this.$t(`${prefix}.nickname`),
                orderCode:this.$t(`${prefix}.orderCode`),
                amountByYuan:this.$t(`${prefix}.amountByYuan`),
                paymentTypeName:this.$t(`${prefix}.paymentTypeName`),
                paymentOrderCode:this.$t(`${prefix}.paymentOrderCode`),
                paymentTime:this.$t(`${prefix}.paymentTime`),
                orderTime:this.$t(`${prefix}.orderTime`),
                statusName:this.$t(`${prefix}.statusName`),
                remarks:this.$t(`${prefix}.remarks`),
                creatorName:this.$t(`${prefix}.creatorName`),
                createTime:this.$t(`${prefix}.createTime`),
                modifierName:this.$t(`${prefix}.modifierName`),
                modifyTime:this.$t(`${prefix}.modifyTime`),
                operate:this.$t(`${prefix}.operate`),
            }

        },
        form(){
            const prefix = 'clientUserRechargeOrderPage.form';

            return{
                orderCode: {
                    placeholder:this.$t(`${prefix}.orderCode.placeholder`)
                },
                paymentOrderCode:{
                    placeholder:this.$t(`${prefix}.paymentOrderCode.placeholder`)
                },
                statusName:{
                    placeholder:this.$t(`${prefix}.statusName.placeholder`)
                }
            }
        },
       elDatePickerText(){

          const prefix = 'public.elDatePicker';

          return{
            rangeSeparator:this.$t(`${prefix}.rangeSeparator`),
            startPlaceholder:this.$t(`${prefix}.startPlaceholder`),
            endPlaceholder:this.$t(`${prefix}.endPlaceholder`),
          }
       }
    }

}
</script>

<style lang="scss">
.orders-recharge_wrapper{
    height: 100%;
    background-color: var(--bg-theme-page-color);
}

</style>