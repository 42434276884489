import Vue from 'vue'
import App from './App.vue'
import router from "@/router"; //导入router

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';
import '@/assets/fonts/iconfont.css'

import ElOverlayAutoClose from '@/components/patch/el-overlay-auto-close';
import {i18n} from '@/lang';



Vue.config.productionTip = false

Vue.use(ElementUI,{
  i18n:(key,value)=> i18n.t(key, value)
});
Vue.use(ElOverlayAutoClose);//补丁

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app');

