<template>
  <div class="data-main-wrapper">
    <i-table v-if="table.showTable" :config="table.config" :page="table.page" :page-sizes="defaultPageSizes" @change-page="handleChangePage">
      <!--搜索条件-->
      <template slot="search-condition">
        <el-form :inline="true" :model="table.searchCondition" size="small">
          <el-form-item :label="field.orgName.name + ':'">
            <el-select v-model="table.searchCondition.orgId"
                       clearable filterable
                       :placeholder="field.orgName.placeholder">
              <el-option
                  v-for="(item, index) in table.orgPullDownData"
                  :key="index"
                  :label="item.orgName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="field.stationName.name + ':'">
            <el-select v-model="table.searchCondition.stationId"
                       clearable filterable
                       :placeholder="field.stationName.placeholder">
              <el-option
                  v-for="(item, index) in table.chargeStationPullDownData"
                  :key="index"
                  :label="item.stationName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="field.chargePileCode.name + ':'">
            <el-input clearable v-model="table.searchCondition.chargePileCode"
                      :placeholder="field.chargePileCode.search"></el-input>
          </el-form-item>
          <el-form-item :label="field.chargePileName.name + ':'">
            <el-input clearable v-model="table.searchCondition.chargePileName"
                      :placeholder="field.chargePileName.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.enableName.name + ':'">
            <el-select v-model="table.searchCondition.enable"
                       clearable filterable
                       :placeholder="field.enableName.placeholder">
              <el-option
                  v-for="(item, index) in table.enablePullDownData"
                  :key="index"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <!--搜索按钮-->
      <template slot="search-button">
        <el-button type="primary" size="small" @click="handleChangePage(true)">{{ btn.search }}</el-button>
      </template>
      <!--全局操作-->
      <template slot="overall-left">
        <el-button
            type="primary"
            v-for="(btn, index) in btnPosition.obtainPositionButtons(table.buttons, btnPosition.positions.left)"
            :key="index"
            size="small"
            :icon="btn.icon"
            @click="handleOperate(btn.code)">
          {{ btn.name }}
        </el-button>
      </template>
      <!--表头设置-->
      <template slot="header" scope="scope">
        {{ field[scope.column.property].name }}
      </template>
      <!--行内操作-->
      <template slot="row_operate" slot-scope="scope">
        <el-button type="text"
                   v-for="(btn, index) in btnPosition.obtainPositionButtons(table.buttons, btnPosition.positions.row)"
                   :key="index"
                   size="mini"
                   :icon="btn.icon"
                   @click="handleOperate(btn.code, scope.row)">
          {{ btn.name }}
        </el-button>
      </template>
    </i-table>

    <!--drawer-->
    <i-drawer :visible.sync="drawer.visible" :title="drawer.title"
              size="30%" @close="handleCloseDrawer">
      <el-form class="charge-drawer" ref="chargePileForm" :model="drawer.chargePileForm"
               :rules="chargePileRules"
               label-width="120px" label-position="right" size="small">
        <el-form-item :label="field.orgName.name + ':'" prop="orgId">
          <el-select v-model="drawer.chargePileForm.orgId"
                     clearable filterable class="item-width"
                     :placeholder="field.orgName.placeholder"
                     @change="handleChangeOrgPullDown">
            <el-option
                v-for="(item, index) in table.orgPullDownData"
                :key="index"
                :label="item.orgName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="field.stationName.name + ':'" prop="stationId">
          <el-select v-model="drawer.chargePileForm.stationId"
                     clearable filterable class="item-width"

                     :placeholder="field.stationName.placeholder">
            <el-option
                v-for="(item, index) in drawer.chargeStationPullDownData"
                :key="index"
                :label="item.stationName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="field.brandName.name + ':'" prop="brandId">
          <el-select v-model="drawer.chargePileForm.brandId"
                     clearable filterable class="item-width"
                     :placeholder="field.brandName.placeholder" @change="handleSelectBrandChange">
            <el-option
                v-for="(item, index) in table.chargePileBrandPullDownData"
                :key="index"
                :label="item.brandName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="field.brandModelName.name + ':'" prop="brandModelId">
          <el-select v-model="drawer.chargePileForm.brandModelId"
                     clearable filterable class="item-width"
                     :placeholder="field.brandModelName.placeholder">
            <el-option
                v-for="(item, index) in drawer.chargePileBrandModelPullDownData"
                :key="index"
                :label="item.brandModelName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="field.chargePileCode.name + ':'" prop="chargePileCode">
          <el-input class="item-width" v-model="drawer.chargePileForm.chargePileCode"
                    :disabled="true"
                    :placeholder="field.chargePileCode.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="field.chargePileName.name + ':'" prop="chargePileName">
          <el-input class="item-width" v-model="drawer.chargePileForm.chargePileName"
                    :placeholder="field.chargePileName.placeholder" clearable></el-input>
        </el-form-item>
        <el-form-item :label="field.useScopeName.name + ':'" prop="useScope">
          <el-select v-model="drawer.chargePileForm.useScope"
                     clearable filterable class="item-width"
                     :placeholder="field.useScopeName.placeholder">
            <el-option
                v-for="(item, index) in drawer.useScopeData"
                :key="index"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="field.enableName.name + ':'" prop="enable">
          <el-switch
              v-model="drawer.chargePileForm.enable"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text=public.enable
              :inactive-text=public.disable
              :active-value="1"
              :inactive-value="0">
          </el-switch>
        </el-form-item>
        <el-form-item :label="field.remark.name + ':'" prop="remark">
          <el-input class="item-width" v-model="drawer.chargePileForm.remark"
                    type="textarea"
                    :rows="2"
                    :placeholder="field.remark.placeholder"></el-input>
        </el-form-item>
      </el-form>

      <template slot="footer">
        <el-button type="primary" @click="handleChargePileFormSubmit">{{ btn.submit }}</el-button>
        <el-button @click="drawer.visible = false">{{ btn.cancel }}</el-button>
      </template>
    </i-drawer>

    <!--设置计费规则dialog-->
    <i-dialog :title="chargeRuleDialog.title"
              :visible.sync="dialog.visible"
              @close="handleDialogClose"
              width="30%">
      <div class="charge-rule-dialog">
        <el-select clearable v-model="dialog.chargeRule.chargeRuleId"
                   :placeholder="chargeRuleDialog.chargeRule.placeholder"
                   size="small">
          <el-option
              v-for="(item, index) in dialog.chargeRulePullDownData"
              :key="index"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <template slot="footer">
        <el-button type="primary" size="small" @click="handleDialogSubmit">{{ btn.submit }}</el-button>
        <el-button size="small" @click="dialog.visible = false">{{ btn.cancel }}</el-button>
      </template>
    </i-dialog>

    <!--充电桩枪口配置的drawer-->
        <i-drawer :visible.sync="chargeGunDrawer.visible" :title="gunDrawer.title"
                  size="30%" @close="handleClosePilePortDrawer">
          <el-form class="charge-drawer" :model="chargeGunDrawer.chargeGunForm" ref="chargeGunForm"
                   label-width="100px" size="small">

            <i-title :title="gunDrawer.baseInfoTitle"></i-title>
            <el-form-item :label="field.chargePileName.name + ':'">
              {{ this.chargeGunDrawer.chargePile.chargePileName }}
            </el-form-item>

            <i-title :title="gunDrawer.settingTitle"></i-title>
<!--            <el-form-item>
              <el-button @click="addChargeGun">{{ gunDrawer.addGun.title }}</el-button>
            </el-form-item>-->
            <div v-for="(item, index) in chargeGunDrawer.chargeGunForm.chargeGuns" :key="index">
              <i-title class="item-width" :title="`${gunDrawer.sign + (index + 1)}`">
<!--                <template slot="right">
                  <el-button type="text" @click.prevent="removeChargeGun(item)">{{ btn.delete }}</el-button>
                </template>-->
              </i-title>
              <el-form-item
                  class="item-width"
                  :label="gunDrawer.gunCode + ':'">
                <el-input v-model="item.chargeGunCode" disabled
                          :placeholder="gunDrawer.addGun.placeholder"></el-input>
              </el-form-item>
              <el-form-item
                  class="item-width"
                  :label="gunDrawer.gunName.name + ':'"
                  :prop="'chargeGuns.' + index + '.chargeGunName'"
                  :rules="[{ required: true, message: gunDrawer.gunName.required, trigger: 'blur'}
                  , {min: 1, max: 20, message: gunDrawer.gunName.maxSize, trigger: 'blur'}]">
                <el-input v-model="item.chargeGunName" clearable
                          :placeholder="gunDrawer.gunName.placeholder"></el-input>
              </el-form-item>
            </div>
          </el-form>

          <template slot="footer">
            <el-button type="primary" @click="handleChargeGunFormSubmit">{{ btn.submit }}</el-button>
            <el-button @click="chargeGunDrawer.visible = false">{{ btn.cancel }}</el-button>
          </template>
        </i-drawer>
  </div>
</template>

<script>

import {defaultPage,defaultPageSizes} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable.vue';
import IDrawer from '@/components/i-drawer/IDrawer.vue';
import {chargePileTableSettings} from '@/config/table-settings';
import {obtainPositionButtons, showData} from '@/utils/power';
import {positions} from '@/config/button-position-settings';
import {loadCurrentPageTheButtons} from '@/api/system/menu';
import {hasSuccess, validateNull} from '@/utils/tool';
import {
  chargePilePaging,
  deleteChargePile,
  listChargePileProtocolType,
  saveOrUpdateChargePile,
  chargePileSetChargeRule
} from '@/api/business/charge-pile';
import {listOrgSelect} from '@/api/system/organization';
import {chargeStationPullDownList} from '@/api/business/charge-station';
import {loadDataDictByCodes} from '@/api/system/data-dict';
import {chargePileBrandPullDownList} from '@/api/business/charge-pile-brand';
import {rules} from '@/utils/rules';
import {message} from '@/utils/message';
import ITitle from '@/components/i-title/ITitle.vue';
import {saveOrUpdateChargeGuns, listChargeGuns} from '@/api/business/charge-pile-port';
import IDialog from '@/components/i-dialog/IDialog.vue';
import {loadChargeRuleOptionList} from '@/api/business/charge-rule';
import {chargePileBrandModelPullDown} from '@/api/business/charge-pile-brand-model';
import {useWebsocket} from '@/utils/websocket';
import {wsTopic} from "@/config/websocket-topic";

export default {
  components: {
    'i-dialog': IDialog,
    'i-table': ITable,
    'i-drawer': IDrawer,
    'i-title': ITitle,
  },
  computed: {
    btnPosition: {
      get: function () {
        return {obtainPositionButtons, positions};
      }
    },
    public() {
      return {
        unauthorizedView: this.$t('public.resultMessages.unauthorizedView'),
        enable: this.$t('public.button.enable'),
        disable: this.$t('public.button.disabled'),
      }
    },
    btn() {
      return {
        search: this.$t('public.button.search'),
        submit: this.$t('public.button.submit'),
        cancel: this.$t('public.button.cancel'),
        confirm: this.$t('public.button.confirm'),
        delete: this.$t('public.button.delete'),
      }
    },
    field() {
      const fieldPrefix = 'chargePileManagePage.field';
      const formPrefix = 'chargePileManagePage.form';
      const confirmPrefix = 'chargePileManagePage.confirm';
      return {
        id: {name: this.$t(`${fieldPrefix}.id`)},
        orgName: {
          name: this.$t(`${fieldPrefix}.orgName`),
          placeholder: this.$t(`${formPrefix}.orgName.placeholder`),
          required: this.$t(`${formPrefix}.orgName.checkFail.required`),
        },
        stationName: {
          name: this.$t(`${fieldPrefix}.stationName`),
          placeholder: this.$t(`${formPrefix}.stationName.placeholder`),
          required: this.$t(`${formPrefix}.stationName.checkFail.required`),
        },
        chargeRuleName: {
          name: this.$t(`${fieldPrefix}.chargeRuleName`),
          placeholder: this.$t(`${formPrefix}.chargeRuleName.placeholder`),
          required: this.$t(`${formPrefix}.chargeRuleName.checkFail.required`),
        },
        chargePileCode: {
          name: this.$t(`${fieldPrefix}.chargePileCode`),
          placeholder: this.$t(`${formPrefix}.chargePileCode.placeholder`),
          search: this.$t(`${formPrefix}.chargePileCode.search`),
        },
        chargePileName: {
          name: this.$t(`${fieldPrefix}.chargePileName`),
          placeholder: this.$t(`${formPrefix}.chargePileName.placeholder`),
          required: this.$t(`${formPrefix}.chargePileName.checkFail.required`),
          maxSize: this.$t(`${formPrefix}.chargePileName.checkFail.maxSize`),
        },
        brandName: {
          name: this.$t(`${fieldPrefix}.brandName`),
          placeholder: this.$t(`${formPrefix}.brandName.placeholder`),
          required: this.$t(`${formPrefix}.brandName.checkFail.required`),
        },
        brandModelName: {
          name: this.$t(`${fieldPrefix}.brandModelName`),
          required: this.$t(`${formPrefix}.brandModelName.checkFail.required`),
        },
        brandModelTypeName: {name: this.$t(`${fieldPrefix}.brandModelTypeName`)},
        gunNumber: {name: this.$t(`${fieldPrefix}.gunNumber`)},
        maxPower: {name: this.$t(`${fieldPrefix}.maxPower`)},
        onlineStatusName: {name: this.$t(`${fieldPrefix}.onlineStatusName`)},
        serviceStatusName: {name: this.$t(`${fieldPrefix}.serviceStatusName`)},
        useScopeName: {
          name: this.$t(`${fieldPrefix}.useScopeName`),
          placeholder: this.$t(`${formPrefix}.useScopeName.placeholder`),
          required: this.$t(`${formPrefix}.useScopeName.checkFail.required`),
        },
        enableName: {
          name: this.$t(`${fieldPrefix}.enableName`),
          placeholder: this.$t(`${formPrefix}.enableName.placeholder`),
          required: this.$t(`${formPrefix}.enableName.checkFail.required`),
        },
        remark: {
          name: this.$t(`${fieldPrefix}.remark`),
          placeholder: this.$t(`${formPrefix}.remark.placeholder`),
          maxSize: this.$t(`${formPrefix}.remark.checkFail.maxSize`),
        },
        modifierName: {name: this.$t(`${fieldPrefix}.modifierName`),},
        modifyTime: {name: this.$t(`${fieldPrefix}.modifyTime`),},
        creatorName: {name: this.$t(`${fieldPrefix}.creatorName`),},
        createTime: {name: this.$t(`${fieldPrefix}.createTime`),},
        operate: {name: this.$t(`${fieldPrefix}.operate`),},
        confirm: {
          confirmMessage: this.$t(`${confirmPrefix}.confirmMessage`),
          title: this.$t(`${confirmPrefix}.title`),
          cancelMessage: this.$t(`${confirmPrefix}.cancelMessage`),
        },
      }
    },
    chargePileRules() {

      return {
        orgId: [{required: true, message: this.field.orgName.required, trigger: 'change'}],
        stationId: [{required: true, message: this.field.stationName.required, trigger: 'change'}],
        chargePileName: [{required: true, message: this.field.chargePileName.required, trigger: 'blur'},
          {min: 1, max: 30, message: this.field.chargePileName.maxSize, trigger: 'blur'}],
        brandId: [{required: true, message: this.field.brandName.required, trigger: 'change'}],
        brandModelId: [{required: true, message: this.field.brandModelName.required, trigger: 'change'}],
        useScope: [{required: true, message: this.field.useScopeName.required, trigger: 'change'}],
        enable: [{required: true, message: this.field.enableName.required, trigger: 'change'}],
        remark: [{min: 0, max: 300, message: this.field.remark.maxSize, trigger: 'blur'}],
      }
    },
    chargePileDrawer() {
      const prefix = 'chargePileManagePage.chargePileDrawer';
      return {
        saveTitle: this.$t(`${prefix}.saveTitle`),
        updateTitle: this.$t(`${prefix}.updateTitle`),
      }
    },
    gunDrawer() {
      const prefix = 'chargePileManagePage.gunDrawer';
      return {
        title: this.$t(`${prefix}.title`),
        baseInfoTitle: this.$t(`${prefix}.baseInfoTitle`),
        settingTitle: this.$t(`${prefix}.settingTitle`),
        sign: this.$t(`${prefix}.sign`),
        addGun: {
          title: this.$t(`${prefix}.addGun.title`),
          warnMessage: this.$t(`${prefix}.addGun.warnMessage`),
          placeholder: this.$t(`${prefix}.addGun.placeholder`),
          infoMessage: this.$t(`${prefix}.addGun.infoMessage`),
        },
        gunCode: this.$t(`${prefix}.gunCode`),
        gunName: {
          name: this.$t(`${prefix}.gunName.name`),
          placeholder: this.$t(`${prefix}.gunName.placeholder`),
          required: this.$t(`${prefix}.gunName.required`),
          maxSize: this.$t(`${prefix}.gunName.maxSize`),
        }
      };
    },
    chargeRuleDialog() {
      const prefix = 'chargePileManagePage.chargeRuleDialog';
      return {
        title: this.$t(`${prefix}.title`),
        chargeRule: {
          placeholder: this.$t(`${prefix}.chargeRule.placeholder`),
          required: this.$t(`${prefix}.chargeRule.required`),
        },
      }
    }
  },
  data() {
    return {
      defaultPageSizes,
      table: {
        showTable: false,
        page: {
          ...defaultPage
        },
        config: {
          columns: [...chargePileTableSettings],
          rowKey: 'id',
        },
        searchCondition: {
          orgId: null,
          stationId: null,
          chargePileCode: '',
          chargePileName: '',
          enable: null,
        },
        buttons: [],
        chargePile: {},
        /**
         * 机构下拉数据
         */
        orgPullDownData: [],
        /**
         * 充电站下拉数据
         */
        chargeStationPullDownData: [],
        /**
         * 启用禁用下拉列表数据
         */
        enablePullDownData: [],
        /**
         * 充电桩品牌下拉数据
         */
        chargePileBrandPullDownData: [],
        /**
         * 初始化充电桩品牌型号所有数据
         */
        initChargePileBrandModelPullDownData: [],
      },
      /**
       * 充电桩的Drawer
       */
      drawer: {
        title: '',
        visible: false,
        chargePileForm: {
          enable: 1,
          stationId: null,
          useScope: 0,
          brandModelId: null,
        },
        /**
         * 充电桩类型下拉数据
         */
        // chargePileTypeData: [],
        /**
         * 充电桩协议下拉数据
         */
        // protocolTypeData: [],
        /**
         * 充电规则下拉数据
         */
        chargeRuleData: [],
        /**
         * 使用范围下拉数据
         */
        useScopeData: [],
        /**
         * 充电站下拉数据
         */
        chargeStationPullDownData: [],
        /**
         * 充电桩品牌型号下拉数据
         */
        chargePileBrandModelPullDownData: [],
      },
      /**
       * 充电桩枪口抽屉
       */
      chargeGunDrawer: {
        visible: false,
        chargeGunForm: {
          chargePileId: null,
          chargeGuns: [],
        },
        chargePile: {},
      },
      dialog: {
        visible: false,
        chargeRule: {
          id: null,
          chargeRuleId: null,
          chargePileCode: null,
        },
        chargeRulePullDownData: [],
      }
    }
  },
  watch: {},
  mounted() {
    this.initCurrentPageButton();
    const websocket = useWebsocket();
    websocket.bindOnMessageHandler(wsTopic.MANUAL_OPERATION_ACCOUNT_MODEL_TOPIC, this.onMessageHandleManualOperationAccountModel);
  },
  methods: {
    /**
     *
     */
    onMessageHandleManualOperationAccountModel(websocketMessage) {
      let contentStr = websocketMessage.content;
      if (contentStr) {
        let content = JSON.parse(contentStr);
        if (content.status) {
          message.success({message: content.content});
        } else {
          message.error({message: content.content});
        }
      }
    },
    /**
     * 处理充电桩品牌选择
     * 初始化品牌型号下拉数据
     * @param e 当前选项的值
     */
    handleSelectBrandChange(e) {
      this.drawer.chargePileForm.brandModelId = null;
      this.drawer.chargePileBrandModelPullDownData = validateNull(e) ? [] : this.getBrandModels(e);
    },
    /**
     * 通过品牌ID过滤出品牌型号下拉数据
     * @param brandId 品牌ID
     */
    getBrandModels(brandId) {
      if (validateNull(brandId)) {
        return [];
      }
      return this.table.initChargePileBrandModelPullDownData.filter(brandModel => brandModel.brandId === brandId);
    },
    /**
     * 处理Dialog提交
     */
    async handleDialogSubmit() {
      if (validateNull(this.dialog.chargeRule.chargeRuleId)) {
        message.error({message: this.chargeRuleDialog.chargeRule.required});
        return;
      }
      await chargePileSetChargeRule(this.dialog.chargeRule);
      await this.initChargePileData();
      this.dialog.visible = false;
    },
    /**
     * 处理Dialog关闭
     */
    handleDialogClose() {
      this.dialog.chargeRule = {
        orgId: null,
        chargeRuleId: null,
        chargePileCode: null,
      };
      this.drawer.visible = false;
    },
    /**
     * 添加充电桩桩口
     */
    addChargeGun() {
      let {chargeGuns} = this.chargeGunDrawer.chargeGunForm;
      if (chargeGuns.length >= this.chargeGunDrawer.chargePile.gunNumber) {
        message.warning({message: this.gunDrawer.addGun.warnMessage});
        return;
      }
      chargeGuns.push({
        id: null,
        chargeGunCode: null,
        chargeGunName: '',
      });
    },
    /**
     * 移除充电桩桩口
     * @param item 充电桩桩口实体数据
     */
    removeChargeGun(item) {
      let {chargeGuns} = this.chargeGunDrawer.chargeGunForm;
      let index = chargeGuns.indexOf(item);
      if (index !== -1) {
        chargeGuns.splice(index, 1);
      }
    },
    /**
     * 处理充电桩桩口表单提交
     */
    handleChargeGunFormSubmit() {
      this.$refs.chargeGunForm.validate(async (valid) => {
        if (valid) {
          let chargeGunForm = this.chargeGunDrawer.chargeGunForm;
          await saveOrUpdateChargeGuns(chargeGunForm);
          this.chargeGunDrawer.visible = false;
        }
      });
    },
    /**
     * 处理关闭充电桩枪口Drawer
     */
    handleClosePilePortDrawer() {
      this.chargeGunDrawer.chargeGunForm = {
        chargePileId: null,
        chargeGuns: [],
      };
      this.chargeGunDrawer.chargePile = {};
    },
    /**
     * 删除充电桩操作
     * @param row 当前行数据
     */
    handleDeleteChargePile(row) {
      this.$confirm(this.$t('chargePileManagePage.confirm.confirmMessage', {chargePileName: row.chargePileName}), this.field.confirm.title, {
        confirmButtonText: this.btn.confirm,
        cancelButtonText: this.btn.cancel,
        type: 'warning'
      }).then(async () => {
        await deleteChargePile({id: row.id, chargePileCode: row.chargePileCode});
        await this.initChargePileData();
      }).catch(() => {
        message.info({message: this.field.confirm.cancelMessage});
      });
    },
    /**
     * 处理充电桩表单提交
     */
    handleChargePileFormSubmit() {
      this.$refs.chargePileForm.validate(async (valid) => {
        if (valid) {
          let {code} = await saveOrUpdateChargePile(this.drawer.chargePileForm);
          if (hasSuccess(code)) {
            this.drawer.visible = false;
            this.initChargePileData();
          }
        }
      });
    },
    /**
     * 处理改变机构下拉时
     * 选了机构之后，则放开禁用，并显示当前机构的充电站
     */
    handleChangeOrgPullDown(e) {
      // 选择时清空充电站信息
      this.drawer.chargePileForm.stationId = null;
      this.drawer.chargeStationPullDownData = validateNull(e) ? [] : this.getChargeStations(e);
    },
    /**
     * 获取机构下的所有充电站数据
     * @param orgId 机构ID
     */
    getChargeStations(orgId) {
      if (validateNull(orgId)) {
        return [];
      }
      return this.table.chargeStationPullDownData
          .filter(station => station.orgId === orgId);
    },
    /**
     * 处理关闭Drawer
     */
    handleCloseDrawer() {
      this.drawer.visible = false;
      this.$refs.chargePileForm.resetFields();
      this.drawer.chargePileForm = {
        enable: 1,
        stationId: null,
        useScope: 0,
        brandModelId: null,
      };
      this.drawer.chargeStationPullDownData = [];
      this.drawer.chargePileBrandModelPullDownData = [];
    },
    /**
     * 处理按钮操作
     * @param btnCode 按钮编号
     * @param row 当前行数据
     */
    async handleOperate(btnCode, row) {
      switch (btnCode) {
        case 'add':
          this.drawer.title = this.chargePileDrawer.saveTitle;
          this.drawer.visible = true;
          break;
        case 'update':
          this.drawer.title = this.chargePileDrawer.updateTitle;
          this.drawer.chargePileForm = Object.assign({}, row);
          // 回填表单充电站下拉数据
          this.drawer.chargeStationPullDownData = this.getChargeStations(row.orgId);
          this.drawer.chargePileBrandModelPullDownData = this.getBrandModels(row.brandId);
          this.drawer.visible = true;
          break;
        case 'delete':
          this.handleDeleteChargePile(row);
          break;
        case 'set-charge-rule':
          let {data: chargeRulePullDownData} = await loadChargeRuleOptionList({orgId: row.orgId});
          this.dialog.chargeRulePullDownData = chargeRulePullDownData;
          this.dialog.chargeRule.id = row.id;
          this.dialog.chargeRule.chargePileCode = row.chargePileCode;
          // 判断当前行的规则ID是否在数组里面,存在则回显,不存在则不显示(主要避免规则被删除回显失败的问题)
          if (this.dialog.chargeRulePullDownData.some(rule => rule.id === row.chargeRuleId)) {
            this.dialog.chargeRule.chargeRuleId = row.chargeRuleId
          } else {
            this.dialog.chargeRule.chargeRuleId = null;
          }
          this.dialog.visible = true;
          break;
        case 'set-charge-pile-port':
          let {data: chargeGuns} = await listChargeGuns({chargePileId: row.id});
          // 判断查询的枪数据是否为空,为空则使用当前品牌型号的枪数量填充默认数据,否则使用已保存的数据
          if (validateNull(chargeGuns)) {
            /*let {chargeGuns} = this.chargeGunDrawer.chargeGunForm;
            for (let i = 1; i <= row.gunNumber; i++) {
              chargeGuns.push({
                id: null,
                chargeGunCode: null,
                chargeGunName: `枪口${i}`,
              });
            }*/
          } else {
            this.chargeGunDrawer.chargeGunForm.chargeGuns = chargeGuns;
          }
          this.chargeGunDrawer.chargePile = Object.assign({}, row);
          this.chargeGunDrawer.chargeGunForm.chargePileId = row.id;
          this.chargeGunDrawer.visible = true;
          break;
        default:
          break;
      }
    },
    /**
     * 处理更改页面
     */
    handleChangePage(hasSearch) {
      if (hasSearch) {
        this.table.page.current = 1;
      }
      this.initChargePileData();
    },
    /**
     * 初始化充电桩数据
     */
    async initChargePileData() {
      const show = showData(this.table.buttons, positions.view);
      if (!show) {
        this.$message.error(this.public.unauthorizedView);
        return;
      }
      let {code, data} = await chargePilePaging({
        current: this.table.page.current,
        size: this.table.page.size,
        ...this.table.searchCondition
      });
      if (hasSuccess(code)) {
        this.table.page = {
          current: data.current,
          size: data.size,
          total: data.total,
          records: data.records
        }
      }
    },
    /**
     * 初始化页面按钮
     */
    async initCurrentPageButton() {
      let whetherOrNot = 'whether_or_not';
      // let chargePileType = 'charge_pile_type';
      let useScope = 'use_scope';
      let {code, data} = await loadCurrentPageTheButtons({route: this.$route.path});
      if (hasSuccess(code)) {
        this.table.buttons = data;
        this.table.showTable = true;
        // 初始化字典数据
        let {
          code: dictDataCode,
          data: dictData
        } = await loadDataDictByCodes([whetherOrNot/*, chargePileType*/, useScope]);
        if (hasSuccess(dictDataCode)) {
          // 处理字典数据
          this.table.enablePullDownData = dictData[whetherOrNot];
          // this.drawer.chargePileTypeData = dictData[chargePileType];
          this.drawer.useScopeData = dictData[useScope];
        }
        // 初始化充电桩数据
        this.initChargePileData();
        // 初始化机构下拉数据
        let {data: orgPullDownData} = await listOrgSelect();
        this.table.orgPullDownData = orgPullDownData;
        // 初始化充电站下拉数据
        let {data: chargeStationPullDownData} = await chargeStationPullDownList();
        this.table.chargeStationPullDownData = chargeStationPullDownData;
        // 初始化充电桩品牌数据
        let {data: chargePileBrandPullDownData} = await chargePileBrandPullDownList();
        this.table.chargePileBrandPullDownData = chargePileBrandPullDownData;
        // 初始化充电桩品牌型号数据
        let {data: chargePileBrandModelPullDownData} = await chargePileBrandModelPullDown();
        this.table.initChargePileBrandModelPullDownData = chargePileBrandModelPullDownData;
        // 初始化充电桩协议数据
        // let {data: protocolTypeData} = await listChargePileProtocolType();
        // this.drawer.protocolTypeData = protocolTypeData;
      }
    }
  }
}

</script>

<style scoped lang="scss">

.data-main-wrapper {
  .item-width {
    width: 80%;
  }

  .charge-drawer {
    height: 90%;
    overflow-y: auto;
  }

  .charge-drawer::-webkit-scrollbar {
    display: none;
  }

  .charge-rule-dialog {
    height: 230px;
    overflow-y: auto;
  }

  .charge-rule-dialog::-webkit-scrollbar {
    display: none;
  }
}
</style>
