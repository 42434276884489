import {get, post} from '@/utils/request'

/**
 * 用户登录
 */
export const login = (params) => {
    return post({url: '/login-web', data: {...params}}, false);
}

/**
 * 用户注销登录
 */
export const logout = () => {
    return post({url: '/logout'});
}

/**
 * 分页查询用户信息
 */
export const listWebUserPage = (params = {}) => {
    return get({url: '/web-user/list-page', params: {...params}});
}

/**
 * 新增或修改用户信息
 * @param params 用户信息
 */
export const saveOrUpdate = (params) => {
    return post({url: '/web-user/save-or-update', data: {...params}});
}

/**
 * 重置用户密码
 * @param params 用户ID
 */
export const resetPassword = (params) => {
    return post({url: '/web-user/reset-password', data: {...params}});
}

/**
 * 设置用户角色
 * @param params 用户ID和角色ID
 */
export const setRole = (params) => {
    return post({url: '/web-user/set-role', data: {...params}});
}

/**
 * 修改用户密码
 * @param params 旧密码 新密码 重复确认密码
 */
export const changeWebUserPassword = (params) => {
    return post({url: '/web-user/change-password', data: {...params}});
}

/**
 * 删除系统用户
 * @param params
 */
export const deleteWebUser = (params) => {
    return post({url: '/web-user/delete', data: {...params}});
}
