import {getAccessToken} from '@/utils/token';

let websocket;
let websocketTask;
let websocketConfig;

/**
 * 绑定消息处理函数
 * @param {string} topic 消息主题
 * @param{(data:any)=>void} handlerFunction 处理函数
 */
function bindOnMessageHandler(topic,handlerFunction){
    if(websocketTask&&websocket){
        websocketTask.set(topic,handlerFunction);
    }else {
        //尝试再次创建websocket连接
        createWebsocket('/ws/endpoint/');
        if(websocketTask&&websocket){
            websocketTask.set(topic,handlerFunction);
        }else {
            console.warn('websocket连接失败');
        }
    }
}


/**
 * 创建websocket连接
 * @param {string|URL} url 连接地址
 */
export function createWebsocket(url) {
    const authToken = getAccessToken();
    if(!websocket&&authToken){
        url = process.env.VUE_APP_WEBSOCKET_API_BASE_URL+url+`${authToken}`;
        websocket = new WebSocket(url);
        websocket.onmessage = onMessage;
        websocketConfig = url;
    }
    if(!websocketTask){
       websocketTask = new Map();
    }
}

/**
 * 消息处理函数
 * @param {MessageEvent} event 消息对象
 * @returns {void}
 */
function onMessage(event) {
    const websocketMessage = JSON.parse(event.data);
    const topic = websocketMessage.topic;
    if(topic){
        const handlerFunction = websocketTask.get(topic);
        if(handlerFunction){
            handlerFunction(websocketMessage);
        }
    }
}

/**
 * websocketA工具
 * @returns {{bindOnMessageHandler: bindOnMessageHandler}} 绑定消息处理函数
 */
export function useWebsocket(){
    return {
        bindOnMessageHandler
    }
}
