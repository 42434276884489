
/**
 * 获取对应位置的按钮
 * 
 * */ 

export function obtainPositionButtons(arr,position){

   if(arr===undefined||arr===null){
      return [];
   }
   
   return arr.filter(item => item.position == position);
}

/**
 * 判断是否允许查看数据
 * @param {*} arr 
 * @param {*} position 
 * @returns 
 */
export function showData(arr,position){
   const view = obtainPositionButtons(arr,position);
   return view&&view.length>0
}