<template>
    <div class="i-cascader">
        <el-cascader v-bind="$attrs" v-on="$listeners">
        
        </el-cascader>

    </div>
</template>

<script>

export default{

    data(){

        return{

        }
    },
    methods:{

    }
}

</script>