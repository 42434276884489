import axios from "axios";
import {message} from '@/utils/message';
import {validateNull} from "@/utils/tool";
import {getAccessToken, removeAccessToken, setAccessToken} from "@/utils/token";
import router from "@/router";

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 3 * 100000
})

//设置请求拦截器

httpClient.interceptors.request.use(config => {

    // 添加token
    const accessToken = getAccessToken();
    if (!validateNull(accessToken)) {
        config.headers['Authorization'] = 'bearer ' + accessToken;
    }

    return config;
});

//响应拦截器
httpClient.interceptors.response.use(resp => {

    const result = resp.data;
    // console.log('result',result);
    if (resp.status && resp.status === 200 && result.code === 500) {
        message.error({message: result.message});
        return result;
    }
    if (result.message) {
        message.success({message: result.message});
    }
    // 刷新token
    let refreshToken = resp.headers['energy-refresh-token'];
    if (!validateNull(refreshToken)) {
        setAccessToken(refreshToken);
    }
    return result;
}, error => {
    console.log('result',error.response.data);
    const status = error.response.status;
    const errMsg = error.response.data.message;
    if(status === 500 ){
        message.error({message:errMsg});
    }else if (status === 504 || status === 404) {
        message.error({message: '服务器被吃了o(╯□╰)o'});
    } else if (status === 403) {
        message.error({message: '权限不足, 请联系管理员'});
    } else if (status === 401) {
        message.error({message: errMsg ? errMsg : '尚未登录, 请登录'});
        removeAccessToken();
        router.replace('/login').catch(err => err);//catch掉路由跳转时跳转的时当前路由时抛出的异常
    } else {
        if (message) {
            message.error({message: errMsg});
        } else {
            message.error({message: '未知错误'});
        }
    }

    return error.response.data;
})

export default httpClient;
