import {get, post} from '@/utils/request';

const prefix = '/order/client-user-charging';

/**
 * 分页查询客户端用户充电订单
 */
export const clientUserChargingOrderPaging = (params = {}) => {
    return get({url: `${prefix}/list-paging`, params: params});
}
