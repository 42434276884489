<template>
  <div class="data-main-wrapper">
    <i-table v-if="table.showTable" :config="table.config" :page="table.page" :page-sizes="defaultPageSizes" @change-page="handleChangePage">
      <template slot="search-condition">
        <el-form :inline="true" size="small">
          <el-form-item :label="field.brandName.name + ':'">
            <el-input clearable v-model="searchCondition.name" :placeholder="field.brandName.placeholder"></el-input>
            <!--            <el-select v-model="searchCondition.brandId" clearable placeholder="请选择充电桩品牌">
                          <el-option
                              v-for="(item, index) in chargePileBrandPullDownData"
                              :key="index"
                              :label="item.brandName"
                              :value="item.id">
                          </el-option>
                        </el-select>-->
          </el-form-item>
        </el-form>
      </template>
      <!--搜索按钮-->
      <template slot="search-button">
        <el-button type="primary" size="small" @click="handleChangePage(true)">{{ btn.search }}</el-button>
      </template>

      <!--表头-->
      <template slot="header" slot-scope="scope">
        {{ field[scope.column.property].name }}
      </template>

      <!--全局左-->
      <template slot="overall-left" slot-scope="scope">
        <el-button
            v-for="(overallLeftButton,index) in btnPosition.obtainPositionButtons(buttons,btnPosition.positions.left)"
            :key="index" type="primary" size="small" :icon="overallLeftButton.icon"
            @click="operateHandler(overallLeftButton.code)">{{ overallLeftButton.name }}
        </el-button>
      </template>

      <!--数据行内-->
      <template slot="row_operate" slot-scope="scope">
        <el-button
            type="text"
            :icon="rowButton.icon"
            size="mini"
            v-for="(rowButton,index) in btnPosition.obtainPositionButtons(buttons,btnPosition.positions.row) "
            :key="index"
            @click="operateHandler(rowButton.code,scope.row)"
        >
          {{ rowButton.name }}
        </el-button>
      </template>
    </i-table>

    <i-drawer :visible.sync="brandDrawer.visible" :title="brandDrawer.title"
              size="30%" @close="handleCloseBrandDrawer">
      <el-form :model="brandDrawer.chargePileBrandForm" ref="chargePileBrandForm" label-width="100px" size="small"
               label-position="left" :rules="brandFormRules">
        <el-form-item :label="field.brandName.name + ':'" prop="brandName">
          <el-input v-model="brandDrawer.chargePileBrandForm.brandName"
                    :placeholder="field.brandName.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="field.protocolTypeCode.formName + ':'" prop="protocolTypeCode">
          <el-select v-model="brandDrawer.chargePileBrandForm.protocolTypeCode" clearable
                     :placeholder="field.protocolTypeCode.placeholder">
            <el-option
                v-for="(item, index) in brandProtocolTypePullDownData"
                :key="index"
                :label="item.name"
                :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="field.remark.name + ':'" prop="remark">
          <el-input class="item-width" v-model="brandDrawer.chargePileBrandForm.remark"
                    type="textarea"
                    :rows="2"
                    :placeholder="field.remark.placeholder"></el-input>
        </el-form-item>
      </el-form>

      <template slot="footer">
        <el-button type="primary" @click="handleBrandFormSubmit">{{ btn.submit }}</el-button>
        <el-button @click="brandDrawer.visible = false">{{ btn.cancel }}</el-button>
      </template>
    </i-drawer>
  </div>
</template>

<script>
import {defaultPage,defaultPageSizes} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable.vue';
import IDrawer from '@/components/i-drawer/IDrawer.vue';
import {chargePileBrandTableSettings} from '@/config/table-settings';
import {obtainPositionButtons, showData} from '@/utils/power';
import {positions} from '@/config/button-position-settings';
import {loadCurrentPageTheButtons} from '@/api/system/menu';
import {hasSuccess} from '@/utils/tool';
import {
  chargePileBrandPaging,
  listChargePileBrandProtocolType,
  deleteChargePileBrand,
  addOrUpdateChargePileBrand
} from '@/api/business/charge-pile-brand';
import {message} from "@/utils/message";


export default {

  components: {
    'i-table': ITable,
    'i-drawer': IDrawer,
  },
  data() {
    return {
      defaultPageSizes,
      table: {
        showTable: false,
        page: {
          ...defaultPage
        },
        config: {
          columns: [...chargePileBrandTableSettings],
          rowKey: 'id'
        },
      },
      buttons: [],
      searchCondition: {},
      brandProtocolTypePullDownData: [],
      brandDrawer: {
        visible: false,
        title: '',
        chargePileBrandForm: {},
      }
    }
  },
  computed: {
    /**
     * 获取页面按钮和按钮位置
     */
    btnPosition: {
      get: function () {
        return {obtainPositionButtons, positions}
      }
    },
    public() {
      return {
        unauthorizedView: this.$t('public.resultMessages.unauthorizedView'),
      }
    },
    btn() {
      return {
        search: this.$t('public.button.search'),
        submit: this.$t('public.button.submit'),
        cancel: this.$t('public.button.cancel'),
      }
    },
    field() {
      const fieldPrefix = 'chargePileBrandManagePage.field';
      const formPrefix = 'chargePileBrandManagePage.form';
      return {
        id: {name: this.$t(`${fieldPrefix}.id`)},
        brandName: {
          name: this.$t(`${fieldPrefix}.brandName`),
          placeholder: this.$t(`${formPrefix}.brandName.placeholder`),
          required: this.$t(`${formPrefix}.brandName.checkFail.required`),
          maxSize: this.$t(`${formPrefix}.brandName.checkFail.maxSize`)
        },
        protocolTypeCode: {
          name: this.$t(`${fieldPrefix}.protocolTypeCode`),
          formName: this.$t(`${formPrefix}.protocolTypeCode.formName`),
          placeholder: this.$t(`${formPrefix}.protocolTypeCode.placeholder`),
          required: this.$t(`${formPrefix}.protocolTypeCode.checkFail.required`)
        },
        protocolTypeName: {
          name: this.$t(`${fieldPrefix}.protocolTypeName`),
        },
        remark: {
          name: this.$t(`${fieldPrefix}.remark`),
          placeholder: this.$t(`${formPrefix}.remark.placeholder`),
          maxSize: this.$t(`${formPrefix}.remark.checkFail.maxSize`)
        },
        modifierName: {name: this.$t(`${fieldPrefix}.modifierName`)},
        modifyTime: {name: this.$t(`${fieldPrefix}.modifyTime`)},
        creatorName: {name: this.$t(`${fieldPrefix}.creatorName`)},
        createTime: {name: this.$t(`${fieldPrefix}.createTime`)},
        operate: {name: this.$t(`${fieldPrefix}.operate`)},
      }
    },
    brandFormRules() {
      return {
        brandName: [
          {required: true, message: this.field.brandName.required, trigger: 'blur'},
          {min: 1, max: 30, message: this.field.brandName.maxSize, trigger: 'blur'}
        ],
        protocolTypeCode: [{required: true, message: this.field.brandName.required, trigger: 'change'}],
        remark: [{min: 0, max: 300, message: this.field.remark.maxSize, trigger: 'blur'}],
      }
    },
    operateInfo() {
      const prefix = 'chargePileBrandManagePage.operateInfo';
      return {
        addTitle: this.$t(`${prefix}.addTitle`),
        updateTitle: this.$t(`${prefix}.updateTitle`),
        deleteTitle: this.$t(`${prefix}.deleteTitle`),
        confirmMessage: this.$t(`${prefix}.deleteConfirm.confirmMessage`),
        cancelMessage: this.$t(`${prefix}.deleteConfirm.cancelMessage`),
      }
    }
  },
  mounted() {
    this.initCurrentPageButton();
  },
  methods: {
    handleDeleteBrand(row) {
      this.$confirm(this.$t('chargePileBrandManagePage.operateInfo.deleteConfirm.confirmMessage', {brandName: row.brandName}), this.operateInfo.deleteTitle, {
        confirmButtonText: this.btn.confirm,
        cancelButtonText: this.btn.cancel,
        type: 'warning'
      }).then(async () => {
        await deleteChargePileBrand({id: row.id});
        await this.initChargePileBrandData();
      }).catch(() => {
        message.info({message: this.operateInfo.cancelMessage});
      });
    },
    /**
     * 处理品牌表单提交
     */
    handleBrandFormSubmit() {
      this.$refs.chargePileBrandForm.validate(async (valid) => {
        if (valid) {
          let {code} = await addOrUpdateChargePileBrand(this.brandDrawer.chargePileBrandForm);
          if (hasSuccess(code)) {
            this.brandDrawer.visible = false;
            this.initChargePileBrandData();
          }
        }
      });
    },
    /**
     * 处理抽屉关闭
     */
    handleCloseBrandDrawer() {
      this.brandDrawer.visible = false;
      this.$refs.chargePileBrandForm.resetFields();
      this.brandDrawer.chargePileBrandForm = {};
    },
    /**
     * 行内按钮操作处理
     * @param code
     * @param row
     */
    operateHandler(code, row) {
      switch (code) {
        case 'add':
          this.brandDrawer.visible = true;
          this.brandDrawer.title = this.operateInfo.addTitle;
          break;
        case 'update':
          this.brandDrawer.title = this.operateInfo.updateTitle;
          this.brandDrawer.chargePileBrandForm = Object.assign({}, row);
          this.brandDrawer.visible = true;
          break;
        case 'delete':
          this.handleDeleteBrand(row);
          break;
        default:
          break;
      }
    },
    handleChangePage(hasSearch) {
      if (hasSearch) {
        this.table.page.current = 1;
      }
      this.initChargePileBrandData();
    },
    /**
     * 初始化充电桩品牌协议类型
     */
    async initChargePileBrandModelPullDownData() {
      let {code, data} = await listChargePileBrandProtocolType();
      if (hasSuccess(code)) {
        this.brandProtocolTypePullDownData = data;
      }
    },
    /**
     * 初始化数据权限数据
     */
    async initChargePileBrandData() {
      const show = showData(this.buttons, positions.view);
      if (!show) {
        this.$message.error(this.public.unauthorizedView);
        return;
      }
      let {code, data} = await chargePileBrandPaging({
        current: this.table.page.current,
        size: this.table.page.size,
        ...this.searchCondition
      });
      if (hasSuccess(code)) {
        this.table.page = {
          current: data.current,
          size: data.size,
          total: data.total,
          records: data.records,
        };
      }
    },
    /**
     * 初始化页面按钮数据
     */
    async initCurrentPageButton() {
      let {code, data} = await loadCurrentPageTheButtons({route: this.$route.path});
      if (hasSuccess(code)) {
        // 初始化页面按钮
        this.buttons = data;
        this.table.showTable = true;
        await this.initChargePileBrandData();
        await this.initChargePileBrandModelPullDownData();
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
