<template>
  <div class="user-wrapper">
    <!--左侧机构树-->
    <div class="user-left">
      <div class="user-left-tree">
        <el-input
            :placeholder="$t('public.filter.treePlaceholder')"
            v-model="orgTreeFilterText"
            size="mini">
        </el-input>
        <el-tree
            class="filter-tree"
            node-key="id"
            @node-click="handleNodeClick"
            :data="orgTreeData"
            :props="orgTreeProps"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            :highlight-current="true"
            ref="tree">
        </el-tree>
      </div>
    </div>
    <!--右侧用户数据表格-->
    <div class="user-right">
      <i-table v-if="showTable" :config="config" :page="page" @change-page="handlerSearch" :page-sizes="defaultPageSizes">
        <template slot="search-condition">
          <el-form :inline="true" :model="userSearchCondition" size="small">
            <el-form-item :label="$t('webUserManagePage.field.username') +':'">
              <el-input clearable v-model="userSearchCondition.username"
                        :placeholder="$t('webUserManagePage.form.username.placeholder')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('webUserManagePage.field.nickname') + ':'">
              <el-input clearable v-model="userSearchCondition.nickname"
                        :placeholder="$t('webUserManagePage.form.nickname.placeholder')"></el-input>
            </el-form-item>
          </el-form>
        </template>
        <!--搜索按钮-->
        <template slot="search-button">
          <el-button type="primary" size="small" @click="handlerSearch(true)" icon="el-icon-search">
            {{ this.$t('public.button.search') }}
          </el-button>
        </template>
        <!--全局操作-->
        <template slot="overall-left" slot-scope="scope">
          <el-button type="primary"
                     v-for="(btn, index) in obtainPositionButtons(button.childNode, positions.left)"
                     :key="index"
                     size="small"
                     :icon="btn.icon" @click="operateHandler(btn.code, scope.row)">
            {{ btn.name }}
          </el-button>
        </template>
        <!--表头-->
        <template slot="header" slot-scope="scope">
          {{ $t(`webUserManagePage.field.${scope.column.property}`) }}
        </template>
        <!--头像列-->
        <template slot="row_avatar" slot-scope="scope">
          <el-avatar :src="scope.row[scope.column.property]"></el-avatar>
        </template>
        <!--行内操作-->
        <template slot="row_operate" slot-scope="scope">
          <el-button type="text"
                     v-for="(btn, index) in obtainPositionButtons(button.childNode, positions.row)"
                     :key="index"
                     size="mini"
                     :icon="btn.icon"
                     @click="operateHandler(btn.code, scope.row)">
            {{ btn.name }}
          </el-button>
        </template>
      </i-table>
    </div>

    <!--抽屉-->
    <i-drawer :title="drawer.drawerTitle" size="30%" :visible.sync="drawer.drawerVisible" @close="drawerClose">
      <el-form :model="userForm" :rules="userRules" ref="userForm" size="small" label-position="right"
               label-width="100px">
        <el-form-item :label="$t('webUserManagePage.field.orgId') +':'" prop="orgId">
          <el-select v-model="userForm.orgId" clearable filterable
                     :placeholder="$t('webUserManagePage.form.orgId.placeholder')">
            <el-option
                v-for="(item, index) in orgSelectOptions"
                :key="index"
                :label="item.orgName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('webUserManagePage.field.username') +':'" prop="username">
          <el-input v-model="userForm.username" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('webUserManagePage.field.password') +':'" prop="password" v-if="!userForm.id">
          <el-input v-model="userForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item :label="$t('webUserManagePage.field.nickname') + ':'" prop="nickname">
          <el-input v-model="userForm.nickname" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('webUserManagePage.field.mobile') + ':'" prop="mobile">
          <el-input v-model="userForm.mobile" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('webUserManagePage.field.avatar') + ':'" prop="avatar">
          <el-avatar
              :src="userForm.avatar ? 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png' : userForm.avatar"></el-avatar>
        </el-form-item>
        <el-form-item :label="$t('webUserManagePage.field.birthday') + ':'">
          <el-date-picker
              clearable
              v-model="userForm.birthday"
              type="date"
              :placeholder="$t('webUserManagePage.form.birthday.placeholder')"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('webUserManagePage.field.email') + ':'" prop="email">
          <el-input clearable v-model="userForm.email"></el-input>
        </el-form-item>
        <el-form-item :label="$t('webUserManagePage.field.genderName') + ':'">
          <el-radio-group v-model="userForm.gender">
            <el-radio :label="0">男</el-radio>
            <el-radio :label="1">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('webUserManagePage.field.enableName') + ':'" prop="enable">
          <el-switch
              v-model="userForm.enable"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text="$t('public.button.enable')"
              :inactive-text="$t('public.button.disabled')"
              :active-value="1"
              :inactive-value="0">
          </el-switch>
        </el-form-item>
        <el-form-item :label="$t('webUserManagePage.field.loginTypeName') + ':'" prop="loginType">
          <el-radio-group v-model="userForm.loginType">
            <el-radio disabled :label="1">Web</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <!--抽屉底部处理-->
      <template slot="footer">
        <el-button type="primary" size="small" @click="handleSubmitUserForm">{{ $t('public.button.submit') }}
        </el-button>
        <el-button size="small" @click="drawer.drawerVisible = false">{{ $t('public.button.cancel') }}</el-button>
      </template>
    </i-drawer>

    <!--对话框-->
    <i-dialog :title="roleSelect.dialogTitle"
              :visible.sync="roleSelect.dialogVisible"
              @close="handleClearRoleSelect"
              width="20%">
      <div style="height: 300px;">
        <el-select v-model="roleSelect.roleId" clearable filterable
                   :placeholder="$t('webUserManagePage.form.roleId.placeholder')">
          <el-option
              v-for="(item, index) in roleSelect.roles"
              :key="index"
              :label="item.alias"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <template slot="footer">
        <el-button type="primary" size="small" @click="submitSetRole">{{ $t('public.button.submit') }}</el-button>
        <el-button size="small" @click="roleSelect.dialogVisible = false">{{ $t('public.button.cancel') }}</el-button>
      </template>
    </i-dialog>


  </div>
</template>

<script>
import {defaultPage,defaultPageSizes} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable.vue';
import {systemUserTableSettings} from '@/config/table-settings';
import {obtainPositionButtons, showData} from '@/utils/power';
import {positions} from '@/config/button-position-settings';
import {grantTree, listOrgSelect} from '@/api/system/organization';
import {listWebUserPage, saveOrUpdate, resetPassword, setRole, deleteWebUser} from '@/api/system/web-user';
import IDrawer from '@/components/i-drawer/IDrawer.vue';
import {rules} from '@/utils/rules';
import md5 from 'js-md5';
import {getStore, removeStore} from '@/utils/store';
import {storeKey} from '@/constant/store-key';
import {hasSuccess, validateNull} from '@/utils/tool';
import {removeAccessToken} from '@/utils/token';
import {message} from '@/utils/message';
import {loadCurrentPageTheButtons} from '@/api/system/menu';
import IDialog from "@/components/i-dialog/IDialog.vue";
import {roleSelect} from '@/api/system/role';

export default {
  components: {
    'i-table': ITable,
    'i-drawer': IDrawer,
    'i-dialog': IDialog
  },
  computed: {},
  data() {
    let validUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('webUserManagePage.form.username.checkFail.required')));
      }
      if (!rules.englishNumbers.test(value)) {
        return callback(new Error(this.$t('webUserManagePage.form.username.checkFail.rule')));
      }
      return callback();
    };
    let validMobile = (rule, value, callback) => {
      if (value && !rules.phone.test(value)) {
        return callback(new Error(this.$t('webUserManagePage.form.mobile.checkFail.rule')));
      }
      return callback();
    };
    let validEmail = (rule, value, callback) => {
      if (value && !rules.email.test(value)) {
        return callback(new Error(this.$t('webUserManagePage.form.email.checkFail.rule')));
      }
      return callback();
    };
    let validAvatar = (rule, value, callback) => {
      if (value && !rules.url.test(value)) {
        return callback(new Error(this.$t('webUserManagePage.form.avatar.checkFail.rule')));
      }
      return callback();
    };
    return {
      defaultPageSizes,
      /**
       * 角色选择dialog
       */
      roleSelect: {
        dialogTitle: '',
        dialogVisible: false,
        roles: [],
        userId: null,
        roleId: null,
      },
      /**
       * 显示表格
       */
      showTable: false,
      /**
       * 选择机构的数据
       */
      orgSelectOptions: [],
      /**
       * 按钮处理
       */
      obtainPositionButtons,
      positions,
      button: {
        childNode: []
      },
      /**
       * 查询条件
       */
      userSearchCondition: {
        username: '',
        nickname: '',
        orgId: '',
      },
      /**
       * 表格配置
       */
      config: {
        columns: [...systemUserTableSettings],
        rowKey: 'id'
      },
      /**
       * 分页配置
       */
      page: {
        ...defaultPage
      },
      /**
       * 机构树
       */
      orgTreeFilterText: '',
      orgTreeData: [],
      orgTreeProps: {
        children: 'childNode',
        label: 'orgName'
      },
      /**
       * 抽屉数据
       */
      drawer: {
        drawerTitle: '',
        drawerVisible: false,
        operatorCode: '',
      },
      /**
       * 抽屉表单数据
       */
      userForm: {
        id: null,
        orgId: null,
        username: '',
        password: '',
        nickname: '',
        mobile: '',
        avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
        birthday: '',
        email: '',
        gender: 0,
        enable: 1,
        loginType: 1
      },
      userRules: {
        orgId: [{required: true, message: this.$t('webUserManagePage.form.orgId.checkFail.required'), trigger: 'change'}],
        username: [{required: true, validator: validUsername, trigger: 'blur'}],
        password: [{required: true, message: this.$t('webUserManagePage.form.password.checkFail.required'), trigger: 'blur'}],
        nickname: [{required: true, message: this.$t('webUserManagePage.form.nickname.checkFail.required'), trigger: 'blur'},
          {min: 1, max: 20, message: this.$t('webUserManagePage.form.nickname.checkFail.rule'), trigger: 'blur'}
        ],
        mobile: [{validator: validMobile, trigger: 'blur'}],
        avatar: [{validator: validAvatar, trigger: 'blur'}],
        email: [{validator: validEmail, trigger: 'blur'}],
        enable: [{required: true, message: this.$t('webUserManagePage.form.enable.checkFail.required'), trigger: 'change'}],
        loginType: [{required: true, message: this.$t('webUserManagePage.form.loginType.checkFail.required'), trigger: 'change'}],
      },
    };
  },
  watch: {
    /**
     * 监听机构树筛选内容
     * @param val 筛选内容
     */
    orgTreeFilterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted() {
    this.initCurrentPageButton();
  },
  methods: {
    /**
     * 提交设置模糊角色表单
     */
    async submitSetRole() {
      let {roleId, userId} = this.roleSelect;
      if (validateNull(roleId) || validateNull(userId)) {
        message.error({message: this.$t('webUserManagePage.form.roleId.required')});
        return;
      }
      let resp = await setRole({id: userId, roleId: roleId});
      if (hasSuccess(resp.code)) {
        this.handleClearRoleSelect();
        this.roleSelect.dialogVisible = false;
        let clearStatus = this.clearUserStore(userId);
        if (!clearStatus) {
          await this.initWebUserList();
        }
      }
    },
    /**
     * 处理关闭dialog事件
     * 清除设置用户角色的数据
     */
    handleClearRoleSelect() {
      this.roleSelect = {
        dialogVisible: false,
        roles: this.roleSelect.roles,
        roleId: null,
        userId: null,
      };
    },
    /**
     * 角色列表选择
     */
    async initRoleSelect() {
      let resp = await roleSelect();
      if (hasSuccess(resp.code)) {
        this.roleSelect.roles = resp.data;
      }
    },
    /**
     * 操作方法统一处理
     * @param code 按钮的code
     * @param row 当前行数据
     */
    operateHandler(code, row) {
      switch (code) {
        case 'add':
          this.drawer.drawerTitle = this.$t('webUserManagePage.drawer.add');
          this.drawer.drawerVisible = true;
          break;
        case 'update':
          this.drawer.drawerTitle = this.$t('webUserManagePage.drawer.update');
          this.drawer.drawerVisible = true;
          this.userForm = Object.assign({}, row);
          break;
        case 'set-user-role':
          this.roleSelect.dialogTitle = this.$t('webUserManagePage.drawer.setUserRole');
          if (validateNull(this.roleSelect.roles)) {
            message.warning({message: this.$t('webUserManagePage.dialog.warn')});
            return;
          }
          this.roleSelect.userId = row.id;
          if (this.roleSelect.roles.some(role => role.id === row.roleId)) {
            this.roleSelect.roleId = row.roleId;
          } else {
            this.roleSelect.roleId = null;
          }
          this.roleSelect.dialogVisible = true;
          break;
        case 'reset-password':
          this.resetPassword(row);
          break;
        case 'delete':
          this.deleteWebUser(row);
      }
    },
    /**
     * 删除系统用户
     */
    deleteWebUser(row) {
      let localUser = getStore({name: storeKey.user});
      if (localUser && localUser.id === row.id) {
        message.info({message: this.$t('webUserManagePage.drawer.delete.info')});
        return;
      }
      this.$confirm(this.$t('webUserManagePage.drawer.delete.confirm.warn', {username: row.username}), this.$t('webUserManagePage.drawer.delete.confirm.title'), {
        confirmButtonText: this.$t('public.button.confirm'),
        cancelButtonText: this.$t('public.button.cancel'),
        type: 'warning'
      }).then(async () => {
        await deleteWebUser({id: row.id});
        await this.initWebUserList();
      }).catch(() => {
        message.info({message: this.$t('webUserManagePage.drawer.delete.confirm.cancel')});
      });
    },
    /**
     * 重置用户密码
     */
    resetPassword(row) {
      this.$confirm(this.$t('webUserManagePage.drawer.resetPassword.confirm.warn', {username: row.username}), this.$t('webUserManagePage.drawer.resetPassword.confirm.title'), {
        confirmButtonText: this.$t('public.button.confirm'),
        cancelButtonText: this.$t('public.button.cancel'),
        type: 'warning'
      }).then(async () => {
        await resetPassword({id: row.id});
      }).catch(() => {
        message.info({message: this.$t('webUserManagePage.drawer.resetPassword.confirm.cancel')});
      });
    },
    /**
     * 处理提交用户表单
     */
    handleSubmitUserForm() {
      this.$refs.userForm.validate(async (valid) => {
        if (valid) {
          let user = Object.assign({}, this.userForm);
          if (!user.id) {
            user.password = md5(this.userForm.password);
          }
          let resp = await saveOrUpdate(user);
          if (hasSuccess(resp.code)) {
            let clearStatus = this.clearUserStore(user.id);
            if (!clearStatus) {
              await this.initWebUserList();
            }
            this.drawer.drawerVisible = false;
          }
        }
      });
    },
    /**
     * 清除用户缓存
     * @param userId 用户ID
     */
    clearUserStore(userId = '') {
      if (validateNull(userId)) return;
      let userInfo = getStore({name: storeKey.user});
      // 如果修改的自己的信息,那就重新登录
      if (!validateNull(userInfo) && userId === userInfo.id) {
        removeAccessToken();
        removeStore({name: storeKey.user});
        this.$router.replace("/login");
        return true;
      }
      return false;
    },
    /**
     * 抽屉关闭, 清空对象数据
     */
    drawerClose() {
      // 抽屉关闭前先重置表单校验状态
      this.$refs.userForm.resetFields();
      this.userForm = {
        avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
        gender: 0,
        enable: 1,
        loginType: 1
      }
    },
    /**
     * 处理查询
     */
    handlerSearch(hasSearch) {
      const show = showData(this.button.childNode, positions.view);
      if (!show) {
        this.$message.error(this.$t('public.resultMessages.unauthorizedView'));
        return;
      }
      // 是查询则初始化当前页
      if (hasSearch) {
        this.page.current = 1;
      }
      this.initWebUserList();
    },
    /**
     * 点击机构树几点触发
     * @param row 当前点击行数据
     * @param node 当前节点
     * @param vueComponent
     */
    async handleNodeClick(row, node, vueComponent) {

      // 点击全部机构则显示全部数据
      if (node.data.parentId !== 0) {
        this.userSearchCondition.orgId = row.id;
      } else {
        this.userSearchCondition.orgId = null;
      }
      await this.initWebUserList();
    },
    /**
     * 初始化机构下拉选择数据
     */
    async initListOrgSelect() {
      let resp = await listOrgSelect();
      if (hasSuccess(resp.code)) {
        this.orgSelectOptions = resp.data;
      }
    },
    /**
     * 初始化web用户列表
     */
    async initWebUserList() {
      const show = showData(this.button.childNode, positions.view);
      if (!show) {
        this.$message.error(this.$t('public.resultMessages.unauthorizedView'));
        return;
      }
      let resp = await listWebUserPage({
        size: this.page.size
        , current: this.page.current
        , ...this.userSearchCondition
      });
      if (hasSuccess(resp.code)) {
        let {records, total, size, current} = resp.data;
        this.page.records = records;
        this.page.total = total;
        this.page.size = size;
        this.page.current = current;
      }
    },
    /**
     * 初始化机构树数据
     */
    async initOrganizationTreeData() {
      let resp = await grantTree();
      if (hasSuccess(resp.code)) {
        this.orgTreeData = resp.data;
      }
    },
    /**
     * 初始化当前页面按钮
     */
    async initCurrentPageButton() {
      let resp = await loadCurrentPageTheButtons({route: this.$route.path});
      if (hasSuccess(resp.code)) {
        this.button.childNode = resp.data;
        this.showTable = true;
        this.initWebUserList();
        this.initOrganizationTreeData();
        this.initListOrgSelect();
        this.initRoleSelect();
      }
    },
    /**
     * 筛选机构树数据
     * @param value 指定过滤值
     * @param data 机构树结构数据
     * @returns {boolean}
     */
    filterNode(value, data) {
      if (!value) return true;
      if (!data) return true;
      return data.orgName.indexOf(value) !== -1;
    }
  },
}
</script>

<style lang="scss">
.user-wrapper {
  height: 100%;
  //background-color: #fff;
  display: flex;
  overflow: hidden;

  .user-left {
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-right: 8px;
    width: 16%;
    background-color: #fff;
    overflow: auto;

    .user-left-tree {
      flex: 1;
    }
  }

  .user-right {
    width: 84%;
    background-color: #fff;
  }

  /*/deep/ .el-tree > .el-tree-node {
    min-width: 100%;
    display: inline-block;
  }*/

}
</style>
