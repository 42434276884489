
/**
 * 匹配菜单数组中的某个对象
 * @param {*} meunList 菜单数组
 * @param {*} key 数组需要匹配的对象键
 * @param {*} value 数组需要匹配的对象值
 * @returns 匹配到的对象 entity
 */
export function getMenu2MenuListItemAttribute(meunList,key,value,childNodeKey){
    let targetList = [];
    recursionMenuListgetMenuByItemAttribute(meunList,key,value,childNodeKey,targetList);

    // console.log('tags',targetList);

    return targetList.length>0?targetList[0]:null;
    

}

/** 
 *递归获取内容
 * 
 * */ 

function recursionMenuListgetMenuByItemAttribute(meunList,key,value,childKey,targetList = []){


    for (const index of Object.keys(meunList)) {
        

        if(meunList[index][key] === value){
            targetList.push(meunList[index])
            
            break;
        }
        if(meunList[index][childKey]!=undefined){
            recursionMenuListgetMenuByItemAttribute(meunList[index][childKey],key,value,childKey,targetList)
        }

       
        
    }

}

/**
 * 数组去重
 * @param {Array} tabList 需要去重的数组
 * 
 */ 
export function excludeDuplicates2MeunList(tabList){
    return Array.from(new Set(tabList.map(JSON.stringify)), JSON.parse);
}

/**
 *将标签保存到会话中 
 *
 */ 
export function saveTabList2SessionStorage(key,tabList){

    sessionStorage.setItem(key,JSON.stringify(tabList));

}

/**
 *从会话中获取标签集合
 * 
 */ 

export function getTabList2SessionStorage(key){

    let entity = sessionStorage.getItem(key);

    if(!entity||entity===undefined){
        return null;
    }

    return JSON.parse(entity);
}

export function getTabIndex2TabLIst(tabList,key,value){
    let index = -1;
    for(let i=0;i<tabList.length;i++){
        if(tabList[i][key] === value){
            index = i ;
            break;
        }
    }

    return index;

}

