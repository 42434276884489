import {get, post} from '@/utils/request';

const prefix = '/charge-gun';

/**
 * 通过充电桩查询枪口列表
 * @param params 充电桩ID
 */
export const listChargeGuns = (params = {}) => {
    return get({url: `${prefix}/list`, params: {...params}})
};

/**
 * 添加充电桩枪口
 * @param params 对象数据
 */
export const saveOrUpdateChargeGuns = (params = {}) => {
    return post({url: `${prefix}/save-or-update`, data: {...params}});
};
