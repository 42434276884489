<template>
  <div class="data-main-wrapper">
    <i-table v-if="table.showTable" :config="table.config" :page="table.page" :page-sizes="defaultPageSizes" @change-page="handleChangePage">
      <template slot="search-condition">
        <el-form :inline="true" :model="table.searchCondition" size="small">
          <el-form-item :label="field.mobile.name + ':'">
            <el-input clearable v-model="table.searchCondition.mobile"
                      :placeholder="field.mobile.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.nickname.name + ':'">
            <el-input clearable v-model="table.searchCondition.mobile"
                      :placeholder="field.mobile.placeholder"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <!--表头-->
      <template slot="header" slot-scope="scope">
        {{ field[scope.column.property].name }}
      </template>
      <!--头像列-->
      <template slot="row_avatar" slot-scope="scope">
        <el-avatar :src="scope.row[scope.column.property]"></el-avatar>
      </template>
    </i-table>
  </div>
</template>

<script>
import {defaultPageSizes,defaultPage} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable.vue';
import {clientWechatAppletUserTableSettings} from '@/config/table-settings';
import {loadCurrentPageTheButtons} from '@/api/system/menu';
import {hasSuccess} from '@/utils/tool';
import {showData} from '@/utils/power';
import {positions} from '@/config/button-position-settings';
import {clientWechatAppletUserPaging} from '@/api/platform/wechat-applet-user';

export default {
  components: {
    'i-table': ITable
  },
  computed: {
    public() {
      return {
        unauthorizedView: this.$t('public.resultMessages.unauthorizedView')
      }
    },
    field() {
      const fieldPrefix = 'clientWechatAppletUser.field';
      const formPrefix = 'clientWechatAppletUser.form';
      return {
        id: {name: this.$t(`${fieldPrefix}.id`)},
        userId: {name: this.$t(`${fieldPrefix}.userId`)},
        nickname: {
          name: this.$t(`${fieldPrefix}.nickname`),
          placeholder: this.$t(`${formPrefix}.nickname.placeholder`),
        },
        mobile: {
          name: this.$t(`${fieldPrefix}.mobile`),
          placeholder: this.$t(`${formPrefix}.mobile.placeholder`),
        },
        avatar: {name: this.$t(`${fieldPrefix}.avatar`)},
        genderName: {
          name: this.$t(`${fieldPrefix}.genderName`),
        },
        email: {name: this.$t(`${fieldPrefix}.email`)},
        identityTypeName: {name: this.$t(`${fieldPrefix}.identityTypeName`)},
        modifierName: {name: this.$t(`${fieldPrefix}.modifierName`)},
        modifyTime: {name: this.$t(`${fieldPrefix}.modifyTime`)},
        creatorName: {name: this.$t(`${fieldPrefix}.creatorName`)},
        createTime: {name: this.$t(`${fieldPrefix}.createTime`)},
      }
    }
  },
  data() {
    return {
      defaultPageSizes,
      table: {
        showTable: false,
        config: {
          columns: [...clientWechatAppletUserTableSettings],
          rowKey: 'id',
        },
        buttons: [],
        page: {
          ...defaultPage
        },
        searchCondition: {
          mobile: '',
          nickname: '',
        }
      }
    }
  },
  mounted() {
    this.initCurrentPageButton();
  },
  methods: {
    /**
     * 处理分页改变和搜索条件查询
     */
    handleChangePage() {
      this.initClientWechatAppletUser();
    },
    /**
     * 初始化微信小程序用户
     */
    async initClientWechatAppletUser() {
      const show = showData(this.table.buttons, positions.view);
      if (!show) {
        this.$message.error(this.public.unauthorizedView);
        return;
      }
      let {code, data} = await clientWechatAppletUserPaging({
        current: this.table.page.current,
        size: this.table.page.size,
        ...this.table.searchCondition
      });
      if (hasSuccess(code)) {
        this.table.page = {
          current: data.current,
          size: data.size,
          total: data.total,
          records: data.records
        }
      }
    },
    /**
     * 初始化页面按钮
     */
    async initCurrentPageButton() {
      let {code, data} = await loadCurrentPageTheButtons({route: this.$route.path});
      if (hasSuccess(code)) {
        this.table.buttons = data;
        this.table.showTable = true;
        this.initClientWechatAppletUser();
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
