import VueRouter  from "vue-router";
import Vue from "vue";
import {getAccessToken} from "@/utils/token";

Vue.use(VueRouter);

//扫描指定包获取所有的file对象
const files = require.context('@/views', true, /\.vue$/);
let views = {};
//获取所有的.vue文件
files.keys().forEach(key => {
    views[key.replace(/(\.\/|\.vue)/g, '')] = files(key).default;
})

/*let pathList = [];
//遍历每一个vue文件获取路径以及组件实例对象
Object.keys(views).forEach(item=>{
    // console.log('item',item);
    //排除掉 Main和 login页面
    if(item != 'Main'&&!item.startsWith('login') && item !== 'StoredEnergyBigScreen'){
        pathList.push({
            path: `/${item.replace('/Index','')}`,
            component:views[item]
        })
    }
});

//构建路由
const router = new VueRouter({
    routes:[
        {
            path:'/',
            component:()=>import('@/views/Main.vue'),
            redirect:'/login',
            children:[
                ...pathList
            ]
        },
        {
            path:'/login',
            component:()=>import('@/views/login/Index.vue')
        },
        {
            path:'/stored-energy-big-screen',
            name: 'StoredEnergyBigScreen',
            component:()=>import('@/views/stored-energy-big-screen/Index.vue')
        },
    ]
})*/

const pathList = [];
const rootPathList = [];
const rootPath = ['stored-energy-big-screen', 'login', 'main']
//遍历每一个vue文件获取路径以及组件实例对象
Object.keys(views).forEach(item => {
    const target = {
        path: `/${item.replace('/Index','')}`,
        component: views[item]
    }
    // 判断当前视图是根路由还是子路由
    if (rootPath.some(rp => item.toLowerCase().includes(rp.toLowerCase()))) {
        rootPathList.push(target)
    } else {
        pathList.push(target)
    }
});

//构建路由
const router = new VueRouter({
    routes: [{
        path: '/',
        component: () => import('@/views/Main.vue'),
        redirect: '/login',
        children: [
            ...pathList
        ]
    },
        ...rootPathList
    ]
})

// console.log('router',router)

// 导航守卫
router.beforeEach(function(to,from,next){
    // console.log('from',from);
    sessionStorage.setItem('lastRoute',from.fullPath);
    // if(to.matched.length==0){
    //     console.log('未匹配到指定路由~')
    //     next('/error');
    // }else{
    //     //路由跳转之后回到顶部
    //     document.documentElement.scrollTop = 0;
    //     next();
    // }
    document.documentElement.scrollTop = 0;

    if (getAccessToken()) {
        if (to.path === '/login') {
            next(from.path === '/' ? '/home' : from.path);
        } else {
            next();
        }
    } else {
        if (to.path === '/login') {
            next();
        } else {
            next('/login');
        }
    }
})





//路由导航


//解决路径相同报错的问题
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push=function push(to){
    return routerPush.call(this, to).catch(err => err)
}

export default router;
