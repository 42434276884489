<template>
    <div class="finance-platform-user-account_wrapper">
        <i-table
            :page="page"
            :config="config"
            @change-page="changePagingHandler"
            v-if="showPage"
            :page-sizes="defaultPageSizes"
        >

            <!-- 搜索栏 -->
            <template slot="search-condition">
                <el-form :model="search.values" :inline="true" ref="searchRef">

                    <el-form-item :label="field.nickname+':'">
                        <el-input size="small" v-model="search.values.nickname" :placeholder="form.nickname.placeholder" clearable />
                    </el-form-item>
                </el-form>
            </template>


             <!-- 搜索按钮 -->
             <template slot="search-button">
                <el-button type="primary"  size="small" @click="searchHandler" icon="el-icon-search">{{ buttonText.search }}</el-button>
            </template>


            <!-- 表头国际化 -->
            <template slot="header" slot-scope="scope">
                {{ field[scope.column.property] }}
            </template>

            <!-- 左侧功能栏 -->
            <template slot="overall-left">
                <el-button v-for="(overallLeftButton,index) in obtainPositionButtons(buttons,positions.left)" :key="index" type="primary"  size="small" :icon="overallLeftButton.icon" @click="operateHandler(overallLeftButton.code)">{{overallLeftButton.name}}</el-button>
            </template>

            <!-- 右侧工作栏 -->
            <template slot="overall-right">
                <el-button v-for="(overallRightButton,index) in obtainPositionButtons(buttons,positions.right)" :key="index"   size="small" :icon="overallRightButton.icon" @click="operateHandler(overallRightButton.code)">{{overallRightButton.name}}</el-button>
            </template>

            <!-- 数据行内 -->
            <template slot="row_operate" slot-scope="scope">
                <el-button
                type="text"
                :icon="rowButton.icon"
                size="mini"
                v-for="(rowButton,index) in obtainPositionButtons(buttons,positions.row) "
                :key="index"
                @click="operateHandler(rowButton.code,scope.row)"
                >
                    {{ rowButton.name  }}
                </el-button>
            </template>

        </i-table>
        
    </div>
</template>

<script>
import {defaultPageSizes,defaultPage} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable.vue'
import {clientUserWalletAccountTableSettings} from '@/config/table-settings' 
import {obtainPositionButtons,showData} from '@/utils/power'
import {loadCurrentPageTheButtons} from '@/api/system/menu'
import {positions} from '@/config/button-position-settings'
import {loadClientUserWalletAccountPaging} from '@/api/finance/platform-user-account'
export default{
    components: { ITable },
    data(){

        return{
            defaultPageSizes,
            positions,
            obtainPositionButtons,
            config:{
                border:true,
                columns:[
                    ...clientUserWalletAccountTableSettings
                ]
            },
            page:{
               ...defaultPage
            },
            search:{
                values:{}
            },
            showPage:false,
            buttons:[]

            
        }
        
    },
    methods:{
        async getCurrentPageButtons(){//获取当前页按钮权限
            const {data} = await  loadCurrentPageTheButtons({route:this.$route.path});
            // console.log(buttons);
            this.buttons = data;
            //获取到了按钮数据，才开始渲染
            this.showPage = true;

            await this.changePagingHandler();
        
        },
        async changePagingHandler(){
            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.resultMessages.unauthorizedView);
                return;
            }

            const result = await loadClientUserWalletAccountPaging({
                current:this.page.current,
                size:this.page.size,
                ...this.search.values
            });

            if(result&&result.code === 200){
                this.page = result.data;
            }


        },
        async searchHandler(){
            const show = showData(this.buttons,positions.view);

            if(!show){
                this.$message.error(this.resultMessages.unauthorizedView);
                return;
            }

            const result = await loadClientUserWalletAccountPaging({
                current:1,
                size:this.page.size,
                ...this.search.values
            });

            if(result&&result.code === 200){
                this.page = result.data;
            }
        },
        operateHandler(){

        }
    },
    mounted(){
        this.getCurrentPageButtons();
    },
    computed:{
        field(){
            const prefix = 'clientUserWalletAccountPage.field';

            return{
                id:this.$t(`${prefix}.id`),
                userId:this.$t(`${prefix}.userId`),
                nickname:this.$t(`${prefix}.nickname`),
                accumulateRechargeByYuan:this.$t(`${prefix}.accumulateRechargeByYuan`),
                remainingSumByYuan:this.$t(`${prefix}.remainingSumByYuan`),
                creatorName:this.$t(`${prefix}.creatorName`),
                createTime:this.$t(`${prefix}.createTime`),
                modifierName:this.$t(`${prefix}.modifierName`),
                modifyTime:this.$t(`${prefix}.modifyTime`),
                operate:this.$t(`${prefix}.operate`),
            }
        },
        form(){
            const prefix = 'clientUserWalletAccountPage.form'

            return{
                nickname:{
                    placeholder:this.$t(`${prefix}.nickname.placeholder`)
                }
            }
        },
        buttonText(){
            const prefix = 'public.button';
            return{
                search:this.$t(`${prefix}.search`)
            }
        },
        resultMessages(){
            const prefix = 'public.resultMessages';

            return{
                unauthorizedView:this.$t(`${prefix}.unauthorizedView`),
            }
        }

    }
}

</script>

<style lang="scss">
.finance-platform-user-account_wrapper{
    height: 100%;
    background-color: var(--bg-theme-page-color);
}
</style>