<template>
  <div class="data-main-wrapper">
    <i-table v-if="table.showTable" :config="table.config" :page="table.page" :page-sizes="defaultPageSizes" @change-page="handleChangePage">
      <!--搜索条件-->
      <template slot="search-condition">
        <el-form :inline="true" :model="table.searchCondition" size="small">
          <el-form-item :label="field.orgName + ':'">
            <el-input clearable v-model="table.searchCondition.orgName"
                      :placeholder="form.orgName.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.contactPerson + ':'">
            <el-input clearable v-model="table.searchCondition.contactPerson"
                      :placeholder="form.contactPerson.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.contactPhone + ':'">
            <el-input clearable v-model="table.searchCondition.contactPhone"
                      :placeholder="form.contactPhone.placeholder"></el-input>
          </el-form-item>
          <el-form-item :label="field.parentId + ':'">
            <el-select v-model="table.searchCondition.parentId" clearable filterable
                       :placeholder="form.parentId.placeholder">
              <el-option
                  v-for="(item, index) in drawer.orgSelect"
                  :key="index"
                  :label="item.orgName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <!--搜索按钮-->
      <template slot="search-button">
        <el-button type="primary" size="small" @click="handleChangePage(true)">{{ btn.search }}</el-button>
      </template>
      <!--全局操作-->
      <template slot="overall-left">
        <el-button
            type="primary"
            v-for="(btn, index) in btnPosition.obtainPositionButtons(button.childNode, btnPosition.positions.left)"
            :key="index"
            size="small"
            :icon="btn.icon"
            @click="handleOperate(btn.code)">
          {{ btn.name }}
        </el-button>
      </template>
      <!--表头设置-->
      <template slot="header" scope="scope">
        {{ $t(`organizationManagePage.field.${scope.column.property}`) }}
      </template>
      <!--行内操作-->
      <template slot="row_operate" slot-scope="scope">
        <el-button type="text"
                   v-for="(btn, index) in btnPosition.obtainPositionButtons(button.childNode, btnPosition.positions.row)"
                   :key="index"
                   size="mini"
                   :icon="btn.icon"
                   @click="handleOperate(btn.code, scope.row)">
          {{ btn.name }}
        </el-button>
      </template>
    </i-table>

    <!--抽屉-->
    <i-drawer :visible.sync="drawer.visible" :title="drawer.title" size="30%" @open="handleOpenDrawer"
              @close="clearOrgFormData">
      <el-form ref="orgForm" :model="drawer.orgForm" :rules="orgRules" label-width="100px" label-position="right"
               size="small">
        <el-form-item :label="field.orgName + ':'" prop="orgName">
          <el-input v-model="drawer.orgForm.orgName" :placeholder="form.orgName.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="field.parentId + ':'" prop="parentId" v-if="!drawer.orgSelectDisabled">
          <el-select v-model="drawer.orgForm.parentId" clearable filterable :placeholder="form.parentId.placeholder">
            <el-option
                v-for="(item, index) in drawer.orgSelectParent"
                :key="index"
                :label="item.orgName"
                :value="item.id"
                :disabled="drawer.orgForm.id === item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="field.contactPerson + ':'" prop="contactPerson">
          <el-input v-model="drawer.orgForm.contactPerson" :placeholder="form.contactPerson.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="field.contactPhone + ':'" prop="contactPhone">
          <el-input v-model="drawer.orgForm.contactPhone" :placeholder="form.contactPhone.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="field.type + ':'" prop="type">
          <el-radio-group v-model="drawer.orgForm.type">
            <el-radio v-for="(item, index) in drawer.orgTypes" :key="index" :label="item.value">{{
                item.name
              }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="field.sort + ':'">
          <el-input-number v-model="drawer.orgForm.sort" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item :label="field.address + ':'" prop="address">
          <el-input type="textarea" :rows="1" :placeholder="form.address.placeholder"
                    v-model="drawer.orgForm.address"></el-input>
        </el-form-item>
        <el-form-item :label="field.remark + ':'" prop="remark">
          <el-input type="textarea" :placeholder="form.remark.placeholder" v-model="drawer.orgForm.remark"></el-input>
        </el-form-item>
      </el-form>

      <template slot="footer">
        <el-button type="primary" @click="handleOrgFormSubmit">{{ btn.submit }}</el-button>
        <el-button @click="clearOrgFormData">{{ btn.cancel }}</el-button>
      </template>
    </i-drawer>
  </div>
</template>

<script>
import {defaultPageSizes,defaultPage} from "@/config/page-settings";
import ITable from '@/components/i-table/ITable.vue';
import IDrawer from '@/components/i-drawer/IDrawer.vue';
import {obtainPositionButtons, showData} from '@/utils/power';
import {positions} from '@/config/button-position-settings';
import {
  listOrganizationPage,
  deleteOrganization,
  listOrgSelect,
  saveOrUpdateOrganization,
} from '@/api/system/organization';
import {loadCurrentPageTheButtons} from '@/api/system/menu';
import {hasSuccess, validateNull} from '@/utils/tool';
import {organizationTableSettings} from '@/config/table-settings';
import {rules} from '@/utils/rules';
import {loadDataDictByCode} from '@/api/system/data-dict';
import {message} from '@/utils/message';
import {getDataScopeByUser} from '@/api/system/data-scope';
import {getStore} from '@/utils/store';
import {storeKey} from '@/constant/store-key';

export default {

  components: {
    'i-table': ITable,
    'i-drawer': IDrawer,
  },
  computed: {
    /**
     * 获取页面按钮和按钮位置
     */
    btnPosition: {
      get: function () {
        return {obtainPositionButtons, positions}
      }
    },
    defaultOrgSelectData() {
      // 用于一开始没有机构的时候使用的
      return [{id: 0, orgName: this.form.defaultOrgName}];
    },
    field() {
      const prefix = 'organizationManagePage.field';
      return {
        id: this.$t(`${prefix}.id`),
        orgName: this.$t(`${prefix}.orgName`),
        contactPerson: this.$t(`${prefix}.contactPerson`),
        contactPhone: this.$t(`${prefix}.contactPhone`),
        parentId: this.$t(`${prefix}.parentId`),
        type: this.$t(`${prefix}.type`),
        sort: this.$t(`${prefix}.sort`),
        address: this.$t(`${prefix}.address`),
        remark: this.$t(`${prefix}.remark`),
        operate: this.$t(`${prefix}.operate`),
      }
    },
    form() {
      const prefix = 'organizationManagePage.form';
      return {
        orgName: {
          placeholder: this.$t(`${prefix}.orgName.placeholder`),
          required: this.$t(`${prefix}.orgName.checkFail.required`),
          rule: this.$t(`${prefix}.orgName.checkFail.rule`)
        },
        parentId: {
          placeholder: this.$t(`${prefix}.parentId.placeholder`),
          required: this.$t(`${prefix}.parentId.checkFail.required`)
        },
        contactPerson: {
          placeholder: this.$t(`${prefix}.contactPerson.placeholder`),
          required: this.$t(`${prefix}.contactPerson.checkFail.required`)
        },
        contactPhone: {
          placeholder: this.$t(`${prefix}.contactPhone.placeholder`),
          required: this.$t(`${prefix}.contactPhone.checkFail.required`),
          rule: this.$t(`${prefix}.contactPhone.checkFail.rule`)
        },
        address: {
          placeholder: this.$t(`${prefix}.address.placeholder`),
          rule: this.$t(`${prefix}.address.checkFail.rule`),
        },
        type: {
          required: this.$t(`${prefix}.type.checkFail.required`),
        },
        remark: {
          placeholder: this.$t(`${prefix}.remark.placeholder`),
          rule: this.$t(`${prefix}.remark.checkFail.rule`),
        },
        defaultOrgName: this.$t(`${prefix}.defaultOrgName`),
      }
    },
    orgDrawer() {
      const prefix = 'organizationManagePage.drawer';
      return {
        save: this.$t(`${prefix}.save`),
        update: this.$t(`${prefix}.update`),
        orgNotNull: this.$t(`${prefix}.orgNotNull`),
        confirm: {
          title: this.$t(`${prefix}.confirm.title`),
          cancelMessage: this.$t(`${prefix}.confirm.cancelMessage`),
        }
      }
    },
    searchCondition() {
      return {
        parentId: this.$t('organizationManagePage.searchCondition.parentId'),
      }
    },
    btn() {
      return {
        search: this.$t('public.button.search'),
        submit: this.$t('public.button.submit'),
        cancel: this.$t('public.button.cancel'),
        confirm: this.$t('public.button.confirm'),
      }
    },
    orgRules() {
      let validContactPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.form.contactPhone.required));
        }
        if (!rules.phone.test(value)) {
          return callback(new Error(this.form.contactPhone.rule));
        }
        return callback();
      }

      return {
        orgName: [
          {required: true, message: this.form.orgName.required, trigger: 'blur'},
          {min: 1, max: 20, message: this.form.orgName.rule, trigger: 'blur'}],
        parentId: [{required: true, message: this.form.parentId.required, trigger: 'change'}],
        contactPerson: [{required: true, message: this.form.contactPerson.required, trigger: 'blur'}],
        contactPhone: [{required: true, validator: validContactPhone, trigger: 'blur'}],
        type: [{required: true, message: this.form.type.required, trigger: 'change'}],
        address: [{min: 0, max: 230, message: this.form.address.rule, trigger: 'blur'}],
        remark: [{min: 0, max: 230, message: this.form.remark.rule, trigger: 'blur'}],
      }
    },
    public() {
      return {
        unauthorizedOperate: this.$t('public.resultMessages.unauthorizedOperate'),
        unauthorizedView: this.$t('public.resultMessages.unauthorizedView'),
      }
    }
  },
  data() {
    return {
      defaultPageSizes,
      /**
       * 表格设置
       */
      table: {
        showTable: false,
        page: {
          ...defaultPage
        },
        config: {
          columns: [...organizationTableSettings],
          rowKey: 'id',
        },
        searchCondition: {
          orgName: '',
          contactPerson: '',
          contactPhone: '',
          parentId: '',
        }
      },
      /**
       * 页面按钮
       */
      button: {
        childNode: [],
      },
      /**
       * 抽屉
       */
      drawer: {
        visible: false,
        title: '',
        orgForm: {
          sort: 1,
        },
        orgTypes: [],
        // 查询所有机构,不包含默认顶级机构
        orgSelect: [],
        // 包含默认顶级机构
        orgSelectAll: [],
        // 选择父级机构
        orgSelectParent: [],
        // 禁用机构选择
        orgSelectDisabled: false,
      },
      /**
       * 当前用户是否最高数据权限
       */
      highestAuthority: false,
    }
  },
  mounted() {
    this.initHighestAuthority();
    this.initOrgSelectAllData();
    this.initCurrentPageButton();
  },
  methods: {

    /**
     * 关闭抽屉, 清空数据
     */
    clearOrgFormData() {
      this.$refs.orgForm.resetFields();
      this.drawer.visible = false;
      this.drawer.orgForm = {
        sort: 1,
      }
    },
    /**
     * 处理机构表单的提交
     */
    handleOrgFormSubmit() {
      this.$refs.orgForm.validate(async (valid) => {
        if (valid) {
          let {code} = await saveOrUpdateOrganization(this.drawer.orgForm);
          if (hasSuccess(code)) {
            await this.initOrganizationData();
            await this.initOrgSelectAllData();
            // 这是是否要加上先祖列刷新?
            this.drawer.visible = false;
          }
        }
      });
    },
    /**
     * 处理操作
     * @param btnCode 按钮编号
     * @param row 当前行数据
     */
    handleOperate(btnCode, row) {
      switch (btnCode) {
        case 'save':
          this.drawer.title = this.orgDrawer.save;
          this.drawer.visible = true;
          break;
        case 'update':
          this.drawer.title = this.orgDrawer.update;
          this.drawer.orgForm = Object.assign({}, row);
          // 不是最高权限，且是当前登录人的机构则禁用
          let user = getStore({name: storeKey.user});
          if (validateNull(user) || validateNull(user.orgId)) {
            message.error({message: this.orgDrawer.orgNotNull});
            return;
          }
          this.drawer.orgSelectDisabled = ((!this.highestAuthority) && (row.id === user.orgId))
          this.drawer.visible = true;
          break;
        case 'delete':
          this.handleDeleteOrganization(row);
          break;
      }
    },
    /**
     * 处理删除机构
     * @param row 当前行数据
     */
    handleDeleteOrganization(row) {
      this.$confirm(this.$t('organizationManagePage.drawer.confirm.confirmMessage', {orgName: row.orgName}), this.orgDrawer.confirm.title, {
        confirmButtonText: this.btn.confirm,
        cancelButtonText: this.btn.cancel,
        type: 'warning'
      }).then(async () => {
        await deleteOrganization({id: row.id});
        await this.initOrganizationData();
        await this.initOrgSelectAllData();
      }).catch(() => {
        message.info({message: this.orgDrawer.confirm.cancelMessage});
      });
    },
    /**
     * 处理打开抽屉的操作
     */
    handleOpenDrawer() {
      this.initOrgType();
      if (this.drawer.orgForm.id) {
        // 修改，有全部的下拉选项
        this.drawer.orgSelectParent = this.drawer.orgSelectAll;
      } else {
        // 新增，下拉数据少了全部
        this.drawer.orgSelectParent = this.drawer.orgSelect;
      }
    },
    /**
     * 处理更改页面操作
     */
    async handleChangePage(hasSearch) {
      const show = showData(this.button.childNode, positions.view);
      if (!show) {
        this.$message.error(this.public.unauthorizedView);
        return;
      }
      if (hasSearch) {
        this.table.page.current = 1;
      }
      await this.initOrganizationData();
    },
    async initOrgType() {
      let {code, data} = await loadDataDictByCode({code: 'organization_type'});
      if (hasSuccess(code)) {
        this.drawer.orgTypes = data;
      }
    },
    /**
     * 初始化用户是否最高数据权限
     * @returns {Promise<void>}
     */
    async initHighestAuthority() {
      let {code, data} = await getDataScopeByUser();
      if (hasSuccess(code)) {
        this.highestAuthority = data === 0;
      }
    },
    /**
     * 初始化机构下拉选择数据
     */
    async initOrgSelectAllData() {
      let {code, data} = await listOrgSelect({hasDefaultTopLevel: true});
      if (hasSuccess(code)) {
        if (validateNull(data)) {
          // 给个默认值, 表没有默认顶级机构时需要
          this.drawer.orgSelect = this.defaultOrgSelectData;
          return;
        }
        // 过滤出parentId不为0的就可以
        this.drawer.orgSelectAll = data;
        this.drawer.orgSelect = data.filter(item => item.parentId !== 0);
      }
    },
    /**
     * 初始化页面按钮
     */
    async initCurrentPageButton() {
      let {code, data} = await loadCurrentPageTheButtons({route: this.$route.path});
      if (hasSuccess(code)) {
        this.button.childNode = data;
        this.table.showTable = true;
        await this.initOrganizationData();
      }
    },
    /**
     * 初始化机构数据
     */
    async initOrganizationData() {
      const show = showData(this.button.childNode, positions.view);
      if (!show) {
        this.$message.error(this.public.unauthorizedView);
        return;
      }
      let {code, data} = await listOrganizationPage({
        current: this.table.page.current,
        size: this.table.page.size,
        ...this.table.searchCondition
      });
      if (hasSuccess(code)) {
        this.table.page = {
          current: data.current,
          size: data.size,
          total: data.total,
          records: data.records,
        }
      }
    }
  }
}

</script>

<style lang="scss">


</style>
