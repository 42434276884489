import {Message} from "element-ui";

// 仅且只有一个,Symbol.for引用
const showMessage = Symbol('showMessage');

class CustomMessage {

    [showMessage] (type, options, single) {
        if (single) {
            if (document.getElementsByClassName('el-message').length === 0) {
                Message[type](options);
            }
        } else {
            Message[type](options);
        }
    }

    info(options, single = true) {
        this[showMessage]('info', options, single);
    }

    warning(options, single = true) {
        this[showMessage]('warning', options, single);
    }

    error(options, single = true) {
        this[showMessage]('error', options, single);
    }

    success(options, single = true) {
        this[showMessage]('success', options, single);
    }
}

export const message = new CustomMessage();
