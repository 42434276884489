const defaultPageSizes=[20, 40, 80, 100,200,400];
const defaultPage={
    total:0,
    size:defaultPageSizes[0],
    current:1,
    records:[]

}

export {
    defaultPage,
    defaultPageSizes
};