<template>
  <div class="container">

    <div class="data-big-screen">
      <img :src="imgUrl" alt="" class="screen-bg-img">
      <div class="top-box">
        <div class="top">
          <div class="top-time">{{showCurrentTime}}</div>
          <div class="top-station">{{stationName || '百卓光储充储能数据大屏'}}</div>
          <div class="top-right">
            <el-date-picker
                @change="changeCurrentDate"
                class="right-date-picker"
                size="small"
                v-model="currentDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd">
            </el-date-picker>

            <el-button size="small" @click="exportData" class="right-export">导出数据</el-button>
          </div>
        </div>
        <!--        占位box-->
        <div class="top-status"></div>
      </div>
      <div class="screen-content">
        <div class="pv div-common">
          <div class="title">光伏</div>
          <div>光伏发电量(kWh): {{ storedEnergyBigScreenData.pvPower || 0}}</div>
<!--          <div>发电实时效率(%): {{ storedEnergyBigScreenData.pvRealtimeEfficiency || 0}}</div>-->
        </div>
        <div class="grid div-common">
          <div class="title">电网</div>
          <div>正向电量(kWh): {{ storedEnergyBigScreenData.gridForwardCapacity || 0}}</div>
          <div>反向电量(kWh): {{ storedEnergyBigScreenData.gridReverseCapacity || 0}}</div>
        </div>
        <div class="pcs">
          <div>PCS温度(℃): {{ storedEnergyBigScreenData.pcsTemperature || 0}}</div>
        </div>
        <div class="battery div-common">
          <div class="title">电池</div>
          <div>健康情况(%): {{ storedEnergyBigScreenData.batteryHealthCondition || 0}}</div>
          <div>累计循环次数(次): {{ storedEnergyBigScreenData.batteryCumulativeCycleIndex || 0}}</div>
          <div>电池温度(℃): {{ storedEnergyBigScreenData.batteryTemperature || 0}}</div>
          <div>实时电量(%): {{ storedEnergyBigScreenData.batteryRealtimePower || 0}}</div>
          <div>电池状态: {{ storedEnergyBigScreenData.batteryHasChargingName || 0}}</div>
        </div>
        <div class="pcs-battery div-common">
          <div class="title">PCS-电池</div>
          <div>充电量(kWh): {{ storedEnergyBigScreenData.batteryForwardCapacity || 0}}</div>
          <div>放电量(kWh): {{ storedEnergyBigScreenData.batteryReverseCapacity || 0}}</div>
          <div>系统效率(%): {{storedEnergyBigScreenData.systemEfficiency || 0}}</div>
          <div class="title">充电量-其中</div>
          <div>光伏充电量(kWh): {{storedEnergyBigScreenData.batteryForwardPv || 0}}</div>
          <div>电网充电量(kWh): {{storedEnergyBigScreenData.batteryForwardGrid || 0}}</div>
        </div>
        <div class="pile div-common">
          <div class="title">充电桩</div>
          <div>输出电量(kWh): {{ storedEnergyBigScreenData.chargePileOutputPower || 0}}</div>
        </div>
        <div class="se-system-capacity">
          <div>储能系统运转用电(kWh): {{ storedEnergyBigScreenData.esSystemCapacity || 0}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getStoredEnergyDataBigScreenDay, exportStoredEnergyDataOfDay} from '@/api/stored-energy/stored-energy';
import {formatDateTime, hasSuccess, validateNull} from '@/utils/tool';
import {message} from "@/utils/message";

export default {
  data() {
    return {
      currentDate: null,
      imgUrl: require('@/assets/stored-energy-data-big-screen.gif'),
      flushBigScreenDataTimer: null,
      storedEnergyBigScreenData: {},
      currentTimer: null,
      showCurrentTime: '',
      stationName: '',
    }
  },
  mounted() {
    this.stationName = this.$route.query.stationName;
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.getDataBigScreenData(this.currentDate);
    this.startTimerTask();
    this.startCurrentTimer();
  },
  destroyed() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.stopTimerTask();
    this.stopCurrentTimer();
  },
  methods: {
    /**
     * 导出日期的储能大屏数据
     */
    async exportData() {
      let resp = await exportStoredEnergyDataOfDay({dateStr: this.currentDate});
      let respType = resp.type;
      if (respType === 'application/json') {
        const reader = new FileReader();
        reader.onload = () => {
          const jsonData = JSON.parse(reader.result);
          if (jsonData.code !== 200) {
            message.error({message: jsonData.message});
          }
        };
        reader.readAsText(resp);
      } else if ( respType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        let blob = new Blob([resp], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        let url = window.URL.createObjectURL(blob);
        // 创建a标签
        const link = document.createElement('a');
        link.href = url;
        // 重命名下载文件名
        const {year, month, day, hours, minutes, seconds} = formatDateTime(new Date());
        link.download = `储能大屏数据${year}${month}${day}${hours}${minutes}${seconds}.xlsx`;
        link.click();
        // 释放内存
        URL.revokeObjectURL(url);
      } else {
        console.log('未知的响应类型', respType);
      }
    },
    /**
     * 修改当前时间触发
     */
    changeCurrentDate() {
      this.storedEnergyBigScreenData = {};
      const {year, month, day} = formatDateTime(new Date());
      let now = `${year}-${month}-${day}`;
      console.log(this.currentDate && this.currentDate !== now)
      if (this.currentDate && this.currentDate !== now) {
        this.stopTimerTask();
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        this.getDataBigScreenData(this.currentDate);
      } else {
        this.getDataBigScreenData(this.currentDate);
        if (validateNull(this.flushBigScreenDataTimer)) {
          document.addEventListener('visibilitychange', this.handleVisibilityChange);
          this.startTimerTask();
        }
      }
    },
    /**
     * 当前页打开离开页面
     */
    handleVisibilityChange() {
      // 页面隐藏停掉定时任务
      if (document.hidden) {
        this.stopTimerTask();
      } else {
        this.startTimerTask();
      }
    },
    /**
     * 停止定时任务
     */
    stopTimerTask() {
      clearInterval(this.flushBigScreenDataTimer);
      this.flushBigScreenDataTimer = null;
    },
    /**
     * 开启定时任务（5min一次）
     */
    startTimerTask() {
      this.flushBigScreenDataTimer = setInterval(() => {
        this.getDataBigScreenData(this.currentDate);
      }, 5 * 60 * 1000); // 每5分钟执行一次
    },
    /**
     * 获取当天的储能数据大屏数据
     */
    async getDataBigScreenData(currentDate) {
      let {code, data} = await getStoredEnergyDataBigScreenDay({localDateTime: currentDate});
      if (hasSuccess(code)) {
        this.storedEnergyBigScreenData = data;
      }
    },
    /**
     * 停止获取当前时间定时任务
     */
    stopCurrentTimer() {
      clearInterval(this.currentTimer);
      this.currentTimer = null;
    },
    /**
     * 开启获取当前时间定时任务
     */
    startCurrentTimer() {
      this.getCurrentTime();
      this.currentTimer = setInterval(() => {
        this.getCurrentTime();
      }, 1000);
    },
    /**
     * 获取当前时间
     */
    getCurrentTime() {
      const {year, month, day, hours, minutes, seconds} = formatDateTime(new Date());
      this.showCurrentTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  }
}
</script>

<style scoped lang="scss">
$width-m: 1080px;
$width-l: 1440px;
$width-xl: 1920px;

::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 0;
}

.container {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  background-color: #000839;
}
.top-box{
  .top-status{
    height: 60px;
  }
  .top {
    position: absolute;
    background-color: #000839;
    top: 0;
    box-sizing: border-box;
    padding: 0 10%;
    width: 100%;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    .top-time {
      color: #ffffff;
      font-size: 28px;
      line-height: 50px;
    }

    .top-station {
      //margin-left: 20px;
      color: #ffffff;
      height: 50px;
      font-size: 28px;
      line-height: 50px;
      //font-weight: 600;
      margin-top: 20rpx;

    }

    .top-right {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 20px;
      }

      .right-date-picker {

      }

      .right-export {
        background-color: #047aff;
        border-color: #047aff;
        color: #ffffff;
      }
    }
  }
}
.data-big-screen {
  @media screen and (min-width: #{$width-xl + 1}) {
    width: 2000px; // 在超出 $width-xl 范围时设置备用宽度
  }
  @media screen and (max-width: $width-xl) {
    width: 1880px;
  }
  @media screen and (max-width: $width-l) {
    width: 1400px;
  }
  @media screen and (max-width: $width-m) {
    width: 1080px;
  }
  position: relative;
  flex-shrink: 0;

  .screen-bg-img {
    width: 100%;
    object-fit: contain;
  }


  .screen-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;


    .pv {
      color: #ffffff;
      position: absolute;
      width: 190px;
      height: 80px;
      border-radius: 6%;
      border: 3px solid #047afe;
      padding: 10px;
      @media screen and (min-width: #{$width-xl + 1}) {
        top: 368px;
        left: 636px;
      }
      @media screen and (max-width: $width-xl) {
        top: 340px;
        left: 600px;
      }
      @media screen and (max-width: $width-l) {
        top: 226px;
        left: 450px;
      }
      @media screen and (max-width: $width-m) {
        top: 150px;
        left: 313px;
      }
    }

    .grid {
      color: #ffffff;
      position: absolute;
      width: 190px;
      height: 130px;
      border-radius: 6%;
      border: 3px solid #fdfe01;
      padding: 10px;
      @media screen and (min-width: #{$width-xl + 1}) {
        top: 318px;
        right: 636px;
      }
      @media screen and (max-width: $width-xl) {
        top: 290px;
        right: 600px;
      }
      @media screen and (max-width: $width-l) {
        top: 174px;
        right: 450px;
      }
      @media screen and (max-width: $width-m) {
        top: 98px;
        right: 313px;
      }
    }

    .pcs {
      color: #ffffff;
      position: absolute;
      width: 136px;
      height: 26px;
      border-radius: 6%;
      border: 3px solid #fe0000;
      padding: 10px;
      @media screen and (min-width: #{$width-xl + 1}) {
        top: 592px;
        left: 700px;
      }
      @media screen and (max-width: $width-xl) {
        top: 560px;
        left: 650px;
      }
      @media screen and (max-width: $width-l) {
        top: 412px;
        left: 455px;
      }
      @media screen and (max-width: $width-m) {
        top: 274px;
        left: 325px;
      }
    }

    .battery {
      color: #ffffff;
      position: absolute;
      width: 170px;
      height: 145px;
      border-radius: 6%;
      border: 3px solid #fdfe01;
      padding: 10px;
      @media screen and (min-width: #{$width-xl + 1}) {
        top: 772px;
        left: 490px;
      }
      @media screen and (max-width: $width-xl) {
        top: 716px;
        left: 465px;
      }
      @media screen and (max-width: $width-l) {
        top: 700px;
        left: 400px;
      }
      @media screen and (max-width: $width-m) {
        top: 538px;
        left: 313px;
      }
    }

    .pcs-battery {
      color: #ffffff;
      position: absolute;
      width: 170px;
      height: 160px;
      border-radius: 6%;
      border: 3px solid #fdfe01;
      padding: 10px;
      @media screen and (min-width: #{$width-xl + 1}) {
        top: 758px;
        left: 710px;
      }
      @media screen and (max-width: $width-xl) {
        top: 702px;
        left: 684px;
      }
      @media screen and (max-width: $width-l) {
        top: 480px;
        left: 436px;
      }
      @media screen and (max-width: $width-m) {
        top: 330px;
        left: 300px;
      }
    }

    .pile {
      color: #ffffff;
      position: absolute;
      width: 180px;
      height: 116px;
      border-radius: 6%;
      border: 3px solid #047afe;
      padding: 10px;
      @media screen and (min-width: #{$width-xl + 1}) {
        top: 840px;
        right: 315px;
      }
      @media screen and (max-width: $width-xl) {
        top: 780px;
        right: 286px;
      }
      @media screen and (max-width: $width-l) {
        top: 548px;
        right: 160px;
      }
      @media screen and (max-width: $width-m) {
        top: 390px;
        right: 75px;
      }
    }

    .se-system-capacity {
      color: #ffffff;
      position: absolute;
      width: 275px;
      height: 30px;
      border-radius: 6%;
      border: 3px solid #fdfe01;
      padding: 10px;
      @media screen and (min-width: #{$width-xl + 1}) {
        top: 562px;
        right: 232px;
      }
      @media screen and (max-width: $width-xl) {
        top: 540px;
        right: 200px;
      }
      @media screen and (max-width: $width-l) {
        top: 390px;
        right: 110px;
      }
      @media screen and (max-width: $width-m) {
        top: 288px;
        right: 76px;
      }
    }

    .div-common {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-weight: 600;
        align-self: center;
      }

      .content {

      }
    }
  }
}
</style>
