import {get, post} from '@/utils/request';

const prefix = '/charge-station';

/**
 * 分页查询充电站
 * @param params 查询条件
 */
export const listChargeStationPage = (params = {}) => {
    return get({url: `${prefix}/list-page`, params: {...params}});
}

/**
 * 保存或新增充电站
 * @param params
 */
export const saveOrUpdateChargeStation = (params = {}) => {
    return post({url: `${prefix}/save-or-update`, data: {...params}});
}

/**
 * 删除充电站
 * @param params
 */
export const deleteChargeStation = (params = {}) => {
    return post({url: `${prefix}/delete`, data: {...params}});
}

/**
 * 查询充电站下拉列表数据
 */
export const chargeStationPullDownList = () => {
    return get({url: `${prefix}/pull-down`});
}

/**
 * 通过充电站ID下载充电枪二维码
 */
export const downloadChargePileQrCodeByStationId = (params = {}) => {
    return get({url: `${prefix}/download-qr-code`, params: params, responseType: 'blob'});
}
