<template>
  <div class="login-wrapper">

    <div class="login-main">
      <div class="login-left">
        <!-- <div style="text-align: center;">
            <div style="font-size: 36px;color: #fff;font-weight: 700;">新能源充电桩-管理平台</div>
        <div style="font-size: 20px;color: #fff;font-weight: 600;line-height: 64px;">人生得意须尽欢，莫使金樽空对月</div>
        </div> -->
        <div class="icp">
          <div class="icp-text">
                  <!--创泰备案号-->
<!--             <el-link href="https://beian.miit.gov.cn/">粤ICP备2023138690号-1X</el-link>-->
                  <!-- 百卓备案号 -->
                 <el-link href="https://beian.miit.gov.cn/">粤ICP备2021007034号-3</el-link>
          </div>
        </div>
      </div>
      <div class="login-right">
        <div class="login-form">
          <div class="login-title">{{ loginTitle}}</div>
          <el-form :model="user" :rules="rules" ref="loginRef">
            <el-form-item prop="username">
              <el-input v-model="user.username" :placeholder="form.username.placeholder" prefix-icon="el-icon-user"/>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="user.password" @keydown.enter.native="submit" :placeholder="form.password.placeholder" show-password prefix-icon="el-icon-lock"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit">{{ btn.login}}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {login} from '@/api/system/web-user'
import {setStore} from '@/utils/store';
import {setAccessToken} from '@/utils/token';
import {message} from "@/utils/message";
import md5 from 'js-md5';
import {storeKey} from "@/constant/store-key";
import {hasSuccess} from "@/utils/tool";
import {rules} from "@/utils/rules";

export default {
  computed: {
    loginTitle() {
      return this.$t('loginManagePage.loginTitle');
    },
    form() {
      const prefix = 'loginManagePage.form';
      return {
        username: {
          placeholder: this.$t(`${prefix}.username.placeholder`),
          required: this.$t(`${prefix}.username.checkFail.required`),
        },
        password: {
          placeholder: this.$t(`${prefix}.password.placeholder`),
          required: this.$t(`${prefix}.password.checkFail.required`),
          rule: this.$t(`${prefix}.password.checkFail.rule`),
        },
        errorMsg: this.$t(`${prefix}.errorMsg`)
      }
    },
    btn() {
      return {
        login: this.$t('public.button.login')
      }
    },
    rules() {
      let validPassword = (rule, value, callback) => {
        if (!value) {
          return callback(this.form.password.required);
        }
        if (value && !rules.pwd_char20.test(value)) {
          return callback(this.form.password.rule);
        }
        return callback();
      }

      return {
        username: [{required: true, message: this.form.username.required, trigger: 'blur'},],
        password: [{required: true, validator: validPassword, trigger: 'blur'},]
      }
    }
  },
  data() {
    return {
      user: {
        username: '',
        password: '',
      },
    }
  },
  methods: {
    /**
     * 提交菜单
     */
    submit() {
      this.$refs['loginRef'].validate(async valid => {
        if (valid) {
          let encryptPasswd = md5(this.user.password);
          let resp = await login({username: this.user.username, password: encryptPasswd});
          if (hasSuccess(resp.code)) {
            let {accessToken, user} = resp.data;
            setAccessToken(accessToken);
            setStore({name: storeKey.user, content: JSON.parse(user)});
            // 判断当前路由地址是否是/login, 是则跳转首页, 否则跳转url访问的地址
            this.$router.replace(this.$route.path === '/login' ? '/home' : this.$route.path);
          }
        } else {
          message.error({message: '账号或密码请填写正确'});
        }
      });
    }
  },
  components: {},
  created() {
  }
}

</script>

<style lang="scss">

.login-wrapper {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  // background-image: url('../../assets/bg.jpg');
  background-size: 100% 100%;

  .login-main {
    width: 1000px;
    display: flex;
    height: 500px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    animation: wrapper-gradient 800ms linear;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    .login-left {
      width: 50%;
      background-image: url('../../assets/login-bg.jpg');
      background-size: 100% 100%;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      position: relative;
      // display: flex;
      // justify-content: center;
      // // height: 500px;
      // align-items: center;
      .icp{
        position: absolute;
        right: 20px;
        bottom: 0;
        .icp-text{
          .el-link{
            color: #fff;
            font-weight: 700;
            line-height: 40px;
          }
        }
      }
    }

    .login-right {
      width: 50%;
      background-color: #fff;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      display: flex;
      justify-content: center;

      .login-form {
        margin-top: 60px;
        width: 50%;

        .login-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 80px;
          text-align: center;
        }

        .input-deep {
          width: auto;
        }

        .el-input {
          input {
            border-radius: 0;
            border-left: 0;
            border-top: 0;
            border-right: 0;
            border-bottom: 1px solid #DCDFE6;
          }
        }

        .el-button {
          width: 100%;
          border-radius: 0;
        }


      }
    }
  }

}

@keyframes wrapper-gradient {
  0%, 60%, 75%, 90%, 100% {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }

}

</style>
