<template>
    <div class="i-title">
        <div class="i-title_wrapper">
            <div class="i-title_left">
                {{ title }}
            </div>
            <div class="i-title_right">
                <slot name="right"></slot>
            </div>

        </div>
        

    </div>
</template>

<script>

export default{
    props:{
        title:{
            type:String,
            default:()=>''
        }
    }
}

</script>

<style lang="scss">
.i-title{
    // color: #999;
    font-weight: 700;
    font-size: 14px;
    padding: 10px 0 10px 0;
    .i-title_wrapper{
        display: flex;
        justify-content: space-between;
        .i-title_left{
            padding-left: 10px;
            position: relative;
        }
        
        .i-title_left::before{
            content: '';
        width: 5px;
        height: 19px;
        //    background-color: #999;
        left: 0;
        //    top: 50%;
        //    bottom: 0;
        position: absolute;
        
        }

    }
   
}


</style>