import {get, post} from '@/utils/request';

const prefix = '/charge-pile-brand-model';

/**
 * 分页查询充电桩品牌型号列表数据
 */
export const chargePileBrandModelPaging = (params = {}) => {
    return get({url: `${prefix}/list-paging`, params: params});
}

/**
 * 添加或修改充电桩品牌型号
 */
export const saveOrUpdateChargePileBrandModel = (params = {}) => {
    return post({url: `${prefix}/save-or-update`, data: params});
}

/**
 * 删除充电桩品牌型号
 */
export const deleteChargePileBrandModel = (params = {}) => {
    return post({url: `${prefix}/delete`, data: params});
}

/**
 * 查询充电桩品牌型号下拉数据
 */
export const chargePileBrandModelPullDown = (params = {}) => {
    return get({url: `${prefix}/pull-down`, data: params});
}
