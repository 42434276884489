<template>
  <div class="main-wrapper">
    <el-container>
      <el-aside class="left-menu-wrapper" width="auto" v-show="showMenu">
        <div class="main-logo" @click="$router.push('/home')">
          <span :class="collapse? 'avue-logo_subtitle' : 'avue-logo_title'">
              {{ collapse ? systemName.charAt(0) : systemName }}
          </span>
        </div>
        <i-left-menu class="letf-menu" :data-list="menuList"
                     :default-active="$route.path"
                     :collapse="collapse">
        </i-left-menu>
      </el-aside>
      <el-container>
        <el-header height="auto">
          <div class="main-header">
            <div class="header-left">
              <i :class="collapse?'el-icon-s-unfold':'el-icon-s-fold'" @click="changeMenu" v-if="showMenu"></i>
            </div>
            <div class="header-right">
              <el-avatar :src="user.avatar"></el-avatar>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                    {{ user.username }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="user-info">{{ field.personalData }}</el-dropdown-item>
                  <el-dropdown-item command="logout">{{ field.logout }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="pages-lable-wrapper">
            <i-tabs :menu-list="menuList" menu-name-key="name" menu-router-key="router"></i-tabs>
          </div>
        </el-header>
        <el-main class="main-container">
          <router-view class="main-views"></router-view>
        </el-main>
      </el-container>
    </el-container>

    <!--抽屉-->
    <i-drawer :title="field.drawerTitle" :visible.sync="drawer.visible" @close="handleCloseDrawer">
      <el-tabs v-model="drawer.activeName">
        <el-tab-pane :label="field.userInfo" name="first">
          <el-form ref="userInfoForm" :model="userInfoForm" :rules="userInfoFormRules" size="small" label-width="100px"
                   label-position="left">
            <el-form-item :label="field.avatar.name + ':'">
              <el-avatar :size="60" :src="userInfoForm.avatar"></el-avatar>
            </el-form-item>
            <el-form-item :label="field.orgName.name + ':'" prop="orgName">
              <el-input v-model="userInfoForm.orgName" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item :label="field.username.name + ':'" prop="username">
              <el-input v-model="userInfoForm.username" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item :label="field.nickname.name + ':'" prop="nickname">
              <el-input v-model="userInfoForm.nickname" :placeholder="field.nickname.placeholder"></el-input>
            </el-form-item>
            <el-form-item :label="field.mobile.name + ':'" prop="mobile">
              <el-input v-model="userInfoForm.mobile" :placeholder="field.mobile.placeholder"></el-input>
            </el-form-item>
            <el-form-item :label="field.email.name + ':'" prop="email">
              <el-input v-model="userInfoForm.email" :placeholder="field.email.placeholder"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleUserInfoFormSubmit">{{ btn.submit }}</el-button>
              <el-button @click="drawer.visible = false">{{ btn.cancel }}</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="field.changePassword" name="second">
          <el-form ref="passwdForm" :model="passwdForm" :rules="passwdFormRules" label-width="100px" size="small"
                   label-position="left">
            <el-form-item :label="field.originalPassword.name + ':'" prop="password">
              <el-input v-model="passwdForm.password" show-password
                        :placeholder="field.originalPassword.placeholder"></el-input>
            </el-form-item>
            <el-form-item :label="field.newPassword.name + ':'" prop="newPassword">
              <el-input v-model="passwdForm.newPassword" show-password
                        :placeholder="field.newPassword.placeholder"></el-input>
            </el-form-item>
            <el-form-item :label="field.confirmPassword.name + ':'" prop="confirmPassword">
              <el-input v-model="passwdForm.confirmPassword" show-password
                        :placeholder="field.confirmPassword.placeholder"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handlePasswdFormSubmit">{{ btn.submit }}</el-button>
              <el-button @click="drawer.visible = false">{{ btn.cancel }}</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </i-drawer>
  </div>
</template>

<script>

import ILeftMenu from '@/components/i-left-menu/ILeftMenu.vue'
import ITabs from '@/components/i-tabs/ITabs.vue'
import {logout, saveOrUpdate, changeWebUserPassword} from '@/api/system/web-user';
import {removeAccessToken} from '@/utils/token';
import {getStore, removeStore} from '@/utils/store';

import {loadLeftMenu} from '@/api/system/menu'
import {hasSuccess} from '@/utils/tool';
import {storeKey} from '@/constant/store-key';
import IDrawer from '@/components/i-drawer/IDrawer.vue';
import {rules} from "@/utils/rules";
import {message} from '@/utils/message';
import md5 from 'js-md5';

export default {
  components: {
    ILeftMenu,
    ITabs,
    'i-drawer': IDrawer,
  },
  data() {
    return {
      menuList: [],
      timer: null,
      collapse: false,
      showMenu: true,
      systemName: '',
      refreshLock: false,
      drawer: {
        activeName: 'first',
        visible: false,
      },
      userInfoForm: {},
      passwdForm: {},
    }
  },
  computed: {
    user: {
      get: function () {
        return getStore({name: storeKey.user});
      }
    },
    btn() {
      return {
        search: this.$t('public.button.search'),
        submit: this.$t('public.button.submit'),
        cancel: this.$t('public.button.cancel'),
        confirm: this.$t('public.button.confirm'),
      }
    },
    field() {
      const fieldPrefix = 'mainManagePage.field';
      const drawerPrefix = 'mainManagePage.mainDrawer';
      return {
        personalData: this.$t(`${fieldPrefix}.personalData`),
        logout: this.$t(`${fieldPrefix}.logout`),
        userInfo: this.$t(`${fieldPrefix}.userInfo`),
        avatar: {
          name: this.$t(`${fieldPrefix}.avatar`),
          notNull: this.$t(`${drawerPrefix}.avatar.checkFail.notNull`),
        },
        orgName: {
          name: this.$t(`${fieldPrefix}.orgName`),
          required: this.$t(`${drawerPrefix}.orgName.checkFail.required`),
          maxSize: this.$t(`${drawerPrefix}.orgName.checkFail.maxSize`),
        },
        username: {
          name: this.$t(`${fieldPrefix}.username`),
          required: this.$t(`${drawerPrefix}.username.checkFail.required`),
          maxSize: this.$t(`${drawerPrefix}.username.checkFail.maxSize`),
        },
        nickname: {
          name: this.$t(`${fieldPrefix}.nickname`),
          placeholder: this.$t(`${drawerPrefix}.nickname.placeholder`),
          required: this.$t(`${drawerPrefix}.nickname.checkFail.required`),
          maxSize: this.$t(`${drawerPrefix}.nickname.checkFail.maxSize`),
        },
        mobile: {
          name: this.$t(`${fieldPrefix}.mobile`),
          placeholder: this.$t(`${drawerPrefix}.mobile.placeholder`),
          notNull: this.$t(`${drawerPrefix}.mobile.checkFail.notNull`),
        },
        email: {
          name: this.$t(`${fieldPrefix}.email`),
          placeholder: this.$t(`${drawerPrefix}.email.placeholder`),
          notNull: this.$t(`${drawerPrefix}.email.checkFail.notNull`),
        },
        changePassword: this.$t(`${fieldPrefix}.changePassword`),
        originalPassword: {
          name: this.$t(`${fieldPrefix}.originalPassword`),
          placeholder: this.$t(`${drawerPrefix}.originalPassword.placeholder`),
          required: this.$t(`${drawerPrefix}.originalPassword.checkFail.required`),
          maxSize: this.$t(`${drawerPrefix}.originalPassword.checkFail.maxSize`),
        },
        newPassword: {
          name: this.$t(`${fieldPrefix}.newPassword`),
          placeholder: this.$t(`${drawerPrefix}.newPassword.placeholder`),
          required: this.$t(`${drawerPrefix}.newPassword.checkFail.required`),
          maxSize: this.$t(`${drawerPrefix}.newPassword.checkFail.maxSize`),
        },
        confirmPassword: {
          name: this.$t(`${fieldPrefix}.confirmPassword`),
          placeholder: this.$t(`${drawerPrefix}.confirmPassword.placeholder`),
          required: this.$t(`${drawerPrefix}.confirmPassword.checkFail.required`),
          confirm: this.$t(`${drawerPrefix}.confirmPassword.checkFail.confirm`),
        },
        drawerTitle: this.$t(`${drawerPrefix}.drawerTitle`),
        passwordConsistency: this.$t(`${drawerPrefix}.passwordConsistency`),
      }
    },
    userInfoFormRules() {
      let validUsername = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.field.username.required));
        }
        if (!rules.englishNumbers.test(value)) {
          return callback(new Error(this.field.username.maxSize));
        }
        return callback();
      };
      let validMobile = (rule, value, callback) => {
        if (value && !rules.phone.test(value)) {
          return callback(new Error(this.field.mobile.notNull));
        }
        return callback();
      };
      let validEmail = (rule, value, callback) => {
        if (value && !rules.email.test(value)) {
          return callback(new Error(this.field.email.notNull));
        }
        return callback();
      };
      let validAvatar = (rule, value, callback) => {
        if (value && !rules.url.test(value)) {
          return callback(this.field.avatar.notNull);
        }
        return callback();
      };
      return {
        orgName: [{required: true, message: this.field.orgName.required, trigger: 'blur'},
          {min: 1, max: 20, message: this.field.orgName.maxSize, trigger: 'blur'}
        ],
        username: [{required: true, validator: validUsername, trigger: 'blur'}],
        nickname: [{required: true, message: this.field.nickname.required, trigger: 'blur'},
          {min: 1, max: 20, message: this.field.nickname.maxSize, trigger: 'blur'}
        ],
        mobile: [{validator: validMobile, trigger: 'blur'}],
        avatar: [{validator: validAvatar, trigger: 'blur'}],
        email: [{validator: validEmail, trigger: 'blur'}]
      }
    },
    passwdFormRules() {
      let validPassword = (rule, value, callback) => {
        if (!value) {
          return callback(this.field.originalPassword.required);
        }
        if (value && !rules.pwd_char20.test(value)) {
          return callback(this.field.originalPassword.maxSize);
        }
        return callback();
      };
      let validNewPassword = (rule, value, callback) => {
        if (!value) {
          return callback(this.field.newPassword.required);
        }
        if (value && !rules.pwd_char20.test(value)) {
          return callback(this.field.newPassword.maxSize);
        }
        return callback();
      }
      let validConfirmPassword = (rule, value, callback) => {
        // 密码不为空时，
        if (!value) {
          return callback(this.field.confirmPassword.required);
        }
        if (value && (value !== this.passwdForm.newPassword)) {
          return callback(this.field.confirmPassword.confirm);
        }
        return callback();
      }
      return {
        password: [{required: true, validator: validPassword, trigger: 'blur'}],
        newPassword: [{required: true, validator: validNewPassword, trigger: 'blur'}],
        confirmPassword: [{required: true, validator: validConfirmPassword, trigger: 'blur'}],
      }
    }
  },
  methods: {
    /**
     * 处理关闭Drawer
     */
    handleCloseDrawer() {
      this.$refs.passwdForm.resetFields();
      this.$refs.userInfoForm.resetFields();
      this.passwdForm = {};
      this.userInfoForm = {};
      this.drawer = {
        activeName: 'first',
        visible: false,
      };
    },
    /**
     * 处理用户修改密码表单提交
     */
    handlePasswdFormSubmit() {
      this.$refs.passwdForm.validate(async (valid) => {
        if (valid) {
          let {password, newPassword, confirmPassword} = this.passwdForm;
          if (password === newPassword) {
            message.info({message: this.field.passwordConsistency});
            return;
          }
          let resp = await changeWebUserPassword({
            id: this.user.id,
            password: md5(password),
            newPassword: md5(newPassword),
            confirmPassword: md5(confirmPassword)
          });
          if (hasSuccess(resp.code)) {
            this.drawer.visible = false;
            this.clearSelfStore();
            this.$router.replace("/login");
          }
        }
      });
    },
    /**
     * 处理用户信息表单提交
     */
    handleUserInfoFormSubmit() {
      // 提交修改接口？
      this.$refs.userInfoForm.validate(async (valid) => {
        if (valid) {
          let {id, avatar, username, nickname, mobile, email} = this.userInfoForm;
          let resp = await saveOrUpdate({
            id: id,
            username: username,
            avatar: avatar,
            nickname: nickname,
            mobile: mobile,
            email: email
          });
          if (hasSuccess(resp.code)) {
            this.drawer.visible = false;
            this.clearSelfStore();
            this.$router.replace("/login");
          }
        }
      });
    },
    /**
     * 清除自身缓存
     */
    clearSelfStore() {
      removeAccessToken();
      removeStore({name: storeKey.user});
    },
    countMainHeight() {//计算main模块的高度

      let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      let headerElement = document.getElementsByClassName('el-header')[0];
      let mainWrapperElement = document.getElementsByClassName('main-wrapper')[0];
      let mainElement = document.getElementsByClassName('el-main')[0];

      mainElement.style.height = clientHeight - headerElement.offsetHeight + 'px'

      document.getElementsByClassName('el-aside')[0].style.height = clientHeight + 'px'


      if (clientWidth < 1000) {
        this.collapse = true;

      } else {
        this.collapse = false;
      }

      if (clientWidth < 600) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }


    },
    resizeHandler() { //视口监听处理器
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.countMainHeight();
      }, 200);

    },
    changeMenu() { //收起或展开菜单开关
      this.collapse = !this.collapse
    },
    /**
     * 个人信息
     */
    userInfo() {
      this.userInfoForm = Object.assign({}, this.user);
      this.drawer.visible = true;
    },
    /**
     * 用户注销
     */
    async logout() {
      let resp = await logout();
      if (hasSuccess(resp.code)) {
        removeAccessToken();
        removeStore({name: storeKey.user});
        this.$router.replace('/login');
      }
    },
    handleCommand(command) { //处理下拉菜单
      if (command === 'user-info') {
        this.userInfo();
      } else if (command === 'logout') {
        this.logout();
      }
    },
    initSystemName() { //初始化系统设置
      let settings = localStorage.getItem('settings');
      if (settings === null || settings === undefined) {
        this.systemName = 'System Admin'
      } else {
        settings = JSON.parse(settings);

        this.systemName = settings.name;
      }
    },
    async getMenuList() { //获取菜单配置
      const {data} = await loadLeftMenu();
      this.menuList = data;
    },
  },
  watch: {
    collapse(newVal, oldVal) {


    }
  },
  mounted() {
    this.getMenuList();
    this.initSystemName();
    window.addEventListener('resize', this.resizeHandler);
    this.countMainHeight();

  },
  created() {

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);

  },
}


</script>

<style lang="scss">


.main-wrapper {
  height: 100vh;
  overflow: hidden;

  .el-aside {
    color: #333;
    // height: 100vh;
    overflow-x: hidden;
    background-color: var(--bg-theme-page-color);
    overflow: auto;
    position: relative;

    .main-logo {
      // width: auto;
      height: 61px;
      line-height: 61px;
      // font-weight: 600;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
      background-color: var(--bg-theme-page-color);
      color: #409EFF;
      position: fixed;
      top: 0;
      z-index: 99;

      .avue-logo_subtitle {
        width: 65px;
        display: block;
        font-weight: 600;
      }

      .avue-logo_title {
        width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: block;
      }


    }

    .letf-menu {
      margin-top: 60px;
    }


  }

  .el-aside::-webkit-scrollbar {
    display: none;
  }

  .el-header {


    //    color: #333;
    //    line-height: 60px;
    height: 100px;
    padding: 0;

  }

  .main-header {
    line-height: 60px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    // background-color: #409EFF;
    overflow: hidden;

    .header-left {
      width: 50%;

      .el-icon-s-fold, .el-icon-s-unfold {
        font-size: 20px;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
      }

    }

    .header-right {
      width: 50%;
      text-align: right;
      font-size: 16px;
      color: #fff;
      display: flex;
      align-items: center;
      grid-gap: 10px;
      justify-content: flex-end;

      .el-dropdown-link {
        cursor: pointer;
        color: #fff;
        font-weight: 600;
      }

      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }

  .pages-lable-wrapper {
    line-height: 32px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f0f2f5;
  }

  .main-container {
    background-color: #f0f2f5;

    .main-views {
      animation: fadeIn 1.5s;
    }


  }

  .el-main {
    // padding-left: 10px;
    // padding-right: 10px;
    // padding-top: 10px;
    padding: 10px;
  }

}


</style>
