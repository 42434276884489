import {get,post} from "@/utils/request";

/**
 * 角色列表选择
 */
export const roleSelect = () => {
    return get({url: '/role/role-select'});
}

/**
 * 获取角色分页数据
 * @param {*} data 请求参数
 * @returns 分页数据
 */
export function loadRolePaging(data){

    return get({
        url:'/role/load-paging',
        params:{
            ...data
        }
    })
}

/**
 * 保存或者修改角色信息
 * @param {*} data 请求参数
 * @returns 操作结果
 */
export function saveOrUpdateRole(data){


    return post({
        url:'/role/save-or-update',
        data:{
            ...data
        }
    })
}

/**
 * 通过角色编号删除角色信息
 * @param {*} data  请求参数
 * @returns 
 */
export function deleteRoleById(data){

    return post({
        url:'/role/delete-by-id',
        data:{
            ...data
        }
    })
}

/**
 * 通过角色编号获取该角色拥有的菜单和按钮权限
 * @param {*} data 
 */
export function lodaSelectMneusByRoleId(data){

    return get(
        {
            url:'/role/load-select-menu-ids-by-id',
            params:{
                ...data
            }
        }
    )

}

/**
 * 保存选中的菜单到角色的菜单权限控制表中
 * @param {*} data 
 * @returns 
 */
export function saveRoleMenuPower(data){

    return post({
        url:'/role/save/menu-power',
        data:{
            ...data
        }
    });
}

/**
 * 变更角色数据权限
 * @param {*} data 请求参数
 * @returns 
 */
export function changeDataScopeByRoleId(data){

    return post({
        url:'/role/change/data-scope',
        data:{
            ...data
        }
    })
}