import {get} from '@/utils/request'

const prefix = '/client-user';

/**
 * 分页查询微信小程序用户
 * @param params 手机号码、用户昵称
 */
export const clientWechatAppletUserPaging = (params = {}) => {
    return get({url: `${prefix}/wechat-applet-user-paging`, params: {...params}});
};
