import {get, post} from '@/utils/request';

const prefix = '/charge-pile-brand';

/**
 * 分页查询充电桩品牌列表
 * @param params 查询条件
 */
export const chargePileBrandPaging = (params = {}) => {
    return get({url: `${prefix}/list-page`, params: {...params}});
}

/**
 * 查询充电桩品牌下拉列表数据
 */
export const chargePileBrandPullDownList = () => {
    return get({url: `${prefix}/pull-down`});
}

/**
 * 查询充电桩品牌协议类型
 */
export const listChargePileBrandProtocolType =() => {
    return get({url: `${prefix}/protocol-type`});
}

/**
 * 删除充电桩品牌
 */
export const deleteChargePileBrand = (params = {}) => {
    return post({url: `${prefix}/delete`, data: params});
}

/**
 * 添加或更新充电桩品牌
 * @returns {Promise<unknown>}
 */
export const addOrUpdateChargePileBrand = (params = {}) => {
    return post({url: `${prefix}/add-or-update`, data: params});
}
