import {get, post} from '@/utils/request'


/**
 * 多条件分页查询平台用户钱包
 * @param {*} data 
 * @returns 
 */
export function loadClientUserWalletAccountPaging(data){

    return get({
        url:'/client-user/wallet-Account/load-paging',
        params:{...data}
    })
}