<template>

    <div class="i-dialog">
        <el-dialog 
        v-bind="$attrs" 
        v-on="$listeners"
        :visible.sync="visible"
        :before-close="beforeCloseHandler"
        >
            <template v-for="(value,name) in $slots" :slot="name">
                <slot :name="name"></slot>
            </template>

        </el-dialog>
    </div>
</template>

<script>
export default{

    props:{
        visible:{
            type:Boolean
        } 
    },
    methods:{
        beforeCloseHandler(){
            this.$emit('update:visible',false);
        }
    },
}
</script> 